import React from 'react'
import Item from '../generic/item'
import Button from '../generic/button'

export const BoxTitle = ({ actions, children }) => {
  return <div className="row mb-2">
    <div className="col-12">
      <div className="row">
        <div className="col">
          <h2>{children}</h2>
        </div>
        <div className="col-auto">
          {actions}
        </div>
      </div>
    </div>
  </div>
}

export default function BigBox({
                                 title,
                                 wrap_in_item = true,
                                 children,
                                 is_active,
                                 actions,
                                 no_padding,
                                 horizontal_scroll,
                                 force_close = false,
                               }) {
  const is_closable = force_close === true
  const [is_open, set_is_open] = React.useState(!force_close)
  return (
    <>
      <BoxTitle actions={<>{actions} {is_closable ? <Button onClick={() => set_is_open(!is_open)}>Déplier/Replier</Button>:null}</>}>{title}</BoxTitle>
      {is_closable && !is_open ? null :
        <div className="row mb-5">
          <div className="col-12">
            {children ? (
              wrap_in_item ? <Item active={is_active} no_padding={no_padding} horizontal_scroll={horizontal_scroll}>
                {children}
              </Item> : children
            ) : null}
          </div>
        </div>}
    </>
  )
}
