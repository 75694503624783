import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import BackTo from '../../components/generic/back_to'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { FaSeedling } from 'react-icons/all'
import BigBox from '../../components/layout/big_box'
import Item from '../../components/generic/item'
import moment from 'moment'
import { TextCopyButton } from '../../components/generic/copy-button'

export const IntellinautTag = ({intellinaut_company_id}) => {
  const ios_intellinaut = useCachedContext('ios_intellinaut', intellinaut_company_id)

  if (ios_intellinaut.loading) {
    return <span>(chargement...)</span>
  }

  if (ios_intellinaut.error) {
    return <span>n/a</span>
  }

  if (!ios_intellinaut.intellinaut) {
    return <span>n/a</span>
  }

  return <span>{ios_intellinaut.intellinaut.first_name} {ios_intellinaut.intellinaut.last_name}<br/>
    <small><TextCopyButton text_to_copy={intellinaut_company_id}>{intellinaut_company_id.length > 10 ? <>{intellinaut_company_id.substr(0,8)}...</> : intellinaut_company_id}</TextCopyButton> </small>
  </span>
}

export default function Assists() {

  const assists = useCachedContext('ios_assists')

  if (assists.loading) {
    return <DefaultLoading/>
  }

  if (assists.error) {
    return <DefaultError/>
  }


  return (
  <div className={'pt-5 pb-5 container-fluid'}>
    <div className="container mb-5">
      <BackTo to={'/success'}>Back to Support</BackTo>

      <Title color={section_color('success')} style={{ color: 'white' }}><FaSeedling/> Impersonation requests history</Title>
      <BigBox title={'Complete list'}>
        {assists.map((a) => {
          return <Item className={"mb-3"}>
            {moment(a.date).format()}: {a.employee_id} requested impersonation link for <IntellinautTag intellinaut_company_id={a.intellinaut_id}/>
          </Item>
        })}
      </BigBox>
    </div>
  </div>
  )
}
