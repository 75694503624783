import React, { Fragment } from 'react'

import './field.css'
import Button from './button'
import Item from './item'

const Select = ({ value, setter, options, label, option_button, option_label, option_detail }) => {
  return (
    <>
      <div className="col-12 field">
        <label>{label}</label>
      </div>
      <div className="col-12">
        <Item style={{ padding: 0 }}>
          {options.map((option) => {
            return <><Button onClick={() => setter(option)} className={'btn-sm mr-2'}
                             button={
              value === option ? (option_button ? option_button(option) : 'primary') : (option_button ? option_button(option) : 'secondary')} outline={option_button && value !== option}>{option_label ? option_label(option) : option}</Button>
              {option_detail ? <>{option_detail(option)}<br/></>:null}
            </>
          })}
        </Item>
      </div>
    </>
  )
}


export default Select
