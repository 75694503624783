import React from 'react'
import Button from './button'
import { useTranslate } from '../../context/lang'

export default function UpdateButtonV2(
  {
    className = '',
    button = 'primary',
    trigger,
    loadingMessage,
    errorMessage,
    successMessage,
    children,
  },
) {
  const translate = useTranslate()

  const [success, set_success] = React.useState(false)
  const [error, set_error] = React.useState(false)
  const [loading, set_loading] = React.useState(false)


  if (!loadingMessage) {
    loadingMessage = translate({
      fr: `En cours...`,
      en: `In progress...`,
    })
  }
  if (!errorMessage) {
    errorMessage = translate({
      fr: `Une erreur s'est produite`,
      en: `An error occurred`,
    })
  }
  if (!successMessage) {
    successMessage = translate({
      fr: 'Succès !',
      en: `Success !`,
    })
  }

  const do_it = React.useCallback(() => {
    const go = async () => {
      try {
        set_success(false)
        set_error(false)
        set_loading(true)

        await trigger()

        set_success(true)
        set_loading(false)
      } catch (e) {
        set_loading(false)
        set_error(true)
      }
    }

    go().catch(e => console.error(e))
  }, [trigger])

  return (
    <Button className={className} button={success ? 'success' : button} onClick={() => do_it()}>
      {loading ? loadingMessage : null}
      {error ? errorMessage : null}
      {success ? successMessage : null}
      {( !loading && !error && !success ) ? children : null}
    </Button>
  )
}
