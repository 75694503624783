import { create_mutation } from './_query_and_mutations_templates'

export const ios_interface_texts = {
  query: 'ios_interface_texts',
  params: {
    company_id: 'ID!',
  },
  output: `{
          text
          lang
          created_at
          translations {
            original
            lang
            text
            created_at
            updated_at
          }
        }`,
}

export const ios_update_interface_translation = create_mutation({
  mutation: 'ios_update_interface_translation',
  params: {
    company_id: 'ID',
    source_lang: 'String',
    source_text: 'String',
    target_lang: 'String',
    target_text: 'String',
  }
})
