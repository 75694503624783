import { gql } from '@apollo/client'

export const GET_IOS_EMPLOYEES = `
    query GetIosEmployees($company_id: ID!) {
        ios_employees (
          company_id: $company_id,
        ) {
          employee_id
          first_name
          last_name
          phone_number
          email
          roles
        }
    }
`

export const UPDATE_IOS_EMPLOYEE = gql`
    mutation UpdateIosEmployee(
        $company_id: ID!,
        $employee_id: String,
        $roles: [String],
    ) {
        ios_update_employee (
            company_id: $company_id,
            employee_id: $employee_id,
            roles: $roles,
        )
    }
`
