import React from "react";
import { FaSeedling } from "react-icons/all";
import InternalLink from "../../components/generic/internal-link";
import Title from "../../components/layout/title";
import { section_color } from "../colors";
import Intellinauts from "./intellinauts";
import { KnowledgeBaseModal } from "./knowledge_base_modal";

export default function Success() {
  return (
    <div className={"pt-5 pb-5 container-fluid"}>
      <div className="mb-5">
        <Title
          color={section_color("success")}
          style={{ color: "white" }}
          actions={<KnowledgeBaseModal />}
        >
          <FaSeedling /> Support
        </Title>
        <Intellinauts />

        <div className="row mt-4">
          <div className="col-12 font-weight-bold">Other activites</div>
          <div className="col-12">
            <ul>
              <li>
                <InternalLink to={"/success/assists"}>
                  See impersonation history
                </InternalLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
