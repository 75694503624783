import { gql } from '@apollo/client'

export const GET_IOS_ROUTINES = `
    query GetIosRoutines($company_id: ID!, $role: String) {
        ios_routines (
          company_id: $company_id,
          role: $role,
        ) {
          routine_id
          role
          details
          steps
        }
    }
`

export const UPDATE_IOS_ROUTINE = gql`
    mutation UpdateIosRoutine(
        $company_id: ID!,
        $routine_id: String,
        $role: String,
        $details: String,
        $steps: String,
    ) {
        ios_update_routine (
            company_id: $company_id,
            routine_id: $routine_id,
            role: $role,
            details: $details,
            steps: $steps,
        )
    }
`
