import { create_mutation } from './_query_and_mutations_templates'

export const ios_accounts = {
  query: 'ios_accounts',
  params: {
    company_id: 'ID!',
  },
  output: `{
    name
    iban
    balance
    authorized_balance
  }`,
}


export const ios_account_transfer = create_mutation({
  mutation: 'ios_account_transfer',
  params: {
    company_id: 'ID!',
    from: 'String',
    to: 'String',
    avoid_double_transfer: 'String',
    amount: 'String',
    reference: 'String',
  },
})


export const ios_transactions = {
  query: 'ios_transactions',
  params: {
    company_id: 'ID!',
    from: 'Float',
    to: 'Float',
  },
  output: `{
    transaction_id
    type
    date
    merchant_name
    merchant_country
    account_id
    amount
    currency
    bill_amount
    bill_currency
    description
    needs_to_upload_invoice
  }`,
}

export const upload_invoice = create_mutation({
  mutation: 'upload_invoice',
  params: {
    company_id: 'ID!',
    invoice: 'Upload!',
    file_name: 'String',
    transaction_id: 'String',
    year: 'String',
    month: 'String',
  },
})

export const no_invoice_needed = create_mutation({
  mutation: 'no_invoice_needed',
  params: {
    company_id: 'ID!',
    transaction_id: 'String',
  },
})
