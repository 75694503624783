import { useMutation } from "@apollo/client";
import moment from "moment";
import React from "react";
import { FiCheck, FiLink } from "react-icons/all";
import DeleteButtonV2 from "../../components/generic/delete-button-v2";
import ExternalLink from "../../components/generic/external-link";
import Field from "../../components/generic/field";
import { await_mutate } from "../../graphql/mutate_promise";
import { create_mutation } from "../../graphql/queries/_utils";
import { useCachedContext } from "../../hooks/useCachedContext";
import useCompanyId from "../../hooks/useCompanyId";

export const subscription_tiers = {
  new_customer: "new_customer",
  starter: "starter",
  free_trial: "free_trial",
  agency: "agency",
  single_amazon_account: "single_amazon_account",
  unlimited_amazon_accounts: "unlimited_amazon_accounts",
  canceled: "canceled",
  paused: "paused",
};

export const StartFreeTrial = ({ company }) => {
  const company_id = useCompanyId();
  const { intellinaut_company_id } = company;
  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");
  const user_with_transactions = useCachedContext(
    "ios_intellinaut_transactions_and_processes",
    company.intellinaut_company_id
  );

  const [subscription_tier, set_subscription_tier] = React.useState(
    subscription_tiers.agency
  );
  const [days, set_days] = React.useState(30);

  const [ios_start_free_trial] = useMutation(
    create_mutation({
      mutation: "ios_start_free_trial",
      params: {
        company_id: "ID!",
        intellinaut_company_id: "ID!",
        subscription_tier: "String",
        days: "Float",
      },
    })
  );

  const do_ios_start_free_trial = async () => {
    console.log("Move to new plan...");
    await await_mutate(ios_start_free_trial, {
      variables: {
        company_id,
        intellinaut_company_id,
        subscription_tier,
        days,
      },
    });
    await intellinaut_companies.refresh();
    await user_with_transactions.refresh();
    console.log("Done!");
  };

  console.log("start free trial", company);

  return (
    <div className="row">
      <Field
        type={"select"}
        value={subscription_tier}
        setter={set_subscription_tier}
        options={[
          subscription_tiers.starter,
          subscription_tiers.single_amazon_account,
          subscription_tiers.unlimited_amazon_accounts,
          subscription_tiers.agency,
        ]}
        label={"Subscription tier"}
      />

      <Field
        type={"number"}
        value={days}
        setter={set_days}
        label={"Duration in days"}
      />

      <div className="col-12">
        <DeleteButtonV2 do_delete={do_ios_start_free_trial}>
          Start free trial
        </DeleteButtonV2>
      </div>
      <div className="col-12 mt-1 small font-weight-bold">
        Note: this will send a "start_free_trial" email to the user{" "}
        <ExternalLink to={"https://polyglotmail.com/app/emails/RU79uyoxl"}>
          <FiLink /> Edit template on PolyglotMail
        </ExternalLink>
      </div>

      {company.free_trial_end ? (
        <div className="col-12 mt-4">
          <div className="alert alert-success small">
            <FiCheck /> Free trial started! Active until{" "}
            {moment(company.free_trial_end).format("lll")}
          </div>
        </div>
      ) : null}
    </div>
  );
};
