import { create_mutation } from './_query_and_mutations_templates'

export const ios_emails = {
  query: 'ios_emails',
  params: {
    company_id: 'ID!',
  },
  output: `{
    email_id
    label
    source_lang
    translations {
      lang
      title
      body
      is_automated
      is_manually_edited_html
    }
  }`,
}


export const ios_email_history = {
  query: 'ios_email_history',
  params: {
    company_id: 'ID!',
    email_or_intellinaut_id:'String',
  },
  output: `{
    email_template_id
    template_key
    email_sent_id
    date
    from
    to
    title
    content
    lang
  }`,
}
export const ios_email_history_count = {
  query: 'ios_email_history',
  params: {
    company_id: 'ID!',
    email_or_intellinaut_id:'String',
    page: 'Int',
  },
  output: `{
    email_id
    date
  }`,
}


export const ios_edit_email = create_mutation({
  mutation: 'ios_edit_email',
  params: {
    company_id: 'ID!',
    email_id: 'String',
    label: 'String',
    source_lang: 'String',
    lang: 'String',
    title: 'String',
    body: 'String',
    is_automated: 'Boolean',
    is_manually_edited_html: 'Boolean',
  },
})
