import React, { useContext } from 'react'

import './left-menu.scss'
import IntellifoxIcon from '../../images/intellifox-icon'
import { absolute, navigate } from '../../absolute_link'
import { useTheme } from '../../context/theme'
import UserContext from '../../context/user'
import {  GiExitDoor, GiSmallFire } from 'react-icons/gi'
import { WiDaySunny } from 'react-icons/wi'
import {  TiWeatherNight } from 'react-icons/ti'
import {
  MdClose,
  MdMenu,
} from 'react-icons/md'
import { useWindowSize } from '../../hooks/useWindowSize'
import { Link } from '@reach/router'
import  { useHasActiveSubscription } from '../../hooks/useCompanyId'
import ExternalLink from '../generic/external-link'
import { useTranslate } from '../../context/lang'
import { BiChart,  BiCog,  FaSeedling } from 'react-icons/all'
import { section_color } from '../../routes/colors'
import { get_intellifox_url } from '../../hooks/useCurrentIntellifoxUrl'
import { useRole } from '../../hooks/useRole'


export const MenuTitle = ({
                            label,
                            label_className = '',
                            is_active = false,
                            active_color,
                            onClick,
                            description,
                            icon,
                            link,
                            current_path,
                            className = '',
                            label_font_weight = 400,
                            label_font_size,
                            external_link,
                            color,
                          }) => {
  const path_tester = new RegExp(link)


  const is_current_path = link && path_tester.test(current_path)

  const compute_color = () => {
    if (is_current_path) {
      return active_color
    }
    return null
  }
  const content = (
    <>
      <div className="position-relative">
        {/*{is_current_path ? <RoundDecorationRight size={10}/> : null}*/}

        <div
          className={`position-relative item-container left-menu-opened-width pt-2 pb-2 clickable ${className} ${is_current_path || is_active ? 'is-active' : ''}`}
          style={{
            marginBottom: 10,
          }}
          onClick={onClick}>


          <div className="position-absolute" style={{
            width: is_current_path ? 264 : 12,
            transition: 'width 0.2s',
            height: 46,
            // borderRadius: '4px',
            left: 0,
            top: 0,
            background: color,
          }}></div>

          <div className="row no-gutters align-items-center">
            {icon ? <div className="left-menu-fixed-width d-inline-block text-center"
                         style={{
                           perspective: '100px',
                           color: compute_color(),
                         }}

            >
              <div>{icon}</div>
            </div> : null}
            <div className="col item-label text-ellipsis">
              <div className="row">
                <div className={`col-12 pr-5 text-ellipsis ${label_className}`}
                     style={{
                       lineHeight: '18px',
                       fontWeight: label_font_weight,
                       fontSize: label_font_size,
                       color: compute_color(),
                     }}>
                  {label}
                </div>
                <div className="col-12 text-ellipsis" style={{ lineHeight: '14px', fontWeight: 300 }}>
                  <small style={{ lineHeight: '10px', fontWeight: 200 }}>{description}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
  if (onClick) {
    return content
  }

  if (external_link) {
    return <ExternalLink to={external_link} style={{ textDecoration: 'none', color: 'inherit' }}>
      {content}
    </ExternalLink>
  }
  return (
    <Link to={absolute(link)}>
      {content}
    </Link>
  )
}


export default function LeftMenu({ location, isLoggedIn, disconnect }) {
  const { theme, set_theme } = useTheme()
  const user = useContext(UserContext)

  const translate = useTranslate()

  // const is_path_beyond_second_level = location.pathname.split('/').length > 3

  const is_support = useRole('support')
  const is_ceo = useRole('ceo')
  const has_active_subscription = useHasActiveSubscription()

  const window_size = useWindowSize()

  const is_mobile = window_size.width < 768
  const [is_open_on_mobile, set_is_open_on_mobile] = React.useState(false)
  React.useEffect(() => {
    if (!is_mobile) {
      set_is_open_on_mobile(false)
    }
  }, [is_mobile])


  const ThemeIcon = theme === 'dark' ? WiDaySunny : TiWeatherNight

  if (!is_open_on_mobile && is_mobile) {
    return <div className={'min-vh-100 '} style={{ left: 0, top: 0, width: 0, height: '100%', zIndex: 10 }}>
      <div className="h1 clickable position-fixed" style={{ zIndex: 10 }} onClick={() => set_is_open_on_mobile(true)}>
        <MdMenu/></div>
    </div>
  }


  return (
    <div
      className={`left-menu-container min-vh-100  force-full-width ${!isLoggedIn ? 'force-fullest-width' : ''} ${is_mobile ? 'position-fixed' : ''}`}
      style={{ zIndex: is_mobile ? 5 : null }}>
      {is_mobile ? <>
        <div className="position-fixed h1 clickable w-100 h-100 text-right"
             style={{ left: 0, top: 0, background: 'rgba(0,0,0,0.8)' }}
             onClick={() => set_is_open_on_mobile(false)}><MdClose/></div>
      </> : null}
      <div className="row no-gutters">
        <div className="left-menu left-menu-fixed-width position-fixed col ">
          <div
            className={`item-container left-menu-opened-width  clickable`}
            onClick={() => {
              if (isLoggedIn) {
                navigate('/')
              } else {
                window.location = get_intellifox_url()
              }
            }} style={{
            color: 'white',
            height: 50,
          }}>
            <div className="row align-items-center">
              <div className="col item-label" style={{
                fontSize: 33,
                fontWeight: 300,
              }}>
                <b>SellerSuite admin</b>
              </div>
            </div>
          </div>

          <div className="scrollable-menu" style={{ height: `calc(100% - ${50}px)` }}>
            {has_active_subscription ?
              <>
                {is_support || is_ceo ? <MenuTitle current_path={location.pathname}
                                                   icon={<FaSeedling className="item-icon"
                                                                     style={{
                                                                       width: 24,
                                                                       height: 24,
                                                                     }}/>}
                                                   label={'Support'} link={'/success'}
                                                   color={section_color('success')}/> : null}

                {is_ceo ? <>
                  <MenuTitle current_path={location.pathname} icon={<IntellifoxIcon className="item-icon"
                                                                                    style={{ width: 24, height: 24 }}/>}
                             label={'Numbers'} link={'/numbers'} color={section_color('intellifox')}/>
                  <MenuTitle current_path={location.pathname} icon={<GiSmallFire className="item-icon"
                                                                                 style={{ width: 24, height: 24 }}/>}
                             label={'Product'} link={'/product'} color={section_color('product')}/>
                  <MenuTitle current_path={location.pathname} icon={<BiChart className="item-icon"
                                                                                 style={{ width: 24, height: 24 }}/>}
                             label={'Analytics'} link={'/analytics'} color={"orange"}/>
                  <MenuTitle current_path={location.pathname} icon={<BiCog className="item-icon"
                                                                                 style={{ width: 24, height: 24 }}/>}
                             label={'Operations'} link={'/operations'} color={"blue"}/>
                </> : null}
              </> : null}

            <MenuTitle className={'mt-5'} onClick={() => set_theme(theme === 'dark' ? 'light' : 'dark')}
                       label={theme === 'dark' ? translate({
                         fr: 'Mode jour',
                         en: 'Light theme',
                       }) : translate({
                         fr: 'Mode nuit',
                         en: 'Dark theme',
                       })}
                       icon={<ThemeIcon className="item-icon" style={{ width: 24, height: 24 }}/>}/>


            {isLoggedIn ?
              <MenuTitle onClick={disconnect} label={translate({
                fr: 'Déconnecter',
                en: 'Log out',
              })}
                         icon={<GiExitDoor className="item-icon" style={{ width: 24, height: 24 }}/>}
              /> : null}

          </div>
        </div>
      </div>
    </div>
  )
}
