/*
This is a developer helper file, with templates for graphql queries and mutations, using the standard that should be applied to all queries.
 */

/*
Note : replace OBJECT_NAME with the lower case version of the name, as it appears in the graphql api
Example:

OBJECT_NAME becomes "bills"
 */

import { gql } from '@apollo/client'

export const create_query = ({ query, params={}, output}) => {
  const has_params = Object.keys(params).length > 0
  const params_header = has_params ? `
  (
    ${Object.keys(params).map((param) => `$${param}:${params[ param ]}`).join('\n')}
  )` : ''
  const params_assignments = has_params ? `
  (
    ${Object.keys(params).map((param) => `${param}:$${param}`).join('\n')}
  )
  ` : ''

  return `
  query ${query}${params_header}
   {
    ${query}${params_assignments}
    ${output ? output : ''}
  }
`
}


export const create_mutation = ({ mutation, params={}, output }) => {
  return gql`
  mutation ${mutation}(
    ${Object.keys(params).map((param) => `$${param}:${params[ param ]}`).join('\n')}
  ) {
    ${mutation}(
      ${Object.keys(params).map((param) => `${param}:$${param}`).join('\n')}
    )
    ${output ? output : ''}
  }
`
}


