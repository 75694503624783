import { gql } from '@apollo/client'

const procedure_attributes = `
    procedure_id
    role
    title
    trigger_type
    details
    tasks {
        task_id
        days_after_start
        title
        details
        state
        results {
            result_id
            type
            variable_name
            is_mandatory
            date
            details
            value
        }
    }
`

export const GET_IOS_PROCEDURES = `
    query get_ios_procedures($company_id: ID!, $role: String) {
        ios_procedures (
          company_id: $company_id,
          role: $role,
        ) {
          ${procedure_attributes}
        }
    }
`

export const get_ios_procedure_executions = `
    query get_ios_procedure_executions($company_id: ID!) {
        ios_procedure_executions (
          company_id: $company_id,
        ) {
          procedure_execution_id
          start_date
          params
          ${procedure_attributes}
        }
    }
`

export const create_ios_procedure = gql`
    mutation create_ios_procedure(
        $company_id: ID!,
    ) {
        create_ios_procedure (
            company_id: $company_id,
        )
    }
`

export const update_ios_procedure = gql`
    mutation update_ios_procedure(
        $company_id: ID!,
        $procedure_id: String,
        $role: String,
        $title: String,
        $trigger_type: String,
        $details: String,
    ) {
        update_ios_procedure (
            company_id: $company_id,
            procedure_id: $procedure_id,
            role: $role,
            title: $title,
            trigger_type: $trigger_type,
            details: $details,
        )
    }
`
export const delete_ios_procedure = gql`
    mutation delete_ios_procedure(
        $company_id: ID!,
        $procedure_id: String,
    ) {
        delete_ios_procedure (
            company_id: $company_id,
            procedure_id: $procedure_id,
        )
    }
`

export const create_ios_task = gql`
    mutation create_ios_task(
        $company_id: ID!,
        $procedure_id: String,
        $order: Float,
    ) {
        create_ios_task (
            company_id: $company_id,
            procedure_id: $procedure_id,
            order: $order,
        )
    }
`

export const update_ios_task = gql`
    mutation update_ios_task(
        $company_id: ID!,
        $task_id: String,
        $days_after_start: Float,
        $title: String,
        $details: String,
        $state: String,
    ) {
        update_ios_task (
            company_id: $company_id,
            task_id: $task_id,
            days_after_start: $days_after_start,
            title: $title,
            details: $details,
            state: $state,
        )
    }
`
export const delete_ios_task = gql`
    mutation delete_ios_task(
        $company_id: ID!,
        $task_id: String,
    ) {
        delete_ios_task (
            company_id: $company_id,
            task_id: $task_id,
        )
    }
`

export const create_ios_result = gql`
    mutation create_ios_result(
        $company_id: ID!,
        $task_id: String,
    ) {
        create_ios_result (
            company_id: $company_id,
            task_id: $task_id,
        )
    }
`

export const update_ios_result = gql`
    mutation update_ios_result(
        $company_id: ID!,
        $result_id: String,
        $type: String,
        $variable_name: String,
        $is_mandatory: Boolean,
        $date: Float,
        $details: String,
        $value: String,
    ) {
        update_ios_result (
            company_id: $company_id,
            result_id: $result_id,
            type: $type,
            variable_name: $variable_name,
            is_mandatory: $is_mandatory,
            date: $date,
            details: $details,
            value: $value,
        )
    }
`

export const delete_ios_result = gql`
    mutation delete_ios_result(
        $company_id: ID!,
        $result_id: String,
    ) {
        delete_ios_result (
            company_id: $company_id,
            result_id: $result_id,
        )
    }
`


export const create_ios_procedure_execution = gql`
    mutation create_ios_procedure_execution(
        $company_id: ID!,
        $target_company_id: String,
        $procedure_id: String,
        $start_date: Float,
        $params: String
    ) {
        create_ios_procedure_execution (
            company_id: $company_id,
            target_company_id: $target_company_id,
            procedure_id: $procedure_id,
            start_date: $start_date,
            params: $params,
        )
    }
`

export const update_ios_procedure_execution = gql`
    mutation update_ios_procedure_execution(
        $company_id: ID!,
        $procedure_execution_id: String,
        $task_id: String,
        $state: String
        $result_id: String
        $value: String
    ) {
        update_ios_procedure_execution (
            company_id: $company_id,
            procedure_execution_id: $procedure_execution_id,
            task_id: $task_id,
            state: $state,
            result_id: $result_id,
            value: $value,
        )
    }
`

export const delete_ios_procedure_execution = gql`
    mutation delete_ios_procedure_execution(
        $company_id: ID!,
        $procedure_execution_id: String,
    ) {
        delete_ios_procedure_execution (
            company_id: $company_id,
            procedure_execution_id: $procedure_execution_id,
        )
    }
`
