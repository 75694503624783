import React from 'react'
import IntellifoxModal from '../../components/generic/modal'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Field from '../../components/generic/field'
import Button from '../../components/generic/button'
import ExternalLink from '../../components/generic/external-link'
import moment from 'moment'
import * as _ from 'lodash'
import random_color from 'randomcolor'
import { DetailTag } from '../../components/generic/detail_tag'
import TextButton from '../../components/generic/text_button'

export const KnowledgeBaseModal = () => {
  return <IntellifoxModal title={'Knowledge base'}
                          body={<KnowledgeBase/>}>
    Explore Knowledge base
  </IntellifoxModal>
}

const CategoryTag = ({category}) => {
  return <div className="d-inline-block small"><DetailTag background={random_color({
    seed: category,
    luminosity: 'dark',
  })}>
    {category}
  </DetailTag></div>
}

const KnowledgeBase = () => {
  const [filter, set_filter] = React.useState('')

  const knowledge_base = useCachedContext('ios_knowledge_base')
  const [see_more, set_see_more] = React.useState(false)

  if (knowledge_base.loading) {
    return <DefaultLoading/>
  }

  if (knowledge_base.error) {
    return <DefaultError/>
  }

  const categories = _.groupBy(knowledge_base, 'category')

  const sorted_categories = Object.keys(categories).sort((c1,c2) => categories[c1].length > categories[c2].length ? -1:1).filter((c,i) =>  see_more || (i<20))
  const filter_tester = new RegExp(filter, 'ig')

  return <div className="row">
    <div className="col-12">
      <TextButton className={"btn-sm"} onClick={() => knowledge_base.refresh()}>Refresh knowledge base</TextButton>
    </div>
    <Field type={'text'} setter={set_filter} value={filter} label={'Filter knowledge base by category or keyword'}/>

    <div className="col-12">
      {sorted_categories.map((category) => {
        return <Button onClick={() => set_filter(category)} button={filter === category ? 'primary' : 'secondary'}
                       className={'btn-sm mr-1 '}>{<CategoryTag category={category}/>} <span className="small">{categories[ category ].length}</span></Button>
      })}
    </div>
    <div className="col-12">
      <TextButton onClick={() => set_see_more(!see_more)} className={"btn-sm"}>{
        see_more ? 'See less categories' : 'See more categories'
      }</TextButton>
    </div>

    <div className="col-12">
      <hr/>
    </div>

    <div className="col-12">
        {knowledge_base.filter((a) =>
          ( filter.length <= 3 )
          || filter_tester.test(a.category)
          || filter_tester.test(a.problem)
          || filter_tester.test(a.answer),
        ).map(({ session_id, date, category, problem, answer, related_help_article }) => {
          return <div className={'row small mb-3'}>
            <div className="col-12">
              {<CategoryTag category={category}/>}
            </div>
            <div className="col-12 font-weight-bold">
              Problem: {problem}
            </div>
            <div className="col-12">
              Solution: {answer}
            </div>
            {related_help_article ? <div className="col-12">
              Suggested article: <ExternalLink to={related_help_article}>{related_help_article}</ExternalLink>
            </div> : null}
            <div className="col-12">
              {moment(date).format()} <ExternalLink
                to={`https://app.crisp.chat/website/1749a2cd-031f-41e1-97c3-4c1f1d788bbf/inbox/${session_id}/`}>See
                conversation on Crisp</ExternalLink>
            </div>
            <div className="col-12">
              <hr/>
            </div>
          </div>
        })}
    </div>
  </div>
}
