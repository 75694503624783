import React from 'react'

import spain from '../../images/countries/spain.svg'
import france from '../../images/countries/france.svg'
import germany from '../../images/countries/germany.svg'
import uk from '../../images/countries/united-kingdom.svg'
import italy from '../../images/countries/italy.svg'
import netherlands from '../../images/countries/netherlands.svg'
import sweden from '../../images/countries/sweden.svg'
import poland from '../../images/countries/republic-of-poland.svg'

const flag = (market_place) => {
  let flag
  if (/^es/.test(market_place)) {
    flag = spain
  } else if (/^fr/.test(market_place)) {
    flag = france
  } else if (/^de/.test(market_place)) {
    flag = germany
  } else if (/^uk/.test(market_place)) {
    flag = uk
  } else if (/^uk/.test(market_place)) {
    flag = uk
  } else if (/^it/.test(market_place)) {
    flag = italy
  } else if (/^nl/.test(market_place)) {
    flag = netherlands
  } else if (/^se/.test(market_place)) {
    flag = sweden
  } else if (/^pl/.test(market_place)) {
    flag = poland
  }

  if (flag) {
    return <img src={flag} alt={market_place} style={{ width: 24, height: 24 }}/>
  }

  return market_place
}

export default function Flag({ market_place }) {
  return (
    <>
      {flag(market_place)}
    </>
  )
}
