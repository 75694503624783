import React, { Fragment, useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'


import PageTitle from '../generic/page-title'
import { displayFields } from '../generic/field'
import Button from '../generic/button'
import PasswordHelper from './password-rule'
import Item from '../generic/item'
import useLocalStorage from '../../hooks/useLocalStorage'
import { Checkbox } from '../generic/checkbox'
import { FullScreenCentered } from '../generic/centered'
import IntellifoxIcon from '../../images/intellifox-icon'
import { error_to_i18n } from '../i18n/errors'
import CreateAccount from './create-account'
import { await_mutate } from '../../graphql/mutate_promise'
import TextButton from '../generic/text_button'
import UserContext from '../../context/user'
import useHashParam from 'use-hash-param'
import IntellifoxModal, { IntellifoxModalComponent } from '../generic/modal'
import { RESET_PASSWORD } from '../../graphql/queries/user'
import { useTranslate } from '../../context/lang'


const GET_TOKEN = gql`
    mutation GetToken($email: String!, $password: String!, $expires: Int!) {
        getToken(email:$email, password:$password, expires:$expires) {
            token,
            valid_until,
            user {
                user_id
                email
                first_name
                last_name
            }
            company {
                company_id
                label
                url
            }
        }
    }
`

export const useLogin = (set_user) => {
  const [getToken, getTokenMutation] = useMutation(GET_TOKEN) // 1 day = 60*60*24
  const user = React.useContext(UserContext)

  const login = React.useCallback(async (email, password, expires = 8 * 60 * 60 * 24) => {
    await await_mutate(getToken, {
      variables: { email, password: password, expires },
      update: (mutation) => {
        set_user({
          ...mutation.data.getToken,
        })
        window.postMessage({
          direction: 'intellifox-ui',
          user: null,
        }, '*')
        user.refresh()
      },
    })
  }, [getToken, set_user])

  return [login, getTokenMutation]
}


export const LostPasswordModal = ({ email, set_email }) => {
  const translate = useTranslate()

  const [success, set_success] = React.useState(false)
  const fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      placeholder: translate({
        fr: 'Entre ton adresse email',
        en: 'Enter your email address',
      }),
    },
  ]

  const [reset_password] = useMutation(RESET_PASSWORD)

  const on_click = async () => {
    const success = await await_mutate(reset_password, {
      variables: { email },
    })

    if (!success.resetPassword) {
      throw new Error(translate({
        fr: `L'email "${email}" ne correspond à aucun compte sur Intellifox®`,
        en: `The email "${email}" doesn't match any account on Intellifox®`,
      }))
    }

    set_success(true)
  }
  return <><IntellifoxModal title={translate({
    fr: `J'ai besoin d'aide pour me connecter`,
    en: `I need help to log in`,
  })}
                            action_label={translate({
                              fr: 'Recevoir des instructions par email',
                              en: 'Receive instructions by email',
                            })}
                            action_in_progress_label={translate({
                              fr: 'Vérification en cours',
                              en: 'Verification in progress',
                            })}
                            on_click={on_click}
                            body={<>
                              <div className="row">
                                <div className="col-12">
                                  {translate({
                                    fr: `Pour pouvoir récupérer ton mot de passe, tu dois entrer ton adresse email dans le
                                  champs
                                  ci-dessous :`,
                                    en: `In order to reset your password, you need to enter your email address:`,
                                  })}
                                </div>
                                <div className="col-12">
                                  {displayFields(fields)}
                                </div>

                                <div className="col-12">
                                  {translate({
                                    fr: `Ensuite, clique sur le bouton en bas, "Récupérer mon mot de passe".`,
                                    en: `Then click on the button "Reset password"`,
                                  })}
                                </div>
                                <div className="col-12 mt-3">
                                  <div className="alert alert-info">
                                    <div className="row">
                                      <div className="col-12 font-weight-bold">
                                        {translate({
                                          fr: `Que va-t-il se passer ?`,
                                          en: `What's going to happen?`,
                                        })}
                                      </div>
                                      <div className="col-12">
                                        {translate({
                                          fr: `Si l'adresse email est correcte, alors tu vas recevoir un email avec des
                                        instructions
                                        qui vont te permettre de recréer un nouveau mot de passer.`,
                                          en: `If the email address is correct, then you'll receive an email with instructions on how to reset your password.`,
                                        })}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>}
  >
    {translate({
      fr: `J'ai besoin d'aide pour me connecter`,
      en: `I need help to log in`,
    })}
  </IntellifoxModal>
    <IntellifoxModalComponent show_modal={success} close={() => set_success(false)}
                              title={translate({
                                fr: `Les instructions pour remettre à zéro ton mot de passe ont été envoyées à "${email}"`,
                                en: `Instructions on how to reset your password have been sent to "${email}"`,
                              })}
                              body={<>
                                <div className="row">
                                  <div className="col-12">
                                    {translate({
                                      fr: <>Vérifie ta boîte mail <b>{email}</b> et ton dossier <b>spam</b> si besoin et
                                        suis
                                        les instructions présentes dans l'email.</>,
                                      en: <>Check your inbox for <b>{email}</b> and your <b>spam</b> folder if need.
                                        Then simply follow the instructions contained in the email.</>,
                                    })}
                                  </div>
                                  <div className="col-12">
                                    {translate({
                                        fr: `Bonne journée à toi`,
                                        en: `Have a lovely day!`
                                    })}
                                  </div>
                                </div>
                              </>}/>
  </>
}

export default function Login({ set_user }) {
  const [stored_email, set_stored_email] = useLocalStorage('intellifox_login', '')
  const [remember_me, set_remember_me] = useLocalStorage('intellifox_remember_login', true)
  const translate = useTranslate()

  const [_show_create_account, set_show_create_account] = useHashParam('create_account', 'false')
  const show_create_account = _show_create_account === 'true'

  const [email, set_email] = useState(stored_email)
  const [password, set_password] = useState('')

  const [login, getTokenMutation] = useLogin(set_user)

  useEffect(() => {
    if (remember_me) {
      set_stored_email(email)
    } else {
      set_stored_email('')
    }
  }, [remember_me, stored_email, email])

  const fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      placeholder: translate({
          fr: 'Entre ton adresse email',
          en: 'Enter your email address',
      }),
    },
    {
      type: 'password',
      value: password,
      setter: set_password,
      placeholder: translate({
          fr: 'Entre ton mot de passe',
          en: 'Enter your password',
      }),
      helper: <PasswordHelper/>,
    },
  ]


  const on_submit = async () => {
    try {
      await login(email, password)
    } catch (e) {
    }
  }


  return (
    <FullScreenCentered>
      <Item className="min-vw-md-100">
        {!show_create_account ? <>
            <div className="row mb-3">
              <PageTitle><IntellifoxIcon/> {translate({
                  fr: `Connexion`,
                  en: `Log in`
              })}</PageTitle>
            </div>
            <div className="row">
              <div className="col-12">
                {displayFields(fields, on_submit)}
              </div>
              <Checkbox value={remember_me} setter={set_remember_me}>{translate({
                  fr: `Se souvenir de l'email`,
                  en: `Remember me`
              })}</Checkbox>
              {getTokenMutation.error ? <div className="col-12 mb-2 mt-2">
                <div className="alert alert-danger"><span
                  className="font-weight-bold">{translate({
                      fr: `Erreur :`,
                      en: `Error:`
                  })}</span> {error_to_i18n(getTokenMutation.error)}</div>
              </div> : null}
              <div className="col-12 mt-4">
                <Button onClick={on_submit} disabled={getTokenMutation.loading} className={'w-100'}>{translate({
                    fr: `Se connecter`,
                    en: `Log in`
                })}</Button>
              </div>
            </div>
            <div className="row mt-5 align-items-end">
              <div className={'col-6 '}>
                <Button outline={true} onClick={() => set_show_create_account(true)}>{translate({
                    fr: `Je n'ai pas encore de compte`,
                    en: `I don't have an account yet`
                })}</Button>
              </div>
              <div className="col-6 text-right">
                <LostPasswordModal email={email} set_email={set_email}/>
              </div>
            </div>
          </> :
          <>
            <CreateAccount set_user={set_user} set_show_create_account={set_show_create_account}/>
          </>}
      </Item>
    </FullScreenCentered>
  )
}
