import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'

import '../node_modules/lato-font/css/lato-font.min.css'

import 'react-tippy/dist/tippy.css'
import './app.scss'
import './discreet.scss'


import useLocalStorage from './hooks/useLocalStorage'
import UserContext, { DisconnectContext } from './context/user'
import MainContent from './components/layout/main-content'
import client from './graphql/client'
import { GET_ME_STR } from './graphql/queries/user'
import { Router } from '@reach/router'
import ThemeContext from './context/theme'
import { get_client } from './graphql/simple-client'
import { CachedValues, CachedValuesV2, useCachedContext } from './hooks/useCachedContext'
import IntellifoxIcon from './images/intellifox-icon'
import * as _ from 'lodash'

import LangContext, { get_browser_lang } from './context/lang'
import ProgressBar from './components/generic/progress_bar'
import { GET_IOS_SELLER, GET_IOS_SELLERS } from './graphql/queries/ios_seller'
import { GET_IOS_EMPLOYEES } from './graphql/queries/ios_employee'
import { GET_IOS_ROUTINES } from './graphql/queries/ios_routines'
import { get_ios_procedure_executions, GET_IOS_PROCEDURES } from './graphql/queries/ios_procedures'
import {
  ios_amazon_accounts,
  ios_assists,
  ios_intellinaut_companies,
  ios_intellinaut_transactions_and_processes,
  ios_intellinaut_companies_light, ios_get_next_payment,
} from './graphql/queries/ios_intellinauts'
import { create_query } from './graphql/queries/_query_and_mutations_templates'
import { ios_interface_texts } from './graphql/queries/ios_translations'
import { ios_accounts, ios_transactions } from './graphql/queries/ios_accounting'
import { ios_email_history, ios_email_history_count, ios_emails } from './graphql/queries/ios_emails'
import { get_ios_process_data, get_processes } from './graphql/queries/ios_processes'
import AffiliateDashboard from './routes/affiliate'


function UserApp() {
  const [user_in_localstorage, _set_user] = useLocalStorage('user', '')
  const no_delay_user_ref = React.useRef(user_in_localstorage)

  const set_user = (whatevs) => {
    _set_user(whatevs)
    no_delay_user_ref.current = whatevs
  }

  const fetch_me = async () => {
    if (!( no_delay_user_ref.current && no_delay_user_ref.current.user )) {
      return {}
    }

    const client = get_client()
    const response = await client.request(GET_ME_STR, {
      user_id: no_delay_user_ref.current.user.user_id,
    })

    if (!response.me.company.is_ios_employee) {
      window.location = 'https://intellifox.com'
    }

    return response.me
  }

  return <CachedValues name={'me'} fetch_value={fetch_me}>
    <App user_in_localstorage={user_in_localstorage} set_user={set_user}/>
  </CachedValues>
}

function App({ user_in_localstorage, set_user }) {
  const [theme, set_theme] = useLocalStorage('intellifox-theme', 'light')
  const [lang, set_lang] = useLocalStorage('intellifox_lang', get_browser_lang())

  const me = useCachedContext('me')


  const disconnect = () => {
    set_user('')
    me.refresh()
    window.location.reload()
  }

  useEffect(() => {
    if (me.error) {
      disconnect()
    }
  }, [me.error])


  if (me.loading) {
    return <div className="text-center pt-5"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100vw',
                  height: '100vh',
                  background: 'black',
                  color: 'white',
                  fontSize: 120,
                }}
    >
      <IntellifoxIcon/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ProgressBar progress={100}/>
          </div>
        </div>
      </div>
    </div>
  }

  // if (!me.company || !me.company.is_intellifox_employee) {
  //   window.location = 'https://intellifox.com'
  //   return <>Redirection...</>
  // }

  const isLoggedIn = me && me.token
  const company_id = me && me.company ? me.company.company_id : undefined


  console.log({ me, isLoggedIn })

  const fetch_ios_sellers = async (offset, limit, state, only_with_phone) => {
    const client = get_client()
    const response = await client.request(GET_IOS_SELLERS, {
      company_id, offset, limit, state, only_with_phone,
    })
    return response.ios_sellers.map((seller) => {
      let events
      try {
        events = JSON.parse(seller.events)
      } catch (e) {
      }

      return {
        ...seller,
        events: events ? events : [],
      }
    })
  }

  const fetch_ios_seller = async (seller_id) => {
    const client = get_client()
    const response = await client.request(GET_IOS_SELLER, {
      company_id, seller_id,
    })

    let events
    try {
      events = JSON.parse(response.ios_seller.events)
    } catch (e) {
    }
    let details
    try {
      details = JSON.parse(response.ios_seller.details)
    } catch (e) {
    }

    return {
      ...response.ios_seller,
      events: events ? events : [],
      details: details ? details : {},
    }
  }


  const fetch_ios_employees = async () => {
    const client = get_client()
    const response = await client.request(GET_IOS_EMPLOYEES, {
      company_id,
    })
    return response.ios_employees
  }
  const fetch_ios_routines = async () => {
    const client = get_client()
    const response = await client.request(GET_IOS_ROUTINES, {
      company_id,
    })
    const routines = response.ios_routines.map((routine) => {

      return {
        ...routine,
        details: JSON.parse(routine.details),
        steps: JSON.parse(routine.steps),
      }
    })

    console.log('Routines !', routines)

    return routines

  }

  const parse_procedurish_object = (procedure) => {
    try {
      return {
        ...procedure,
        details: JSON.parse(procedure.details) || {},
        params: procedure.params ? JSON.parse(procedure.params) : {},
        tasks: procedure.tasks.map((task) => {
          return {
            ...task,
            details: JSON.parse(task.details) || {},
            results: task.results.map((result) => {
              return {
                ...result,
                details: JSON.parse(result.details) || {},
              }
            }),
          }
        }),
      }
    } catch (e) {
      console.log('App.js parse_procedurish_object : failed to properly parse procedure', e)
      return procedure
    }
  }

  const fetch_ios_procedures = async (role) => {
    const client = get_client()
    const response = await client.request(GET_IOS_PROCEDURES, {
      company_id,
      role,
    })
    const procedures = response.ios_procedures.map((procedure) => parse_procedurish_object(procedure))

    return procedures

  }
  const fetch_ios_procedure_executions = async () => {
    const client = get_client()
    const response = await client.request(get_ios_procedure_executions, {
      company_id,
    })
    const procedures = response.ios_procedure_executions.map((procedure) => parse_procedurish_object(procedure))

    return procedures

  }

  const fetch_ios_intellinaut_companies = async (where, subscription_status) => {
    const client = get_client()
    const response = await client.request(ios_intellinaut_companies, {
      company_id, where, subscription_status,
    })
    return response.ios_intellinaut_companies
  }

  const fetch_ios_intellinaut_companies_light = async () => {
    const client = get_client()
    const response = await client.request(ios_intellinaut_companies_light, {
      company_id,
    })
    return response.ios_intellinaut_companies
  }
  const fetch_ios_intellinaut_transactions_and_processes = async (intellinaut_company_id) => {
    const client = get_client()
    const response = await client.request(ios_intellinaut_transactions_and_processes, {
      company_id,
      intellinaut_company_id,
    })
    return response.ios_intellinaut_transactions_and_processes
  }

  const fetch_ios_assists = async () => {
    const client = get_client()
    const response = await client.request(ios_assists, {
      company_id,
    })
    return response.ios_assists
  }


  const ios_intellinaut = async (intellinaut_id) => {
    const client = get_client()
    const response = await client.request(`
    query ios_intellinaut(
        $company_id: ID!,
        $intellinaut_id: ID
    ) {
      ios_intellinaut (
        company_id: $company_id,
        intellinaut_id: $intellinaut_id,
      ) {
        first_name
        last_name
        email
      }
    }
`, {
      company_id,
      intellinaut_id,
    })
    return { intellinaut: response.ios_intellinaut }
  }
  const ios_get_intellinaut_revenues = async (intellinaut_id, from, to) => {
    const client = get_client()
    const response = await client.request(`
    query ios_get_intellinaut_revenues(
        $company_id: ID!,
        $intellinaut_id: String
        $from: Float
        $to: Float
    ) {
      ios_get_intellinaut_revenues (
        company_id: $company_id,
        intellinaut_id: $intellinaut_id,
        from: $from,
        to: $to,
      )
    }
`, {
      company_id,
      intellinaut_id,
      from,
      to,
    })
    return { revenues: response.ios_get_intellinaut_revenues }
  }

  const fetch_ios_mrr = async () => {
    const client = get_client()
    const response = await client.request(`
      query ios_mrr($company_id: ID!) {
          ios_mrr (
            company_id: $company_id,
          ) {
              start
              end
              mrr
              intellinauts
              
              details {
                date
                intellinaut_id
                referral_id
                mrr
                frequency  
              }
              
              affiliates {
                name
                referral_id
                start
                end
                mrr
                intellinauts
                secret
              }
          }
      }
  `, {
      company_id,
    })
    return _.reverse(response.ios_mrr)
  }


  const ios_publishes = async (limit, offset, optional_intellinaut_id) => {
    const client = get_client()
    const response = await client.request(`
    query ios_publishes(
        $company_id: ID!,
        $limit: Int
        $offset: Int
        $optional_intellinaut_id: ID
    ) {
      ios_publishes (
        company_id: $company_id,
        limit: $limit,
        offset: $offset,
        optional_intellinaut_id: $optional_intellinaut_id,
      ) {
        intellinaut_id
        publish_id
        start
        status
        market_place
        product_image_submission_id
        product_image_submission_status
        product_image_submission_reports
        product_submission_id
        product_submission_status
        product_submission_reports
        price_submission_id
        price_submission_status
        price_submission_reports
      }
    }
`, {
      company_id,
      limit, offset,
      optional_intellinaut_id,
    })
    return { ios_publishes: response.ios_publishes }
  }

  const ios_publish = async (publish_id) => {
    const client = get_client()
    const response = await client.request(`
    query ios_publish(
        $company_id: ID!,
        $publish_id: ID
    ) {
      ios_publish (
        company_id: $company_id,
        publish_id: $publish_id,
      ) {
        intellinaut_id
        publish_id
        start
        status
        products
        market_place
        product_image_submission_id
        product_image_submission_status
        product_image_submission_reports
        product_image_submission_xml
        product_submission_id
        product_submission_status
        product_submission_reports
        product_submission_xml
        price_submission_id
        price_submission_status
        price_submission_reports
        price_submission_xml
        
        images_to_build {
          sku
          index
          status
          url
          image_failed
        }
      }
    }
`, {
      company_id,
      publish_id,
    })
    return { ios_publish: response.ios_publish }
  }

  const ios_processes = async (limit, offset, optional_intellinaut_id, only_unfinished) => {
    const client = get_client()

    const response = await client.request(get_processes, {
      company_id,
      limit, offset,
      optional_intellinaut_id,
      only_unfinished,
    })

    return response
  }

  const ios_process_data = async (intellinaut_id, process_id) => {
    const client = get_client()

    const response = await client.request(get_ios_process_data, {
      company_id,
      intellinaut_id,
      process_id,
    })

    if (!response.ios_process_data) {
      return {}
    }

    return {
      ios_process_data: {
        ...response.ios_process_data,
        data: JSON.parse(response.ios_process_data.data),
      },
    }
  }

  const ios_import = async (import_id) => {
    const client = get_client()
    const response = await client.request(`
    query ios_import(
        $company_id: ID!,
        $import_id: ID
    ) {
      ios_import (
        company_id: $company_id,
        import_id: $import_id,
      ) {
        intellinaut_id
        import_id
        market_places
        start_date
        status
        error
        total
        progress
        imported_products {
          market_place
          asin
          sku
          status
          fnsku
          parent_asin
          packaging_length
          packaging_width
          packaging_height
          packaging_weight
          title
          variation
          key_points
          description
          images
          standard_price
          sale_price
          created_product_id
          step_2_done
        }
      }
    }
`, {
      company_id,
      import_id,
    })
    return { ios_import: response.ios_import }
  }
  const ios_product = async (product_id) => {
    const client = get_client()
    const response = await client.request(`
    query ios_product(
        $company_id: ID!,
        $product_id: ID
    ) {
      ios_product (
        company_id: $company_id,
        product_id: $product_id,
      ) {
        product_id
        date_created
        sku
        asin
        fnsku
        packaging_length
        packaging_width
        packaging_height
        packaging_weight
        is_gift_wrap_available
        is_gift_message_available
        variation_type
        state
        last_update
        product_infos {
          market_place
          title
          key_points
          description
          variation
          standard_price
          min_price
          max_price
          msrp
          sale_price
          sale_from_date
          sale_end_date
          price
          last_update
          last_publish
          asin_override
          sku_override
        }
      }
    }
`, {
      company_id,
      product_id,
    })
    return { ios_product: response.ios_product }
  }

  const fetch_v2 = ({ query, params, output }) => {
    return async (...args) => {
      const client = get_client()

      const keys = Object.keys(params).filter((k) => k !== 'company_id')
      const values = {}
      for (let i = 0; i < keys.length; i++) {
        values[ keys[ i ] ] = args[ i ]
      }
      const response = await client.request(
        create_query({
            query, params, output,
          },
        ),
        {
          company_id,
          ...values,
        })
      return response[ query ]
    }
  }

  const fetch_ios_get_next_payment = async (subscription_id) => {
    const client = get_client()
    const response = await client.request(ios_get_next_payment, {
      company_id, subscription_id,
    })

    let next_payment = {}
    try {
      next_payment = JSON.parse(response.ios_get_next_payment)
    } catch (e) {
      console.log('could not parse ios_get_next_payement', e, response)
    }
    return next_payment
  }

  const plans = create_query({
    query: 'plans',
    params: {
      currency: 'String',
      special: 'String',
    },
    output: `
  {
    subscription_tier
    label_en
    description_en
    products_limit
    accounts_limit
    monthly_ai_bg_removal_limit
    managed_ads_limit
    monthly_plan_id
    yearly_plan_id
    yearly_price
    monthly_price
    monthly_price_reduced
    yearly_price_reduced
    reduced_coupon
    free_trial_days
  }
  `,
  })

  const fetch_plans = async (currency, special) => {
    const client = get_client()
    const response = await client.request ( plans, {
      currency,
      special,
    } )
    return response.plans
  }

  const fetch_ios_interface_texts = fetch_v2(ios_interface_texts)
  const fetch_ios_email_history = fetch_v2(ios_email_history)
  const fetch_ios_email_history_count = fetch_v2(ios_email_history_count)
  const fetch_ios_amazon_accounts = fetch_v2(ios_amazon_accounts)

  const fetch_ios_transactions = fetch_v2(ios_transactions)

  const fetch_ignored_subscription_ids = fetch_v2({
    query: 'ignored_subscription_ids',
    params: {
      company_id: 'ID!',
    },
    output: `{
      subscription_id
      reason
      date
    }`,
  })
  const fetch_ios_knowledge_base = fetch_v2({
    query: 'ios_knowledge_base',
    params: {
      company_id: 'ID!',
    },
    output: `{
      session_id
      date
      category
      problem
      answer
      related_help_article
    }`,
  })

  return (
    <DisconnectContext.Provider value={disconnect}>
      <ThemeContext.Provider value={{ theme, set_theme }}>
        <LangContext.Provider value={{ lang, set_lang }}>
          <UserContext.Provider value={me}>
            <CachedValuesV2 query={ios_accounts}>
              <CachedValuesV2 query={ios_emails}>
                <CachedValues name={'plans'} fetch_value={fetch_plans}>

                  <CachedValues name={'ios_mrr'} fetch_value={fetch_ios_mrr}>
                    <CachedValues name={'ios_transactions'} fetch_value={fetch_ios_transactions}>
                      <CachedValues name={'ios_get_next_payment'} fetch_value={fetch_ios_get_next_payment}>
                        <CachedValues name={'ios_intellinaut'} fetch_value={ios_intellinaut}>
                          <CachedValues name={'ios_assists'} fetch_value={fetch_ios_assists}>
                            <CachedValues name={'ios_intellinaut_companies'}
                                          fetch_value={fetch_ios_intellinaut_companies}>
                              <CachedValues name={'ios_intellinaut_companies_light'}
                                            fetch_value={fetch_ios_intellinaut_companies_light}>
                                <CachedValues name={'ios_intellinaut_transactions_and_processes'}
                                              fetch_value={fetch_ios_intellinaut_transactions_and_processes}>
                                  <CachedValues name={'ios_procedure_executions'}
                                                fetch_value={fetch_ios_procedure_executions}>
                                    <CachedValues name={'ios_procedures'} fetch_value={fetch_ios_procedures}>
                                      <CachedValues name={'ios_routines'} fetch_value={fetch_ios_routines}>
                                        <CachedValues name={'ios_employees'} fetch_value={fetch_ios_employees}>
                                          <CachedValues name={'ios_sellers'} fetch_value={fetch_ios_sellers}>
                                            <CachedValues name={'ios_seller'} fetch_value={fetch_ios_seller}>
                                              <CachedValues name={'ios_publishes'} fetch_value={ios_publishes}>
                                                <CachedValues name={'ios_publish'} fetch_value={ios_publish}>
                                                  <CachedValues name={'ios_processes'} fetch_value={ios_processes}>
                                                    <CachedValues name={'ios_process_data'}
                                                                  fetch_value={ios_process_data}>
                                                      <CachedValues name={'ios_import'} fetch_value={ios_import}>
                                                        <CachedValues name={'ios_product'} fetch_value={ios_product}>
                                                          <CachedValues name={'ignored_subscription_ids'}
                                                                        fetch_value={fetch_ignored_subscription_ids}>
                                                          <CachedValues name={'ios_knowledge_base'}
                                                                        fetch_value={fetch_ios_knowledge_base}>
                                                            <CachedValues name={'ios_interface_texts'}
                                                                          fetch_value={fetch_ios_interface_texts}>
                                                              <CachedValues name={'ios_email_history'}
                                                                            fetch_value={fetch_ios_email_history}>
                                                                <CachedValues name={'ios_email_history_count'}
                                                                              fetch_value={fetch_ios_email_history_count}>
                                                                  <CachedValues name={'ios_amazon_accounts'}
                                                                                fetch_value={fetch_ios_amazon_accounts}>
                                                                    <CachedValues name={'ios_get_intellinaut_revenues'}
                                                                                  fetch_value={ios_get_intellinaut_revenues}>
                                                                      <Router>
                                                                        <MainContent isLoggedIn={isLoggedIn}
                                                                                     disconnect={disconnect}
                                                                                     set_user={set_user}
                                                                                     path={'*'}/>
                                                                      </Router>
                                                                    </CachedValues>
                                                                  </CachedValues>
                                                                </CachedValues>
                                                              </CachedValues>
                                                              </CachedValues>
                                                            </CachedValues>
                                                          </CachedValues>
                                                        </CachedValues>
                                                      </CachedValues>
                                                    </CachedValues>
                                                  </CachedValues>
                                                </CachedValues>
                                              </CachedValues>
                                            </CachedValues>
                                          </CachedValues>
                                        </CachedValues>
                                      </CachedValues>
                                    </CachedValues>
                                  </CachedValues>
                                </CachedValues>
                              </CachedValues>
                            </CachedValues>
                          </CachedValues>
                        </CachedValues>
                      </CachedValues>
                    </CachedValues>
                  </CachedValues>
                </CachedValues>
              </CachedValuesV2>
            </CachedValuesV2>
          </UserContext.Provider>
        </LangContext.Provider>
      </ThemeContext.Provider>
    </DisconnectContext.Provider>
  )
}

export default () => (
  <ApolloProvider client={client}>
    <Router>
      <AffiliateDashboard path={'/affiliate-dashboard/:secret'}/>
      <UserApp path={'*'}/>
    </Router>

  </ApolloProvider>
)
