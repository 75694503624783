import { gql } from "@apollo/client";

export const LINK_ACCOUNT = gql`
  mutation Mutation(
    $mainAccount: String!
    $secondaryAccount: String!
    $companyId: ID!
  ) {
    link_accounts(
      main_account: $mainAccount
      secondary_account: $secondaryAccount
      company_id: $companyId
    )
  }
`;
