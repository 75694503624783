import React from 'react'

import './button.css'

export default function ExternalLink({ children, className = '', to, style = {} }) {
  if (!to) {
    return children
  }
  return (
      <a href={to} target='_blank' className={`external_link ${className}`} style={style}>{children}</a>
  )
}
