import React from 'react'
import Button from './button'
import { Modal } from 'react-bootstrap'
import { useTheme } from '../../context/theme'
import { useTranslate } from '../../context/lang'


export const IntellifoxModalComponent = ({ show_modal, close, title, body, on_click, action_label, action_in_progress_label, disable_action }) => {

  const theme = useTheme()
  const [in_progress, set_in_progress] = React.useState(false)
  const [error, set_error] = React.useState('')
  const translate = useTranslate()

  return <Modal show={show_modal} onHide={close} dialogClassName={`variable-root ${theme.theme}`} size={'xl'}
                style={{ zIndex: 999999 }}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    { body ? <Modal.Body>
      {body}
    </Modal.Body> : null}
    {action_label ? <Modal.Footer>
      {error ? <div className="alert alert-danger p-2 m-2"><b>Erreur</b> : {error}</div> : null}

      {in_progress ? null : <Button outline={true} onClick={close} className="mr-2">
        {translate({
            fr: `Annuler`,
            en: `Cancel`
        })}
      </Button>}
      <Button disabled={in_progress || disable_action} onClick={async () => {
        set_in_progress(true)
        set_error('')

        try {
          await on_click()
          close()
        } catch (e) {
          set_error(e.message)
        }

        set_in_progress(false)

      }}>
        {in_progress ? <>{action_in_progress_label}</> : <>{action_label}</>}
      </Button>
    </Modal.Footer> : <Modal.Footer>
      <Button disabled={in_progress || disable_action} onClick={async () => {
        close()
      }}>
        {translate({
          fr: `Fermer`,
          en: `Close`,
        })}
      </Button>
    </Modal.Footer>
    }
  </Modal>
}

export default function IntellifoxModal({
                                          show_modal_default = false, title, body, dynamic_body, on_click, action_label, action_in_progress_label = 'En cours...', children, button_props = {}, disable_action, on_open = () => {
  },
                                        }) {
  const [show_modal, set_show_modal] = React.useState(show_modal_default)

  React.useEffect(() => {
    set_show_modal(show_modal_default)
  }, [show_modal_default])

  const close = () => set_show_modal(false)

  return <>
    <Button onClick={() => {
      on_open()
      set_show_modal(true)
    }} {...button_props}>{children}</Button>

    <IntellifoxModalComponent show_modal={show_modal} close={close} on_click={on_click} action_label={action_label}
                              body={show_modal ? (dynamic_body ? dynamic_body(close) : body) : null} title={title} action_in_progress_label={action_in_progress_label}
                              disable_action={disable_action}/>
  </>
}
