import React from 'react'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../graphql/queries/_utils'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import Field from '../../components/generic/field'

const severities = [
  {
    label: 'Low',
    description: `Non-blocking issue that can wait to be resolved. Ex: future billing, usage bug that doesn't prevent using the feature, etc...`,
    bg: 'success',
  },
  {
    label: 'Medium',
    description: `Blocking issue that must be resolved quickly, but the intellinaute's life does not depend on it. Ex: bug during publication, account addition problem, short-term billing problem, etc...`,
    bg: 'warning',
  },
  {
    label: 'High',
    description: `Urgent issue that blocks entire sectors: white page error, inability to add an image, technical impossibility to create an account or to log in.`,
    bg: 'danger',
  },
]

const categories = [
  { label: 'Account management' },
  { label: 'Refund request' },
  { label: 'Payment problem' },
  { label: 'Duplicated account' },
  { label: 'Bug/Question: product import' },
  { label: 'Bug/Question: product editing' },
  { label: 'Bug/Question: image editing' },
  { label: 'Bug/Question: price editing' },
  { label: 'Bug/Question: publication' },
  { label: 'Bug/Question: sales tracking' },
  { label: 'Bug/Question: ad management' },
  { label: 'Bug/Question: comment requests' },
  { label: 'Other' },
];

export default function AskForCeoHelp({ intellinaut_company_id }) {
  const company_id = useCompanyId()

  const [do_it] = useMutation(create_mutation({
    mutation: 'ios_ask_help_from_ceo',
    params: {
      company_id: 'ID!',
      intellinaut_company_id: 'String',
      severity: 'String',
      category: 'String',
      summary: 'String',
      details: 'String',
      case_url: 'String',
    },
  }))

  const [severity, set_severity] = React.useState(null)
  const [category, set_category] = React.useState(null)
  const [summary, set_summary] = React.useState('')
  const [details, set_details] = React.useState('')
  const [case_url, set_case_url] = React.useState('')

  const ask_for_help = async () => {
    if (!severity || !category || !summary || !case_url) {
      throw new Error('You need to fill all the fields')
    }
    await await_mutate(do_it, {
      variables: {
        company_id,
        intellinaut_company_id,
        severity: severity.label,
        category: category.label,
        summary,
        details,
        case_url,
      },
    })
  }


return (
  <div className="row mt-5">
    <Field type={'select'} value={severity} setter={set_severity} label={'Severity *'} options={severities}
           option_button={(o) => o.bg}
           option_label={(o) => o.label} option_detail={(o) =>  <span className="small">{o.description}</span>}/>
    <Field type={'select'} value={category} setter={set_category} label={'Category *'} options={categories}
           option_label={(o) => o.label}/>
    <Field type={'text'} value={summary} setter={set_summary} label={'Summary *'} placeholder={'Quick description of the problem'}/>
    <Field type={'textarea'} value={details} setter={set_details} label={'Additional details'}/>
    <Field type={'text'} value={case_url} setter={set_case_url} label={'Crisp conversation address *'}
    placeholder={'https://app.crisp.chat/website/1749a2cd-031f-41e1-97c3-4c1f1d788bbf/inbox/...'}/>


    <div className="col-12">
      <UpdateButtonV2 trigger={ask_for_help} errorMessage={'Error: All mandatory fields must be filled'}>Send the email Conrad</UpdateButtonV2>
    </div>
  </div>
)
}
