export const await_mutate = (trigger_mutation, { variables, update } = {}) => {
  return new Promise((resolve, reject) => {
    trigger_mutation({
      variables,
      update: (_, mutation) => {
        update && update(mutation)
        if (mutation.error) {
          reject(mutation.error)
        } else {
          resolve(mutation.data)
        }
      },
    }).catch(e => reject(e))
  })
}
