import { useMutation } from "@apollo/client";
import React from "react";
import Button from "../../components/generic/button";
import { Checkbox } from "../../components/generic/checkbox";
import DeleteButtonV2 from "../../components/generic/delete-button-v2";
import Field from "../../components/generic/field";
import {
  DefaultError,
  DefaultLoading,
} from "../../components/i18n/translations";
import { await_mutate } from "../../graphql/mutate_promise";
import { create_mutation } from "../../graphql/queries/_utils";
import { is_ready, useCachedContext } from "../../hooks/useCachedContext";
import useCompanyId from "../../hooks/useCompanyId";
import { format_currency } from "../intellifox";
import { DisplayNextPayment } from "./cheque_france_num";

export const currencies = [
  {
    code: "USD",
    symbol: "$",
  },
  {
    code: "EUR",
    symbol: "€",
  },
  {
    code: "GBP",
    symbol: "£",
  },
  {
    code: "CAD",
    symbol: "$",
  },
];

export const MoveToNewPlan = ({ company }) => {
  const company_id = useCompanyId();
  const { intellinaut_company_id, transactions } = company;

  const last_transaction = transactions[transactions.length - 1];

  const [currency, set_currency] = React.useState(
    last_transaction?.currency || "EUR"
  );

  const next_payment = useCachedContext(
    "ios_get_next_payment",
    "" + last_transaction?.subscription_id
  );

  const plans = useCachedContext("plans", currency);

  const [price, set_price] = React.useState("");
  const [selected_plan_id, set_selected_plan_id] = React.useState("");

  const [move_to_new_plan] = useMutation(
    create_mutation({
      mutation: "move_to_new_plan",
      params: {
        company_id: "ID!",
        intellinaut_company_id: "ID!",
        price: "Float",
        currency: "String",
        plan_id: "String",
      },
    })
  );

  React.useEffect(() => {
    if (is_ready(plans)) {
      const selected_plan = plans.find(
        (p) =>
          p.monthly_plan_id === selected_plan_id ||
          p.yearly_plan_id === selected_plan_id
      );

      if (selected_plan) {
        const price =
          selected_plan.monthly_plan_id === selected_plan_id
            ? selected_plan.monthly_price
            : selected_plan.yearly_price;

        set_price(price);
      }
    }
  }, [currency, plans]);

  if (next_payment.loading || plans.loading) {
    return <DefaultLoading />;
  }

  if (next_payment.error || plans.error) {
    return <DefaultError />;
  }

  const do_move_to_new_plan = async () => {
    console.log("Move to new plan...");
    await await_mutate(move_to_new_plan, {
      variables: {
        company_id,
        intellinaut_company_id,
        price,
        currency,
        plan_id: "" + selected_plan_id,
      },
    });
    await next_payment.refresh();
    console.log("Done!");
  };

  /*
  1: determine current user state, plans can only be moved if out of free trial and if subscription is active
  2: show list of possible plans with automated but editable display
  3: show updated next payment for confirmation
   */

  return (
    <div className="row">
      <div className="col-12">
        {currencies.map(({ code, symbol }) => {
          return (
            <Button
              button={code === currency ? "primary" : "secondary"}
              onClick={() => set_currency(code)}
            >
              {code}
            </Button>
          );
        })}
      </div>
      <div className="col-12">
        <DisplayNextPayment
          intellinaut_id={intellinaut_company_id}
          subscription_id={last_transaction?.subscription_id}
          show_reschedule={false}
        />
      </div>
      <div className="col-12">
        {plans.map(
          ({
            subscription_tier,
            label_en,
            description_en,
            monthly_plan_id,
            yearly_plan_id,
            yearly_price,
            monthly_price,
          }) => {
            return (
              <div className="row">
                <div className="col-12">
                  <Checkbox
                    value={selected_plan_id === monthly_plan_id}
                    setter={() => {
                      set_selected_plan_id(monthly_plan_id);
                      set_price(monthly_price);
                    }}
                  >
                    {label_en} (monthly){" "}
                    {format_currency(monthly_price, currency)}/month
                  </Checkbox>
                </div>
                <div className="col-12">
                  <Checkbox
                    value={selected_plan_id === yearly_plan_id}
                    setter={() => {
                      set_selected_plan_id(yearly_plan_id);
                      set_price(yearly_price);
                    }}
                  >
                    {label_en} (yearly){" "}
                    {format_currency(yearly_price, currency)}/year
                  </Checkbox>
                </div>
              </div>
            );
          }
        )}

        <div className="col-12">
          <Field
            type={"number"}
            value={price}
            setter={set_price}
            label={"Price"}
          />
        </div>

        <DeleteButtonV2 do_delete={do_move_to_new_plan}>
          Move to new plan
        </DeleteButtonV2>
      </div>
    </div>
  );
};
