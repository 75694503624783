import React, { useContext } from "react";

import useHashParam from "use-hash-param";
import { useTranslate } from "../../context/lang";
import UserContext, { useDisconnect } from "../../context/user";
import { useCachedContext } from "../../hooks/useCachedContext";
import Button from "../generic/button";
import Field from "../generic/field";
import Item from "../generic/item";
import TextButton from "../generic/text_button";
import { DefaultError, DefaultLoading } from "../i18n/translations";

// eslint-disable-next-line import/no-webpack-loader-syntax
import new_product from "!file-loader!../../images/story/new_product.svg";
import { FaArrowRight } from "react-icons/all";
import IntellifoxIcon from "../../images/intellifox-icon";
import { Checkbox } from "../generic/checkbox";
import IntellifoxModal from "../generic/modal";

export const subscription_plans = {
  588834: "Intellifox Mensuel",
  588840: "Intellifox Annuel",

  // todo these labels are used in billing
};

const CardWarning = () => {
  const translate = useTranslate();

  return (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-success">
          <div className="row">
            <div className="col-12 font-weight-bold">
              <span className="h1">
                <IntellifoxIcon />
              </span>{" "}
              {translate({
                fr: `Une carte bancaire ou un compte PayPal est nécessaire pour bénéficier de la période d'essai`,
                en: `A credit card or PayPal account is required`,
              })}
            </div>
            <div className="col-12">
              {translate({
                fr: `Ceci est mis en place pour éviter les bots et limiter la fraude afin de garantir un service de qualité.`,
                en: `This is to avoid bots and reduce fraud in order to give you the best service possible.`,
              })}
            </div>
            <div className="col-12">
              {translate({
                fr: `Des emails de rappels sont envoyés avant la fin de la période d'essai et il est possible d'annuler à tout moment ton abonnement.`,
                en: `Emails are sent before the end of the trial period and it is possible to cancel anytime.`,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TriggerPaddleModal = ({ children, on_click }) => {
  return (
    <IntellifoxModal
      title={"Éviter la fraude"}
      body={
        <>
          <CardWarning />
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
              <img src={new_product} alt="" />
            </div>
          </div>
        </>
      }
      on_click={on_click}
      action_label={`J'ai compris, procéder à l'inscription`}
      button_props={{
        className: "btn-lg font-weight-bold p-4 btn-block",
      }}
    >
      {children}
    </IntellifoxModal>
  );
};

const Badge = ({ children, badge }) => {
  return (
    <div className="h1 d-inline-block">
      <div className={`badge badge-${badge} d-inline-block`}>{children}</div>
    </div>
  );
};

const ReductionBadge = ({ reduction, can_be_zero }) => {
  if (isNaN(reduction) || (!reduction && !can_be_zero)) {
    return null;
  }

  return (
    <Badge badge={reduction > 0 ? "success" : "secondary"}>
      {reduction > 0 ? "-" : ""} {Math.round(reduction * 100)}%
    </Badge>
  );
};
const PlanChooser = ({
  reduced_price,
  price,
  trial_days,
  coupon,
  reduction,
  button_text,
  payment_detail,
  plan_id,
  has_reduction,
  is_yearly,
  is_expired,
  only_first_month,
}) => {
  const translate = useTranslate();

  const user = useContext(UserContext);
  const { email } = user.user;
  const { company_id } = user.company;

  const VAT = (
    <>
      {translate({
        fr: `La TVA est ajoutée en sus si applicable, en fonction du pays d'immatriculation et du type de société`,
        en: `Prices are excl. VAT. If applicable, VAT will be added during checkout.`,
      })}
    </>
  );
  const soit = (
    <>
      {is_yearly ? (
        <>
          {((reduced_price || price) * 12).toFixed(2)} €{" "}
          {translate({
            fr: `HT par an`,
            en: `Per Year Excl. VAT`,
          })}
        </>
      ) : (
        <>
          {(reduced_price || price).toFixed(2)} €{" "}
          {translate({
            fr: `HT par mois${only_first_month ? " le premier mois" : ""}`,
            en: `Per Month Excl. VAT`,
          })}
        </>
      )}{" "}
      {translate({
        fr: `ou`,
        en: `or`,
      })}{" "}
      {is_yearly ? (
        <>
          {((reduced_price || price) * 12 * 1.2).toFixed(2)} €{" "}
          {translate({
            fr: `par an`,
            en: `Per Year`,
          })}
        </>
      ) : (
        <>
          {((reduced_price || price) * 1.2).toFixed(2)} €{" "}
          {translate({
            fr: `par mois`,
            en: `Per Month`,
          })}
        </>
      )}{" "}
      {translate({
        fr: `pour une TVA à 20%`,
        en: `For a 20% VAT`,
      })}
    </>
  );

  const monthly_with_trial = only_first_month ? (
    <>{(reduced_price || price).toFixed(2)} € HT le premier mois</>
  ) : (
    <>{(reduced_price || price).toFixed(2)} € HT par mois</>
  );

  return (
    <div className="row mb-2">
      <div className="col-12 text-center mb-0">
        {trial_days > 0 ? (
          <>
            <span
              className="h2 font-weight-bold"
              style={{ color: "var(--green)" }}
            >
              {translate({
                fr: (
                  <>
                    {trial_days} jours à 0 €, puis{" "}
                    {is_yearly ? (
                      <>
                        {((reduced_price || price) * 12).toFixed(2)} € HT par an
                      </>
                    ) : (
                      monthly_with_trial
                    )}
                  </>
                ),
                en: (
                  <>
                    {trial_days} days for 0 €, then{" "}
                    {(reduced_price || price).toFixed(2)} € Per Month Excl. VAT
                  </>
                ),
              })}
            </span>
          </>
        ) : (
          <>
            {is_yearly ? (
              <span
                className="h2 font-weight-bold"
                style={{ color: "var(--green)" }}
              >
                {translate({
                  fr: (
                    <>
                      {((reduced_price || price) * 12).toFixed(2)} € HT par an
                    </>
                  ),
                  en: (
                    <>
                      {((reduced_price || price) * 12).toFixed(2)} € Per Year
                      Excl. VAT
                    </>
                  ),
                })}
              </span>
            ) : (
              <span
                className="h2 font-weight-bold"
                style={{ color: "var(--green)" }}
              >
                {translate({
                  fr: <>{(reduced_price || price).toFixed(2)} € HT par mois</>,
                  en: (
                    <>
                      {(reduced_price || price).toFixed(2)} € Per Month Excl.
                      VAT
                    </>
                  ),
                })}
              </span>
            )}
          </>
        )}
      </div>

      <div className="col-12 mt-3 text-center">
        <TriggerPaddleModal
          on_click={() => {
            if (window.fbq) {
              window.fbq("track", "InitiateCheckout");
            }

            window.Paddle.Checkout.open({
              product: plan_id,
              email,
              coupon,
              passthrough: company_id,
              disableLogout: true,
              eventCallback: (data) => {
                console.log("Paddle event", data);
              },
            });
          }}
        >
          {trial_days > 0
            ? translate({
                fr: <>Découvrir Intellifox® avec {trial_days} jours d'essai</>,
                en: <>Discover Intellifox® with a {trial_days}-day trial </>,
              })
            : translate({
                fr: <>Découvrir Intellifox®</>,
                en: <>Discover Intellifox®</>,
              })}{" "}
          <FaArrowRight />
        </TriggerPaddleModal>
      </div>

      {trial_days > 0 ? (
        <div className="col-12 text-center small">
          {VAT}.{" "}
          {translate({
            fr: (
              <>
                Soit 0 € pendant {trial_days} jours, puis {soit}.
              </>
            ),
            en: (
              <>
                0 € for {trial_days} days, then {soit}.
              </>
            ),
          })}
        </div>
      ) : (
        <div className="col-12 text-center small">
          {VAT}.{" "}
          {translate({
            fr: <>Soit {soit}.</>,
            en: <>{soit}.</>,
          })}
        </div>
      )}
    </div>
  );
};

const Option = ({ value, children, setter, name, current, detail }) => {
  return (
    <Checkbox
      value={value === current}
      setter={() => setter(value)}
      className="h3"
    >
      <div className={`${value === current ? "font-weight-bold" : ""}`}>
        {detail} {children}
      </div>
    </Checkbox>
  );
};

export default function SelectPlan({ is_from_cancelled_plan }) {
  const user = useContext(UserContext);
  const translate = useTranslate();

  const [coupon_code, set_coupon_code] = useHashParam("coupon", "");

  const can_do_yearly = !coupon_code || !/black_friday/i.test(coupon_code);

  const plans = useCachedContext("plans", "", is_from_cancelled_plan);
  const plans_with_coupon = useCachedContext(
    "plans",
    coupon_code,
    is_from_cancelled_plan
  );

  const [_yearly, set_yearly] = React.useState(undefined);

  const yearly = can_do_yearly && _yearly;

  const [product_number, set_product_number] = React.useState("startup");

  const disconnect = useDisconnect();

  const refresh_user = async () => {
    console.log("Check user for has_active_subscription...");
    const updated_user = await user.refresh();

    if (
      updated_user &&
      updated_user.company &&
      updated_user.company.has_active_subscription
    ) {
      console.log("Has active subscription ! STOP LOOP");
      window.fbq("track", "StartTrial", {
        value: "0.00",
        currency: "EUR",
        predicted_ltv: "140",
      });

      return;
    }

    console.log("Does not have active subscription... repoll in 5000ms");

    setTimeout(() => refresh_user(), 5000);
  };

  React.useEffect(() => {
    setTimeout(() => refresh_user(), 5000);
  }, []);

  if (plans.loading && !plans.map) {
    return <DefaultLoading />;
  }

  if (plans.error) {
    return <DefaultError />;
  }

  const product_number_tester = new RegExp(product_number, "i");
  const plan = (plans_with_coupon.map ? plans_with_coupon : plans).find((p) =>
    product_number_tester.test(p.label)
  );

  const has_reduction = plan.reduction;

  const is_expired =
    plans_with_coupon &&
    plans_with_coupon.length > 0 &&
    plans_with_coupon[0].is_expired;

  const has_applied_coupon =
    plans_with_coupon &&
    plans_with_coupon.length > 0 &&
    !plans_with_coupon[0].is_expired &&
    plans_with_coupon[0].reduction > 0;

  const ProductNumberOption = ({
    value,
    children,
    setter = set_product_number,
  }) => {
    const product_number_tester = new RegExp(value, "i");

    const plan = (plans_with_coupon.map ? plans_with_coupon : plans).find((p) =>
      product_number_tester.test(p.label)
    );

    if (!plan) {
      return null;
    }

    const is_current = value === product_number;

    return (
      <Button
        onClick={() => set_product_number(value)}
        className={`btn-lg ${is_current ? "font-weight-bold" : ""}`}
        button={is_current ? "success" : "secondary"}
        outline={!is_current}
      >
        {children}
        <br />
        {plan.price_monthly.toFixed(2)} € HT par mois
      </Button>
    );

    return (
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <label
              className={`clickable ${value === product_number ? "font-weight-bold" : ""}`}
            >
              <input
                type="radio"
                name="product_number"
                value={value}
                checked={value === product_number}
                onChange={(e) => set_product_number(e.target.value)}
              />{" "}
              {children}
            </label>
          </div>
          <div
            className={`col-6 text-right ${value === product_number ? "h3 font-weight-bold" : ""}`}
          >
            {plan.price_monthly.toFixed(2)} € HT par mois
          </div>
        </div>
      </div>
    );
  };

  const end_of_form = (
    <>
      <div className="col-12 mb-4">
        <div className="row">
          <div className="col-12 h5">
            {translate({
              fr: (
                <>
                  Coupon de réduction <small>(Optionnel)</small>
                </>
              ),
              en: (
                <>
                  Coupon <small>(Optional)</small>
                </>
              ),
            })}
          </div>

          <div className="col-12">
            <Item>
              <div className="row align-items-center">
                <div className="col-auto">
                  {plan.reduction && !is_expired ? (
                    <>
                      {plan.trial_days > 0 ? (
                        <Badge badge={"success"}>
                          {translate({
                            fr: `${plan.trial_days} JOURS GRATUITS`,
                            en: `${plan.trial_days}-DAY TRIAL`,
                          })}
                        </Badge>
                      ) : null}
                      <ReductionBadge reduction={plan.reduction} />
                    </>
                  ) : null}
                </div>
                <div className="col-auto">
                  {has_applied_coupon && !is_expired ? (
                    <span
                      className="font-weight-bold"
                      style={{ color: "green" }}
                    >
                      ✓ {plans_with_coupon[0].coupon}{" "}
                      <TextButton onClick={() => set_coupon_code("")}>
                        <small>
                          {translate({
                            fr: `Supprimer le coupon`,
                            en: `Remove coupon`,
                          })}
                        </small>
                      </TextButton>
                    </span>
                  ) : (
                    <Field
                      type={"text"}
                      placeholder={translate({
                        fr: "Coupon de réduction",
                        en: "Coupon",
                      })}
                      value={coupon_code}
                      setter={set_coupon_code}
                    />
                  )}
                </div>
              </div>

              {is_expired ? (
                <div className="row">
                  <div
                    className="col-12 font-weight-bold"
                    style={{ color: "red" }}
                  >
                    {translate({
                      fr: `Ce coupon n'est plus valide`,
                      en: `This coupon is expired`,
                    })}
                  </div>
                </div>
              ) : null}
            </Item>
          </div>
        </div>
      </div>

      <div className="col-12 h3">
        <hr />
      </div>

      {product_number ? (
        <div className="col-12">
          <Item>
            <div className="row">
              <div className="col-12">
                {yearly ? (
                  <PlanChooser
                    reduced_price={plan.reduced_price_yearly}
                    price={plan.price_yearly}
                    button_text={`${plan.label} Annuel`}
                    trial_days={plan.trial_days}
                    payment_detail={plan.details}
                    plan_id={plan.plan_id_yearly}
                    coupon={plan.coupon}
                    reduction={plan.reduction}
                    is_expired={plan.is_expired}
                    has_reduction={true}
                    is_yearly={true}
                    only_first_month={plan.only_first_month}
                  />
                ) : (
                  <>
                    <PlanChooser
                      reduced_price={plan.reduced_price_monthly}
                      price={plan.price_monthly}
                      trial_days={plan.trial_days}
                      button_text={`${plan.label} Mensuel`}
                      payment_detail={plan.details}
                      plan_id={plan.plan_id_monthly}
                      coupon={plan.coupon}
                      reduction={plan.reduction}
                      is_expired={plan.is_expired}
                      has_reduction={has_reduction}
                      only_first_month={plan.only_first_month}
                    />
                  </>
                )}
              </div>
            </div>
          </Item>
        </div>
      ) : null}
    </>
  );

  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <h1>
              {translate({
                fr: `Démarrer avec Intellifox®`,
                en: `Start with Intellifox®`,
              })}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row align-items-end">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="row align-items-center">
                      <div className="col-12 h3">
                        {translate({
                          fr: `Combien de produits as-tu actuellement en vente sur Amazon ?`,
                          en: `How many products do you currently sell on Amazon?`,
                        })}
                      </div>
                      <div className="col-12">
                        <Item>
                          <div className="container">
                            <div className="row">
                              <div className="btn-group btn-group-lg w-100">
                                <ProductNumberOption value="retail">
                                  {translate({
                                    fr: `Plus de 500`,
                                    en: `More than 500`,
                                  })}
                                </ProductNumberOption>
                                <ProductNumberOption value="entreprise">
                                  {translate({
                                    fr: `Entre 100 et 500`,
                                    en: `Between 100 and 500`,
                                  })}
                                </ProductNumberOption>
                                <ProductNumberOption value="startup">
                                  {translate({
                                    fr: `Moins de 100 ou aucun`,
                                    en: `Less than 100 or none`,
                                  })}
                                </ProductNumberOption>
                              </div>
                            </div>
                          </div>
                        </Item>
                      </div>
                    </div>
                  </div>

                  {can_do_yearly ? (
                    <div className="col-12 mb-4">
                      <div className="row">
                        <div className="col-12 h3">
                          {translate({
                            fr: `Choisis ton pourcentage de réduction`,
                            en: `Choose your discount`,
                          })}
                        </div>

                        <div className="col-12">
                          <Item>
                            <div className="row">
                              {can_do_yearly ? (
                                <Option
                                  setter={set_yearly}
                                  current={yearly}
                                  name="yearly"
                                  value={true}
                                  detail={
                                    <div
                                      className={"d-inline-block"}
                                      style={{ opacity: yearly ? 1 : 0.5 }}
                                    >
                                      <ReductionBadge
                                        reduction={
                                          1 -
                                          plan.price_yearly / plan.price_monthly
                                        }
                                      />
                                    </div>
                                  }
                                >
                                  {translate({
                                    fr: `Régler une fois par an`,
                                    en: `Pay once a year`,
                                  })}
                                </Option>
                              ) : null}
                              <Option
                                setter={set_yearly}
                                current={yearly}
                                name="yearly"
                                value={false}
                                detail={
                                  can_do_yearly ? (
                                    <div
                                      className={"d-inline-block"}
                                      style={{ opacity: !yearly ? 1 : 0.5 }}
                                    >
                                      <ReductionBadge
                                        reduction={0}
                                        can_be_zero={true}
                                      />
                                    </div>
                                  ) : null
                                }
                              >
                                {translate({
                                  fr: `Régler une fois par mois`,
                                  en: `Pay once a month`,
                                })}
                              </Option>
                            </div>
                          </Item>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {typeof _yearly !== "undefined" || !can_do_yearly
                    ? end_of_form
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <img src={new_product} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export const ShowOptions = ({ subscription_status }) => {
  const translate = useTranslate();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>
            {translate({
              fr: `Redémarrer avec Intellifox®`,
              en: `Restart with Intellifox®`,
            })}
          </h1>
        </div>
        <div className="col-12 mb-2">
          {subscription_status === "paused" ? (
            <>
              {translate({
                fr: (
                  <>
                    Ton abonnement est actuellement en <b>pause</b>.
                  </>
                ),
                en: (
                  <>
                    Your subscription is currently <b>paused</b>.
                  </>
                ),
              })}
            </>
          ) : (
            <>
              {translate({
                fr: <>Bon retour parmi nous !</>,
                en: <>Welcome back!</>,
              })}
            </>
          )}{" "}
          {translate({
            fr: `Pour redémarrer ton abonnement, clique ici :`,
            en: `To restart your subscription, click on the button below:`,
          })}
        </div>

        <div className="col-12 mb-5">
          <Button
            onClick={() => {
              if (window.$crisp) {
                window.$crisp.push(["do", "chat:open"]);
                window.$crisp.push([
                  "do",
                  "message:send",
                  [
                    "text",
                    translate({
                      fr: `Je veux redémarrer mon abonnement`,
                      en: `I want to restart my subscription`,
                    }),
                  ],
                ]);
              }
            }}
          >
            {translate({
              fr: `Je veux redémarrer mon abonnement`,
              en: `I want to restart my subscription`,
            })}
          </Button>
        </div>
        <div className="col-12 col-md-6 offset-md-3">
          <img src={new_product} alt="" />
        </div>
      </div>
    </div>
  );
};
