import {
  ApolloClient,
  ApolloLink,
  concat,
  InMemoryCache,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

const authMiddleware = new ApolloLink((operation, forward) => {
  let user = localStorage.getItem("user");
  let token = null;

  if (user) {
    user = JSON.parse(user);
    token = user.token;
  }

  operation.setContext({
    headers: {
      authorization: token || null,
    },
  });

  return forward(operation);
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});
const policy = (type, id_field = `${type.toLowerCase()}_id`) => ({
  [type]: { keyFields: [id_field] },
});
const client = new ApolloClient({
  cache: new InMemoryCache({
    ...policy("Update"),
  }),
  link: concat(authMiddleware, httpLink),
});

export default client;
