import { GraphQLClient } from "graphql-request";

export function get_client() {
  const endpoint = process.env.REACT_APP_API_URL;

  let user = localStorage.getItem("user");
  let token = null;
  if (user) {
    user = JSON.parse(user);
    token = user.token;
  }

  return new GraphQLClient(endpoint, {
    timeout: 180000,
    headers: {
      authorization: token || null,
    },
  });
}
