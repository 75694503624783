import useHashParam from 'use-hash-param'
import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import Field from '../../components/generic/field'
import Button from '../../components/generic/button'
import moment from 'moment'
import { TextCopyButton } from '../../components/generic/copy-button'
import { IntellinautTag } from '../success/assists'
import Flag from '../../components/flag/flag'
import XMLViewer from 'react-xml-viewer'
import ExternalLink from '../../components/generic/external-link'
import BigBox from '../../components/layout/big_box'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import ReactJson from 'react-json-view'
import { IntellifoxModalComponent } from '../../components/generic/modal'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { GiSmallFire } from 'react-icons/gi'
import BackTo from '../../components/generic/back_to'


export default function Publish() {
  return (
    <div className={'pt-5 pb-5 container-fluid'}>
      <div className="container mb-5">
        <BackTo to={'/product'}>Back to Product</BackTo>

        <Title color={section_color('product')} style={{ color: 'white' }}><GiSmallFire/> Publications to Amazon</Title>

        <BigBox title={'List of the latest publications'} no_padding={true}>
          <ListPublish/>
        </BigBox>
      </div>
    </div>

  )
}


const PublishState = ({ id, status, reports }) => {

  if (id === 'n/a') {
    return null
  }

  let result = <>🌀</>

  if (status === 'done') {
    try {
      reports = JSON.parse(reports)

      let successful = 0
      let errors = 0
      let warnings = 0
      for (let i = 0; i < reports.length; i++) {
        successful = successful + reports[ i ].successful
        errors = errors + reports[ i ].errors
        warnings = warnings + reports[ i ].warnings
      }

      if (errors > 0) {
        result = <>❌</>
      } else if (warnings > 0) {
        result = <>⚠</>
      } else {
        result = <>✅</>
      }
    } catch (e) {
      console.log(e)
    }
  }


  return <div className="row">
    <div className="col-12">
      {result}
    </div>
  </div>
}

let update_timeout
export const ListPublish = ({default_search = ''}) => {


  const [intellinaut_id, set_intellinaut_id] = useHashParam('search', default_search)
  const [search_param, set_search_param] = React.useState(intellinaut_id)

  const [last_update, set_last_update] = React.useState(Date.now())
  const [lines, set_lines] = React.useState(20)
  const publishes = useCachedContext('ios_publishes', lines, 0, search_param)

  React.useEffect(() => {
    clearTimeout(update_timeout)
    update_timeout = setTimeout(() => {
      set_search_param(intellinaut_id)
    }, 500)
  }, [intellinaut_id])

  React.useEffect(() => {
    const auto_refresh_interval = setInterval(async () => {
      await publishes.refresh()
      set_last_update(Date.now())
    }, 10000)

    return () => {
      clearInterval(auto_refresh_interval)
    }
  }, [])

  // publishes.ios_publishes

  return <div className="row">
    <div className="col-12">
      <Field label={'Search with company_id or publish_id'} value={intellinaut_id}
             setter={set_intellinaut_id}/>
    </div>
    <div className="col-12 text-center">
      {intellinaut_id ? <Button className="mr-2" outline={true} onClick={() => set_intellinaut_id('')}>Reset search</Button> : null}
      <Button className="mr-2" onClick={() => set_lines(lines+100)}>See 100 more</Button> <Button onClick={() => publishes.refresh()}>Refresh</Button>
    </div>
    <div className="col-12">
      {publishes.loading ? 'Loading...' : ''}
      {publishes.error ? 'Error while loading' : ''}

      {publishes.ios_publishes ? <table className="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>User</th>
          <th>State</th>
          <th>Marketplace</th>
          <th>Product</th>
          <th>Images</th>
          <th>Price</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {publishes.ios_publishes.map((publish) => {

          return <tr>
            <td className={'small'}>{moment(publish.start).format()}<br/>
              <TextCopyButton text_to_copy={publish.publish_id}>{publish.publish_id}</TextCopyButton>
            </td>
            <td><IntellinautTag intellinaut_company_id={publish.intellinaut_id}/></td>
            <td>{publish.status}</td>
            <td><Flag market_place={publish.market_place}/></td>
            <td>
              <PublishState
                id={publish.product_submission_id}
                status={publish.product_submission_status}
                reports={publish.product_submission_reports}
              />
            </td>
            <td>
              <PublishState
                id={publish.product_image_submission_id}
                status={publish.product_image_submission_status}
                reports={publish.product_image_submission_reports}
              />
            </td>
            <td>
              <PublishState
                id={publish.price_submission_id}
                status={publish.price_submission_status}
                reports={publish.price_submission_reports}
              />
            </td>
            <td><MoreDetails publish_id={publish.publish_id}/></td>
          </tr>
        })}
        </tbody>
      </table> : null}
    </div>
  </div>
}


const ShowXml = ({ xml }) => {

  const [show_more, set_show_more] = React.useState(false)

  if (!xml) {
    return <div className="row">
      <div className="col-12">
        <i>No XML...</i>
      </div>
    </div>
  }

  return <div className="row">
    <div className="col-12 font-weight-bold">
      XML file sent to Amazon
      <Button className="btn-sm ml-2" onClick={() => set_show_more(!show_more)}>
        {show_more ? 'See less' : 'See more'}
      </Button>
    </div>
    <div className="col-12 text-monospace small mb-2 p-3"
         style={{ overflow: 'auto', height: !show_more ? 200 : undefined }}>
      <XMLViewer xml={xml}/>
    </div>
  </div>
}

const ShowImagesToBuild = ({ images_to_build }) => {

  if (!images_to_build) {
    return null
  }

  return <div className="row">
    <div className="col-12 font-weight-bold">
      Images envoyées à Amazon
    </div>
    <div className="col-12">
      <table className="table table-sm">
        <thead>
        <tr>
          <th>SKU</th>
          <th>Index</th>
          <th>Status</th>
          <th>Url</th>
          <th>Échec ?</th>
        </tr>
        </thead>
        <tbody>
        {images_to_build.map(({ sku, index, status, url, images_failed }) => {
          return <tr>
            <td>{sku}</td>
            <td>{index}</td>
            <td>{status}</td>
            <td><ExternalLink to={url}>{url.substr(0, 50)}...</ExternalLink></td>
            <td>{images_failed}</td>
          </tr>
        })}
        </tbody>
      </table>
    </div>
  </div>
}

const ShowReport = ({ label, publish, type, details }) => {

  const id = publish[ `${type}_submission_id` ]
  const status = publish[ `${type}_submission_status` ]
  const xml = publish[ `${type}_submission_xml` ]

  let reports = publish[ `${type}_submission_reports` ]
  try {
    reports = JSON.parse(reports) || []
  } catch (e) {
    reports = []
  }

  if (!xml) {
    return <div className="row">
      <div className="col-12">
        <h3>{label}</h3>
      </div>
      <div className="col-12">
        No XML for {type}
      </div>
    </div>
  }

  if (id === 'n/a') {
    return <div className="row">
      <div className="col-12">
        <h3>{label}</h3>
      </div>
      <div className="col-12">
        Something strange happened here
      </div>
    </div>
  }

  return <BigBox title={label} actions={<>Status: {status}</>}>
    <div className="row">
      <div className="col-12">
        <ShowXml xml={xml}/>
      </div>
      {details ? <div className="col-12">
        {details}
      </div> : null}
      <div className="col-12 font-weight-bold">
        Report
      </div>
      {reports && reports.length > 0 ?
        <div className="col-12">
          <table className="table table-sm">
            <thead>
            <tr>
              <th>Status</th>
              <th>Processed</th>
              <th>Success</th>
              <th>Failed</th>
              <th>Warning</th>
            </tr>
            </thead>
            <tbody>
            {reports.map(({ status, processed, successful, errors, warnings, results }) => {
              return <>
                <tr>
                  <td>{status}</td>
                  <td>{processed}</td>
                  <td>{successful}</td>
                  <td>{errors}</td>
                  <td>{warnings}</td>
                </tr>
                {results && results.length > 0 ? <tr>
                  <td colSpan={5}>
                    <table className="table table-sm">
                      <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Code</th>
                        <th>description</th>
                      </tr>
                      </thead>
                      <tbody>
                      {results.map(({ message_code, description, info }) => {
                        return <tr>
                          <td>{info.SKU}</td>
                          <td>{message_code}</td>
                          <td>{description}</td>
                        </tr>
                      })}
                      </tbody>
                    </table>
                  </td>
                </tr> : null}
              </>
            })}
            </tbody>
          </table>
        </div> : <div className="col-12">
          Not yet available
        </div>}
    </div>
  </BigBox>
}

const LoadMoreDetails = ({ publish_id }) => {

  const publish = useCachedContext('ios_publish', publish_id)

  if (publish.loading) {
    return <DefaultLoading/>
  }

  if (publish.error) {
    return <DefaultError/>
  }

  if (!publish.ios_publish) {
    return <div className="row">
      <div className="col-12">
        Publication non trouvée ?
      </div>
    </div>
  }

  let products = {}
  try {
    products = JSON.parse(publish.ios_publish.products)
  } catch (e) {

  }

  return <div className="row">
    <div className="col-12">
      <h3>Summary</h3>
    </div>
    <div className="col-12">
      Start: {moment(publish.ios_publish.start).format()}
    </div>
    <div className="col-12">
      State: {publish.ios_publish.status}
    </div>
    <div className="col-12">
      <h3>Products</h3>
    </div>
    <div className="col-12 small">
      <ReactJson src={products} collapsed={true}/>
    </div>

    <div className="col-12">
      <ShowReport label="Product" publish={publish.ios_publish} type={'product'}/>
    </div>
    <div className="col-12">
      <ShowReport label="Images" publish={publish.ios_publish} type={'product_image'}
                  details={<ShowImagesToBuild images_to_build={publish.ios_publish.images_to_build}/>}/>
    </div>
    <div className="col-12">
      <ShowReport label="Price" publish={publish.ios_publish} type={'price'}/>
    </div>
  </div>
}


const MoreDetails = ({ publish_id }) => {

  const [load_more_details, set_load_more_details] = React.useState(false)

  return <>
    <Button onClick={() => set_load_more_details(true)}>See details</Button>
    <IntellifoxModalComponent
      show_modal={load_more_details}
      title={'Publication details'}
      body={
        load_more_details ? <div className="row">
          <div className="col-12">
            <LoadMoreDetails publish_id={publish_id}/>
          </div>
        </div> : null
      }
      close={() => set_load_more_details(false)}
    >

    </IntellifoxModalComponent>
  </>
}
