import React from 'react'
import useCompanyId from '../../hooks/useCompanyId'
import { useCachedContext } from '../../hooks/useCachedContext'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../graphql/queries/_utils'
import { await_mutate } from '../../graphql/mutate_promise'
import random_color from 'randomcolor'
import { DetailTag } from '../../components/generic/detail_tag'
import DeleteButtonV2 from '../../components/generic/delete-button-v2'

export const DeleteAmazonAccount = ({ company }) => {
  const company_id = useCompanyId()
  const { intellinaut_company_id } = company

  const amazon_accounts = useCachedContext('ios_amazon_accounts', company.intellinaut_company_id)

  const [delete_amazon_account_for_real] = useMutation(create_mutation({
    mutation: 'delete_amazon_account_for_real',
    params: {
      company_id: 'ID!',
      intellinaut_company_id: 'ID!',
      merchant_id: 'String',
    },
  }))

  if (amazon_accounts.loading) {
    return null
  }

  if (amazon_accounts.error) {
    console.log('ManageAmazonAccounts got error:', amazon_accounts.error)
    return null
  }

  if (amazon_accounts.length === 0) {

    return <div className="row">
      <div className="col-12">
        There are no Amazon accounts for this user
      </div>
    </div>
  }


  return <div className="row">
    <div className="col-12">
      {amazon_accounts.map(({ merchant_id }) => {
        const do_delete_amazon_account = async () => {
          console.log("Deleting Amazon account...")
          await await_mutate(delete_amazon_account_for_real, {
            variables: {
              company_id,
              intellinaut_company_id,
              merchant_id,
            },
          })

          await amazon_accounts.refresh()
          console.log('Done!')
        }

        return <div className="row">
          <div className="col-12">
            <DetailTag background={random_color({
              seed: merchant_id,
              luminosity: 'dark',
            })} copy={true} value={merchant_id}/>
            <DeleteButtonV2 do_delete={do_delete_amazon_account}>
              Delete this Amazon account
            </DeleteButtonV2>
          </div>
        </div>
      })}
    </div>
  </div>
}
