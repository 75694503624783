import React, { useState } from 'react'
import moment from 'moment'

import './field.css'
import PhoneField from './phone-field'

import TextArea from './text-area'
import Select from './select'
import { Checkbox } from './checkbox'
import CopyButton from './copy-button'
import { FiLoader, MdContentCopy, MdError } from 'react-icons/all'
import { useTranslate } from '../../context/lang'
import Button from './button'
import Item from './item'
import { FaCheckCircle } from 'react-icons/fa'

let global_field_id = 0

const Field = (props) => {
  const {
    type,
    value,
    setter,
    help,
    placeholder,
    label,
    validate = () => true,
    trim = false,
    edit = true,
    col = '12',
    parent_value,
    margin_bottom = 'mb-3',
    need_to_be_filled,
    step = '0.01',
    lock = false,
    style,
    hidden,
    disabled,
    presets,
    copy_button,
    on_enter,

    auto_save,
  } = props

  const [is_locked, set_is_locked] = React.useState(lock)

  const translate = useTranslate()


  const [field_id] = useState(global_field_id)
  global_field_id = global_field_id + 1


  if (type === 'phone') {
    return <PhoneField {...props}/>
  }
  if (type === 'checkbox') {
    return <Checkbox {...props} value={value !== null && typeof value !== 'undefined' ? value : parent_value}>{label}
    </Checkbox>
  }

  if (type === 'textarea') {
    return <TextArea {...props}/>
  }

  if (type === 'select') {
    return <Select {...props}/>
  }

  return (
    <>
      <div className={`${margin_bottom} col-12 col-md-${col} field`}>
        <div className="row">
          <div className="col">

            {label ? <label htmlFor={`field_${field_id}`}>{label}</label> : null}
          </div>
          {auto_save ? <div className="col-auto">
            {auto_save.in_progress ? <span className="badge badge-warning"><FiLoader/> Sauvegarde en cours...</span> : null}
            {auto_save.error ? <span className="badge badge-danger"><MdError/> Erreur lors de la sauvegarde</span> : null}
            {!auto_save.in_progress && !auto_save.error ? <span className="badge badge-success"><FaCheckCircle/> Sauvegardé</span> : null}
          </div> : null}
        </div>

        <Item style={{ padding: 0 }}>
          <div className="row">
            <div className="col-12">

              {!is_locked ?
                <>
                  <input id={`field_${field_id}`} type={type}
                         className={`${lock ? 'w-75' : 'w-100'} ${( validate(value) ? 'valid' : 'invalid' )} ${!value && parent_value ? 'fade-color' : ''} ${need_to_be_filled && !value ? 'need_to_be_filled' : ''}`}
                         disabled={disabled}
                         value={type === 'date' && ( value || parent_value ) ? moment(value || parent_value).format('YYYY-MM-DD') : ( value || parent_value )}
                         style={style}
                         step={type === 'number' ? step : null}
                         placeholder={placeholder}
                         onKeyDown={(e) => {
                           if (e.key === 'Enter' || e.keyCode === 13) {
                             if (lock) {
                               set_is_locked(true)
                             }
                             if (on_enter) {
                               on_enter()
                             }
                           }
                         }}
                         onChange={(e) => {
                           return setter(trim ? e.target.value.trim() : e.target.value)
                         }}/>
                </> :
                <div style={{ padding: 10, paddingLeft: 0 }}
                     className="font-weight-bold">{value}
                </div>}

            </div>
            {presets ? <div className="col-12">
              {presets(setter)}
            </div> : null}

            {lock ?
              <div className="col-12">
                <Button onClick={() => set_is_locked(!is_locked)} className={'btn-sm mt-1'}>Modifier</Button>
              </div> :
              null
            }
            {copy_button ? <div className="col-12 mt-1 mb-1">
              <CopyButton text_to_copy={value && value.trim ? value.trim() : value}
                          className={'btn-sm'}><MdContentCopy/> {translate({
                fr: `Copier`,
                en: `Copy`,
              })}</CopyButton>
            </div> : null}
          </div>
        </Item>
      </div>
      {help ? <div className="col-12 ">
        {help}
      </div> : null}

    </>
  )
}


export default Field
export const displayFields = (fields = [], onSubmit = () => {
}) => {

  return <form onSubmit={(e) => {
    e.preventDefault()
    onSubmit()
  }} className='row'>
    {fields.map((fieldProps, key) => <Field key={key} {...fieldProps} />)}
    <input type='submit' value='go' style={{ display: 'none' }}/>
  </form>
}

export const OnSubmitField = ({ on_submit, children }) => {
  return <form onSubmit={(e) => {
    e.preventDefault()
    on_submit()
  }} className='row'>
    {children}
    <input type='submit' value='go' style={{ display: 'none' }}/>
  </form>
}
