import React, { useState } from "react";

import copy from "copy-to-clipboard";
import { useTranslate } from "../../context/lang";
import "./button.css";

const CopyButton = ({
  className = "",
  children,
  text_to_copy,
  outline = true,
  btn_type = `btn-${outline ? "outline-" : ""}primary`,
  btn_type_on_success = "btn-success",
  hover,
  copied_label,
  style,
}) => {
  const translate = useTranslate();

  if (!copied_label) {
    copied_label = translate({
      fr: "✔ Copié !",
      en: "✔ Copied!",
    });
  }

  const [is_copied, set_is_copied] = useState(false);

  return (
    <button
      className={`btn ${className} ${is_copied ? btn_type_on_success : btn_type} position-relative text-nowrap`}
      style={style}
      onClick={() => {
        set_is_copied(true);
        setTimeout(() => set_is_copied(false), 1000);
        return copy(text_to_copy);
      }}
    >
      {is_copied ? copied_label : children}
      {hover ? (
        <div
          className={`hover position-absolute ${is_copied ? "copied" : ""}`}
          style={{ right: 0, top: 0 }}
        >
          {hover}
        </div>
      ) : null}
    </button>
  );
};

export const TextCopyButton = ({ children, text_to_copy, style }) => {
  return (
    <CopyButton
      btn_type={"btn-link"}
      btn_type_on_success={"btn-link"}
      className={"m-0 p-0 text-left btn-sm"}
      style={style}
      text_to_copy={text_to_copy}
      copied_label={children}
      hover={<div className="hidden">✔ Copié dans le presse-papier</div>}
    >
      {children}
    </CopyButton>
  );
};

export default CopyButton;
