import React from 'react'
import BackTo from '../../components/generic/back_to'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { GiSmallFire } from 'react-icons/gi'
import { MdEmail } from 'react-icons/all'
import BigBox from '../../components/layout/big_box'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import moment from 'moment'
import IntellifoxModal from '../../components/generic/modal'
import Item from '../../components/generic/item'
import Field from '../../components/generic/field'
import Button from '../../components/generic/button'

export default function EmailHistory() {
  return (
    <div className={'pt-5 pb-5 container-fluid'}>
      <div className="container mb-5">
        <BackTo to={'/product'}>Retour à Produit</BackTo>

        <Title color={section_color('product')} style={{ color: 'white' }}><MdEmail/> Emails envoyé</Title>

        <BigBox title={`Historique emails envoyés`}>
          <EmailHistorySearch/>
        </BigBox>
      </div>
    </div>
  )
}


const EmailHistoryList = ({ search, page }) => {
  const email_history = useCachedContext('ios_email_history', search, page)

  if (email_history.loading) {
    return <DefaultLoading/>
  }

  if (email_history.error) {
    return <>
      <Button onClick={() => email_history.refresh()}>Refresh</Button>
      <DefaultError/>
    </>
  }

  if (email_history.length === 0) {
    return <tr>
      <td colSpan={4} className="text-center font-italic">No email found for "{search}"</td>
    </tr>
  }

  return <>
    {email_history.map(({
                          email_template_id,
                          template_key,
                          email_sent_id,
                          date,
                          from,
                          to,
                          title,
                          content,
                          lang,
                        }, i) => {
      return <tr key={i}>
        <td>{moment(date).format()}</td>
        <td>{to}</td>
        <td><span className="small">{template_key}</span><br/>{title}</td>
        <td><SeeEmailContent
          email={to}
          title={title}
          body={content}
          date={date}
        /></td>
      </tr>
    })}

  </>
}

const SentEmailCount = ({ email_id }) => {
  const email_history = useCachedContext('ios_email_history_count', email_id, 0)

  if (email_history.loading) {
    return null
  }

  if (email_history.error) {
    return null
  }
  const email_for_the_last_seven_days = email_history.filter(({ date }) => {
    return moment(date).isAfter(moment().add(-7, 'days'))
  })

  let is_big = false
  let badge = 'danger'
  if (email_for_the_last_seven_days.length > 90) {
    badge = 'green'
    is_big = true
  } else if (email_for_the_last_seven_days.length >= 1) {
    badge = 'warning'
  }

  return <span
    className={`badge badge-${badge} small`}>{is_big ? '>' : ''} {email_for_the_last_seven_days.length}</span>
}


export const EmailHistorySearch = ({default_search = ''}) => {
  const [page, set_page] = React.useState(0)
  const [search, set_search] = React.useState(default_search)

  return <>
    <div className="row">
      <div className="col-12">
        <table className="table">
          <thead>
          <tr>
            <th>Date</th>
            <th>Destinataire</th>
            <th>Objet</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <EmailHistoryList page={page} search={search}/>
          </tbody>
        </table>
      </div>
    </div>
  </>
}

const SeeEmailContent = ({ email, title, body, date }) => {
  return <IntellifoxModal title={`Email content`}
                          body={<div className="row">
                            <div className="col-12">
                              To: {`<`}{email}{`>`}
                            </div>
                            <div className="col-12">
                              Date: {moment(date).format()}
                            </div>
                            <div className="col-12">
                              Objet: {title}
                            </div>
                            <div className="col-12 small font-weight-bold">
                              Content
                            </div>
                            <div className="col-12">
                              <Item>
                                <div dangerouslySetInnerHTML={{ __html: body }}>
                                </div>
                              </Item>
                            </div>
                          </div>}>
    See email content
  </IntellifoxModal>
}
