import React, { useCallback, useContext, useEffect, useState } from 'react'
import { logger } from '../debug/log'
import { get_client } from '../graphql/simple-client'
import { create_query } from '../graphql/queries/_query_and_mutations_templates'
import useCompanyId from './useCompanyId'

const log = logger('use_cached_context')

export const create_cached_context = () => React.createContext({
  // product cache : product_id -> product_with_full_details
  cache: {},
  update_value: (id, value) => {
    // products_cache[ product_id ] = product
  },
  refresh_value: () => {
  },
})

const cached_contexts = {}
export const CachedValues = ({ name, fetch_value, children }) => {
  const value = useCachedContextInit(fetch_value)

  if (!cached_contexts[ name ]) {
    cached_contexts[ name ] = create_cached_context()
  }
  const Context = cached_contexts[ name ]

  return <Context.Provider value={value}>
    {children}
  </Context.Provider>
}

const useFetch = ({ query, params={}, output }) => {
  const company_id = useCompanyId()

  return async (...args) => {
    const client = get_client()

    const keys = Object.keys(params).filter((k) => k !== 'company_id')
    const values = {}
    for (let i = 0; i < keys.length; i++) {
      values[ keys[ i ] ] = args[ i ]
    }
    const response = await client.request(
      create_query({
          query, params, output,
        },
      ),
      {
        company_id,
        ...values,
      })
    return response[ query ]
  }
}

export const CachedValuesV2 = ({ query, children }) => {
  const fetch_value = useFetch(query)

  const value = useCachedContextInit(fetch_value)

  if (!cached_contexts[ query.query ]) {
    cached_contexts[ query.query ] = create_cached_context()
  }
  const Context = cached_contexts[ query.query ]

  return <Context.Provider value={value}>
    {children}
  </Context.Provider>
}

const useCachedContextInit = (fetch_value) => {
  const [cache, set_cache] = useState({})
  const update_value = useCallback((id, value) => {
    set_cache((cache) => ( {
      ...cache,
      [ id ]: value,
    } ))
  }, [])

  const reset_cache = useCallback(() => {
    set_cache({})
  }, [set_cache])

  return [cache, update_value, fetch_value, reset_cache]
}

const refresh_value = (current_value, resource_id, ids, fetch_value, update_value, name) => {

  log(`\t\t${name} cached_context: refresh called`)

  // hot lava !
  if (current_value.loading) {
    update_value(resource_id, current_value)
  }


  const fetch_product = async () => {
    try {
      const value = await fetch_value(...ids)
      log(`\t\t${name} cached_context: get "${resource_id}" ->`, value)
      value.refresh = () => refresh_value(value, resource_id, ids, fetch_value, update_value, name)
      update_value(resource_id, value)
      return value
    } catch (e) {
      log(`\t\t${name} cached_context: get "${resource_id}" -> failure`, e)
      update_value(resource_id, {
        error: e,
        refresh: () => refresh_value(current_value, resource_id, ids, fetch_value, update_value, name),
      })
    }
  }
  return fetch_product().catch(e => log(e))
}


export const get_value = async (context, name, ...ids) => {
  const [cache, update_value, fetch_value] = context
  const resource_id = ids.join('-')

  let value = cache[ resource_id ]
  if (!cache[ resource_id ]) {
    value = await refresh_value({
      loading: true, refresh: () => {
        log(`\t\t${name}  cached_context: refresh called on empty value`)
      },
    }, resource_id, ids, fetch_value, update_value, name).catch(e => log(e))
  }

  return value

}
export const useContextProgrammatically = (name) => {
  return useContext(cached_contexts[ name ])
}

const faster_cache = {}

export const useCachedContext = (name, ...ids) => {
  const resource_id = ids.join('-')

  const [cache, update_value, fetch_value] = useContext(cached_contexts[ name ])

  let local_faster_cache = faster_cache[ name ]
  if (!local_faster_cache) {
    local_faster_cache = faster_cache[ name ] = {}
  }

  useEffect(() => {
    if (!local_faster_cache[ resource_id ]) {
      local_faster_cache[resource_id] = true
      refresh_value({
        loading: true, refresh: () => {
          log(`\t\t${name}  cached_context: refresh called on empty value`)
        },
      }, resource_id, ids, fetch_value, update_value, name).catch(e => log(e))
    }
  }, [resource_id, cache, ids])

  return cache[ resource_id ] || {
    loading: true, refresh: () => {
      log(`\t\tcached_context: refresh called on empty value`)
    },
  }
}


export const useResetCachedValues = (name) => {
  const [cache, update_value, fetch_value, reset_cache] = useContext(cached_contexts[ name ])

  return reset_cache
}

export const is_ready = (cached_context) => cached_context && !cached_context.loading && !cached_context.error
