import React, { useState } from "react";
import Button from "../../components/generic/button";
import Title from "../../components/layout/title";
import TrialBoard from "./trial";
import UserAnalytics from "./user_analytics";

const tabs = [
  { name: "Users", id: "users" },
  { name: "Trials", id: "trials" },
];
function cn(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Analytics() {
  const [activeTab, setActiveTab] = useState("users");

  return (
    <div>
      <Title color={"orange"} style={{ color: "white" }}>
        Analytics
      </Title>

      <ul className="nav nav-tabs">
        {tabs.map((tab) => (
          <li key={tab.name} className={tab.current ? "active" : ""}>
            <Button
              onClick={() => setActiveTab(tab.id)}
              className={cn("tab", tab.id === activeTab ? "active" : "")}
            >
              {tab.name}
            </Button>
          </li>
        ))}
      </ul>

      <div className="tab-content">
        {activeTab === "users" && <UserAnalytics />}
        {activeTab === "trials" && <TrialBoard />}
      </div>
    </div>
  );
}
