import useHashParam from 'use-hash-param'
import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import Field from '../../components/generic/field'
import Button from '../../components/generic/button'
import moment from 'moment'
import CopyButton, { TextCopyButton } from '../../components/generic/copy-button'
import { IntellinautTag } from '../success/assists'
import Flag from '../../components/flag/flag'
import BigBox from '../../components/layout/big_box'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import IntellifoxModal, { IntellifoxModalComponent } from '../../components/generic/modal'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { GiSmallFire } from 'react-icons/gi'
import BackTo from '../../components/generic/back_to'
import Item from '../../components/generic/item'
import * as _ from 'lodash'
import ExternalLinkButton from '../../components/generic/external-link-button'
import { FaArrowRight, MdFileDownload } from 'react-icons/all'
import ReactDiffViewer from 'react-diff-viewer'
import ReactJson from 'react-json-view'
import UpdateButton from '../../components/generic/update-button'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import DeleteButtonV2 from '../../components/generic/delete-button-v2'
import { useMutation } from '@apollo/client'
import { delete_ios_process, restart_ios_process } from '../../graphql/queries/ios_processes'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import { Checkbox } from '../../components/generic/checkbox'
import { force_download_json } from '../success/download_bill'

export const remove_html = (text) => {
  const returned = text && text.replace ? text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') : text || ''

  if (returned.replace) {
    return returned
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
  }

  return returned
}


export const clean_for_label = (text, limit, variables = [], suffix = '...') => {
  text = remove_html(text)

  if (text.length > limit) {
    return text.substring(0, limit) + suffix
  }

  return text

}


export default function Imports() {
  return (
    <div className={'pt-5 pb-5 container-fluid'}>
      <div className="container mb-5">
        <BackTo to={'/product'}>Back to Product</BackTo>

        <Title color={section_color('product')} style={{ color: 'white' }}><GiSmallFire/> Imports from Amazon</Title>

        <BigBox title={'Last imports'} no_padding={true}>
          <ListImport/>
        </BigBox>
      </div>
    </div>

  )
}


const display_duration = (duration) => {
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  const ms = duration.milliseconds()

  let output = ''
  if (hours) {
    output = `${hours}h `
  }
  if (output || minutes) {
    output = `${output}${minutes}min `
  }
  if (output || seconds) {
    output = `${output}${seconds}s `
  }

  if (!output) {
    output = `<1s`
  }
  return output
}

let update_timeout

export const ListImport = ({default_search = ''}) => {
  const company_id = useCompanyId()

  const [intellinaut_id, set_intellinaut_id] = useHashParam('search', default_search)
  const [only_unfinished, set_only_unfinished] = useHashParam('only_unfinished', false)
  const [search_param, set_search_param] = React.useState(intellinaut_id)

  const [lines, set_lines] = React.useState(20)
  const processes = useCachedContext('ios_processes', lines, 0, search_param, only_unfinished === 'true')

  const [delete_process_mutation] = useMutation(delete_ios_process)

  React.useEffect(() => {
    clearTimeout(update_timeout)
    update_timeout = setTimeout(() => {
      set_search_param(intellinaut_id)
    }, 500)
  }, [intellinaut_id])

  React.useEffect(() => {
    const auto_refresh_interval = setInterval(async () => {
      await processes.refresh()
    }, 10000)

    return () => {
      clearInterval(auto_refresh_interval)
    }
  }, [])

  // imports.ios_imports

  return <div className="row">
    <div className="col-12">
      <Field label={`Search with company_id or publish_id`} value={intellinaut_id}
             setter={set_intellinaut_id}/>
    </div>
    <div className="col-12 text-center">
      {intellinaut_id ? <Button className="mr-2" outline={true} onClick={() => set_intellinaut_id('')}>Reset search</Button> : null}
      <Button className="mr-2" onClick={() => set_lines(lines + 100)}>See 100 more</Button>
      <UpdateButton onClick={() => processes.refresh()}>Refresh processes</UpdateButton>
    </div>
    <div className="col-12">
      <Checkbox setter={set_only_unfinished} value={only_unfinished === 'true'}>Show only unfinished imports</Checkbox>
    </div>
    <div className="col-12">
      {processes.loading ? 'Loading...' : ''}
      {processes.error ? 'Error while loading' : ''}

      {processes.ios_processes ? <table className="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>User</th>
          <th>Step</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {processes.ios_processes.map((process) => {

          const steps = process.steps.sort((s1, s2) => s1.index < s2.index ? -1 : 1)

          return <tr>
            <td className={'small'}>
              {process.type}<br/>
              {moment(process.creation_date).format()}<br/>
              <TextCopyButton text_to_copy={process.process_id}>process_id = {process.process_id}</TextCopyButton>
            </td>
            <td><IntellinautTag intellinaut_company_id={process.intellinaut_id}/></td>
            <td>
              <Item no_padding={true}>
                <table className="table table-sm small">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {steps.map(({
                                name,
                                status,
                                index,
                                start_date,
                                end_date,
                                data,
                              }) => {
                    const duration = moment.duration(( end_date || Date.now() ) - ( start_date ))
                    return <tr>
                      <td>{index}</td>
                      <td>{name}</td>
                      <td>{status}</td>
                      <td>{start_date ? display_duration(duration) : '-'}</td>
                    </tr>
                  })}
                  </tbody>
                </table>
              </Item>
            </td>
            <td>
              <div><MoreDetails steps={steps} intellinaut_id={process.intellinaut_id} process_id={process.process_id}/></div>
              {/*<div><RestartProcess process={process} steps={steps} processes={processes}/></div>*/}
              <div><DeleteButtonV2 do_delete={async () => {
                await await_mutate(delete_process_mutation, {
                  variables: {
                    company_id,
                    intellinaut_id: process.intellinaut_id,
                    process_id: process.process_id,
                  },
                })
                await processes.refresh()
              }}>Delete</DeleteButtonV2></div>
            </td>
          </tr>
        })}
        </tbody>
      </table> : null}
    </div>
  </div>
}


// const RestartProcess = ({ processes, steps, process }) => {
//   const company_id = useCompanyId()
//
//   const [restart_process_mutation] = useMutation(restart_ios_process)
//
//   const [selected_index, set_selected_index] = React.useState(-1)
//   const [launch_job, set_launch_job] = React.useState(false)
//
//   const do_it = async () => {
//     await await_mutate(restart_process_mutation, {
//       variables: {
//         company_id,
//         intellinaut_id: process.intellinaut_id,
//         process_id: process.process_id,
//         index: selected_index,
//         relaunch_job: launch_job,
//       },
//     })
//     await processes.refresh()
//   }
//
//   return <IntellifoxModal title={'à quelle étape ?'}
//                           body={<div className="row">
//                             <div className="col-12 font-weight-bold">
//                               Choisir l'étape
//                             </div>
//                             <div className="col-12 mb-4">
//                               {steps.map(({ index, name }) => {
//                                 return <Button onClick={() => set_selected_index(index)}
//                                                button={index === selected_index ? 'primary' : 'secondary'}>{index} - {name}</Button>
//                               })}
//                             </div>
//                             <div className="col-12 font-weight-bold">
//                               Autre option
//                             </div>
//                             <Checkbox value={launch_job} setter={set_launch_job}>Lancer un nouveau job (à utiliser si le
//                               process est déjà terminé)</Checkbox>
//                           </div>}
//                           on_click={do_it}
//                           action_label={'C\'est parti'}>
//     Redémarrer
//   </IntellifoxModal>
// }

const MoreDetails = ({ intellinaut_id, process_id, steps }) => {

  const [current_step, set_current_step] = React.useState(0)

  const [show_details, set_show_details] = React.useState(false)

  const before = steps[ current_step ]
  const after = steps[ current_step + 1 ]

  return <>
    <IntellifoxModal
      title={`Process details`}
      body={
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-5">
                <Button onClick={() => set_current_step(Math.max(current_step - 1, 0))}>Previous</Button>
                <h2>{before.name}</h2>
              </div>
              <div className="col-2 text-center">
                <h1><FaArrowRight/></h1>
              </div>
              <div className="col-5 text-right">
                <Button onClick={() => set_current_step(Math.min(current_step + 1, steps.length - 2))}>Next</Button>
                <h2>{after.name}</h2>
              </div>
            </div>
          </div>

          <div className="col-12">
            <Button onClick={() => set_show_details(!show_details)}>See details</Button>
          </div>
          {show_details ? <div className="col-12">
            <ShowProcessData intellinaut_id={intellinaut_id} process_id={process_id} />
          </div> : null}
        </div>
      }
    >
      See details
    </IntellifoxModal>
  </>
}

const ShowProcessData = ({ intellinaut_id, process_id }) => {

  const ios_process_data = useCachedContext('ios_process_data', intellinaut_id, process_id)

  const [show_json, set_show_json] = React.useState(false)

  if (ios_process_data.loading) {
    return <DefaultLoading/>
  }

  if (ios_process_data.error) {
    return <DefaultError/>
  }


  return <div className="row">
    <div className="col-5">
      {/*<CopyButton text_to_copy={before_as_text}>Copier le .json</CopyButton>*/}
      <Button
        onClick={() => force_download_json(ios_process_data.ios_process_data.data, `process_data_${moment().format()}_${process_id}.json`)} className={"mr-2"}>
        <MdFileDownload/> Download .json
      </Button>
      <Button outline={true} onClick={() => set_show_json(!show_json)}>Explore .json here</Button>
    </div>
    {show_json ? <div className="col-12">
      <ReactJson src={ios_process_data.ios_process_data.data}/>
    </div> : null}
  </div>
}

