import React from "react";

import { useTranslate } from "../../context/lang";
import IntellifoxIcon from "../../images/intellifox-icon";
import PageTitle from "../generic/page-title";
import { CreateUserFields } from "./user-fields";

export default function CreateAccount({ set_user, set_show_create_account }) {
  const translate = useTranslate();

  // const [has_message_been_pushed, set_has_message_been_pushed] = useLocalStorage('is_going_to_create_account', false)
  React.useEffect(() => {
    if (window.$crisp) {
      setTimeout(() => {
        window.$crisp.push([
          "set",
          "session:event",
          ["is_going_to_create_account"],
        ]);
        //       console.log('event done')
        //       if (!has_message_been_pushed) {
        //         set_has_message_been_pushed(true)
        //         window.$crisp.push(['do', 'message:show', ['text', `Hey ! J'ai fait une petite vidéo pour vous faciliter la vie avec l'inscription :
        //
        // \${youtube}[Choisir la bonne formule](AAgmxbKtT5Y)
        //
        // N'oubliez surtout pas de remplir votre numéro de TVA (si vous en avez un) comme indiqué dans la vidéo !
        //
        // On se retrouve de l'autre côté ;)`]])
        //       }
      }, 5000);
    }
  }, []);
  return (
    <>
      <div className="row">
        <PageTitle>
          <IntellifoxIcon />{" "}
          {translate({
            fr: "Bienvenue",
            en: "Welcome",
          })}
        </PageTitle>

        <CreateUserFields
          set_user={set_user}
          set_show_create_account={set_show_create_account}
        />
      </div>
    </>
  );
}
