import React, { useContext } from 'react'
import { get_translation } from '../components/i18n/translations'


const LangContext = React.createContext({
  lang: 'ен',
  set_lang: () => {
  },
})

export const useLangContext = () => {
  return useContext(LangContext)
}
export const useLang = () => {
  return 'en'
}

export const useTranslate = () => {
  const lang = useLang()

  return React.useCallback((translations) => {
    return get_translation(translations, lang)
  }, [lang])

}

export const get_browser_lang = () => {
  const browser_lang = navigator.language.split('-')[ 0 ]

  if (browser_lang === 'fr' || browser_lang === 'en') {
    return browser_lang
  }

  // default lang
  return 'fr'
}

export default LangContext
