import React from 'react'
import BackTo from '../../components/generic/back_to'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { GiSmallFire } from 'react-icons/gi'
import BigBox from '../../components/layout/big_box'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import moment from 'moment'
import IntellifoxModal from '../../components/generic/modal'
import Item from '../../components/generic/item'
import Field from '../../components/generic/field'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { await_mutate } from '../../graphql/mutate_promise'
import { useMutation } from '@apollo/client'
import { ios_update_interface_translation } from '../../graphql/queries/ios_translations'
import useCompanyId from '../../hooks/useCompanyId'
import { FaCheck, MdEdit } from 'react-icons/all'
import Button from '../../components/generic/button'

function UpdateSingleTranslation({ source_lang, source_text, translation, on_update }) {
  const company_id = useCompanyId()

  const { lang, text, created_at, updated_at, original } = translation
  const [new_text, set_new_text] = React.useState(text)

  const is_automated_translation = text === original

  React.useEffect(() => {
    set_new_text(translation.text)
  }, [translation])

  const [update_translation] = useMutation(ios_update_interface_translation)

  const do_update_translation = async (target_text = new_text) => {
    await await_mutate(update_translation, {
      variables: {
        company_id,
        source_lang,
        source_text,
        target_lang: translation.lang,
        target_text: target_text.trim() ,
      },
    })
    on_update()
  }

  return <div className="row">
    <div className="col-12 mb-2">
      <Item>
        <div className="row">
          <div className="col-12 font-weight-bold">
            <h4>{lang}</h4>
          </div>
          <Field type={'text'} value={new_text} setter={set_new_text}/>
          {original !== new_text ? <div className="col-12 small text-right mb-3">
            <div className="row">
              <div className="col-12">
                Traduction automatique : "{original}"
              </div>
              <div className="col-12">
                <Button className="btn-sm" onClick={() => {
                  set_new_text(original)
                  do_update_translation(original)
                }}>Rétablir la traduction automatique d'origine</Button>
              </div>
            </div>
          </div> : <div className="col-12 text-right">
            <FaCheck/> Traduction automatique
          </div>}
          <div className="col-6">
            <UpdateButtonV2 trigger={() => do_update_translation()}>Modifier</UpdateButtonV2>
          </div>
          <div className="col-6 small text-right">
            <div className="row">
              <div className="col-12">
                Créée le {moment(created_at).format()}
              </div>
              <div className="col-12">
                Dernière mise à jour {moment(updated_at).format()}
              </div>
            </div>
          </div>
        </div>
      </Item>
    </div>
  </div>
}


function UpdateTranslation({ source_text, source_lang, translations, on_update }) {
  if (!translations) {
    return null
  }

  return <IntellifoxModal title={'Modifier les traductions'}
                          body={<div className="row">
                            <div className="col-12 mb-3">
                              <Item>
                                <div className="row">
                                  <div className="col-12">
                                    <h4>"{source_text}"</h4>
                                  </div>
                                </div>

                              </Item>
                            </div>
                            <div className="col-12">
                              {translations.map((translation) => {
                                return <UpdateSingleTranslation translation={translation} source_text={source_text}
                                                                source_lang={source_lang} on_update={on_update}/>
                              })}
                            </div>
                          </div>}
  >
    Modifier les traductions
  </IntellifoxModal>
}

export default function Translations() {

  const interface_texts = useCachedContext('ios_interface_texts')

  const [search, set_search] = React.useState('')

  if (interface_texts.loading) {
    return <DefaultLoading/>
  }

  if (interface_texts.error) {
    return <DefaultError/>
  }

  const matcher = new RegExp(search, 'ig')

  const texts = !search ? interface_texts : interface_texts.filter(({ text, translations }) => {
    for (let i = 0; i < translations.length; i++) {
      if (matcher.test(translations[ i ].text)) {
        return true
      }
    }
    return matcher.test(text)
  })

  return (
    <div className={'pt-5 pb-5 container-fluid'}>
      <div className="container mb-5">
        <BackTo to={'/product'}>Retour à Produit</BackTo>

        <Title color={section_color('product')} style={{ color: 'white' }}><GiSmallFire/> Traductions de
          l'interface</Title>

        <BigBox title={`Liste des textes`}>
          <div className="row">
            <div className="col-12 font-weight-bold">
              Rechercher un texte : entrer un morceau du texte, dans n'importe quelle langue
            </div>
            <Field type="text" value={search} setter={set_search}/>
            <div className="col-12">
              <hr/>
            </div>
          </div>


          <table className="table">
            <thead>
            <tr>
              <th>Date</th>
              <th>Langue</th>
              <th>Texte</th>
              <th>Traductions</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>

            {texts.map(({ created_at, lang, text, translations }) => {
              return <tr key={text}>
                <td className="small">{moment(created_at).format()}</td>
                <td>{lang}</td>
                <td>{text}</td>
                <td>{translations.map(({ lang }) => <>{lang}</>)}</td>
                <td><UpdateTranslation source_lang={lang} source_text={text} translations={translations}
                                       on_update={() => interface_texts.refresh()}/></td>
              </tr>
            })}
            </tbody>
          </table>
        </BigBox>
      </div>
    </div>
  )
}
