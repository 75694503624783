import React from 'react'
import BackTo from '../../components/generic/back_to'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { GiSmallFire } from 'react-icons/gi'
import BigBox from '../../components/layout/big_box'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import moment from 'moment'
import IntellifoxModal from '../../components/generic/modal'
import Item from '../../components/generic/item'
import Field from '../../components/generic/field'
import { await_mutate } from '../../graphql/mutate_promise'
import { useMutation } from '@apollo/client'
import useCompanyId from '../../hooks/useCompanyId'
import { MdAdd, MdContentCopy, MdEdit, MdFileDownload, MdFileUpload } from 'react-icons/all'
import Button from '../../components/generic/button'
import { ios_edit_email } from '../../graphql/queries/ios_emails'
import { create_query } from '../../graphql/queries/_query_and_mutations_templates'
import { get_client } from '../../graphql/simple-client'
import CopyButton from '../../components/generic/copy-button'
import { force_download_json } from '../success/download_bill'
import * as _ from 'lodash'


const extract_variables = (text_with_variables = '') => {
  const matches = text_with_variables.match(/\$([^\$\s]+)\$/g)
  return matches ? _.uniq(matches.map((variable) => variable.replace(/\$/g, ''))) : []
}

const get_universal_translation = async ({
                                           company_id, source_lang, source_text, target_lang, keep, type = 'html',
                                         }) => {

  if (!source_text) {
    return ''
  }

  const client = get_client()

  console.log('get_universal_translation', { keep })

  const response = await client.request(create_query({
    query: 'translation',
    params: {
      company_id: 'ID!',
      source_lang: 'String',
      target_lang: 'String',
      text: 'String',
      keep: '[String]',
      type: 'String',
    },
    output: `{
    translated_text
  }`,
  }), {
    company_id,
    source_lang,
    target_lang,
    text: source_text,
    keep,
    type,
  })

  return response.translation ? response.translation.translated_text : ''
}


const EditTranslation = ({ lang, title, body, is_automated, setter, is_source_lang, is_manually_edited_html }) => {

  const [raw_html, set_raw_html] = React.useState(is_manually_edited_html)
  return <div className="row mb-3">
    <div className="col-12">
      <Item>
        <div className="row">
          <div className="col-12 font-weight-bold">
            <h2>{lang}</h2>
          </div>

          {!is_automated ? <>
            <Field type="text" label={'Titre'} value={title}
                   setter={(title) => setter({ lang, title, body, is_automated, is_manually_edited_html: raw_html })}/>
            <Field type="textarea" label={'Body'} value={body}
                   setter={(body) => setter({ lang, title, body, is_automated, is_manually_edited_html: raw_html })}
                   show_html={true} raw_html={raw_html}/>
            <div className="col-12">
              Variables : <CopyButton text_to_copy={'$first_name$'}>Prénom $first_name$</CopyButton> | <CopyButton
              text_to_copy={'$last_name$'}>Nom de famille $last_name$</CopyButton> | <CopyButton
              text_to_copy={'$email$'}>Email $email$</CopyButton> | <CopyButton text_to_copy={'🦊'}>Emoji Renard
              🦊</CopyButton>
            </div>
            <div className="col-12 text-right">
              <Button className="btn-sm" outline={true} onClick={() => {
                set_raw_html(!raw_html)
                setter({ lang, title, body, is_automated, is_manually_edited_html: !raw_html })
              }}>Modifier l'HTML</Button>
            </div>
            {!is_source_lang ? <Button onClick={() => setter({
              lang,
              title,
              body,
              is_automated: !is_automated,
              is_manually_edited_html: raw_html,
            })}>
              Traduction automatique
            </Button> : null}
          </> : <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h3>Traduction automatique</h3>
              </div>
              <div className="col-12">
                <b>Titre :</b> {title}
              </div>
              <div className="col-12">
                <b>Body</b>
              </div>
              <div className="col-12">
                <code dangerouslySetInnerHTML={{ __html: body }}></code>
              </div>
            </div>
            <div className="col-12">
              <Button onClick={() => setter({ lang, title, body, is_automated: !is_automated })}><MdEdit/> Modifier
                manuellement</Button>
            </div>
          </div>
          }
        </div>
        <div className="row">
          <div className="col-12">
            Variables détectées : titre : {extract_variables(title).join(', ')} && body
            : {extract_variables(body).join(', ')}
          </div>
        </div>
      </Item>
    </div>
  </div>
}

const EditEmail = ({
                     email_id,
                     is_email_id_editable,
                     translations,
                     label = '',
                     source_lang = 'fr',
                     children,
                     on_complete,
                   }) => {
  const company_id = useCompanyId()

  const [new_email_id, set_new_email_id] = React.useState(email_id)
  const [new_translations, set_new_translations] = React.useState(translations || [
    {
      lang: source_lang,
      title: '',
      body: '',
      is_automated: false,
      is_manually_edited_html: false,
    },
  ])
  const [new_label, set_new_label] = React.useState(label)

  React.useEffect(() => {
    if (translations) {
      set_new_translations(translations)
    }
    if (label) {
      set_new_label(label)
    }
  }, [translations, label])

  const [ios_edit_email_mutation] = useMutation(ios_edit_email)


  const source = new_translations.find((t) => t.lang === source_lang)


  const do_edit_email = async () => {
    await Promise.all(new_translations.map(async ({ lang, title, body, is_automated, is_manually_edited_html }) => {
      const is_source = lang === source_lang
      if (!is_source && is_automated) {
        const [title_automated_translation, body_automated_translation] = await Promise.all(
          [
            get_universal_translation({
              company_id,
              source_lang,
              source_text: source.title,
              target_lang: lang,
              keep: extract_variables(source.title),
            }),
            get_universal_translation({
              company_id,
              source_lang,
              source_text: source.body,
              target_lang: lang,
              keep: extract_variables(source.body),
            }),
          ],
        )
        title = title_automated_translation
        body = body_automated_translation
      }

      await await_mutate(ios_edit_email_mutation, {
        variables: {
          company_id,
          email_id: new_email_id,
          label: new_label,
          source_lang,
          lang, title, body, is_automated, is_manually_edited_html,
        },
      })
    }))

    on_complete && on_complete()
  }


  const set_new_translation = (new_t) => {
    set_new_translations(new_translations.map((t) => {
      if (t.lang === new_t.lang) {
        return new_t
      }
      return t
    }))
  }




  return <IntellifoxModal title={children} action_label={children}
                          on_click={do_edit_email}
                          body={<div className="row">

                            {is_email_id_editable ?
                              <Field type="text" label="email_id" value={new_email_id} setter={set_new_email_id}/> :
                              <div className="col-12 font-weight-bold">
                                <code>{email_id}</code>
                              </div>}
                            <Field type="text" label="Label" value={new_label} setter={set_new_label}/>

                            <div className="col-12">
                              <EditTranslation lang={source_lang} title={source.title} body={source.body}
                                               is_automated={false} is_source_lang={true}
                                               is_manually_edited_html={source.is_manually_edited_html}
                                               setter={set_new_translation}/>

                            </div>

                            <div className="col-12">
                              {new_translations.filter(({ lang }) => lang !== source_lang).map(({
                                                                                                  lang,
                                                                                                  title,
                                                                                                  body,
                                                                                                  is_automated,
                                                                                                  is_manually_edited_html,
                                                                                                }) => {
                                return <EditTranslation lang={lang} title={title} body={body}
                                                        is_automated={is_automated} setter={set_new_translation}
                                                        is_manually_edited_html={is_manually_edited_html}/>
                              })}
                            </div>

                          </div>}
  >
    {children}
  </IntellifoxModal>
}

export default function Emails() {

  const ios_emails = useCachedContext('ios_emails')

  const [search, set_search] = React.useState('')

  if (ios_emails.loading) {
    return <DefaultLoading/>
  }

  if (ios_emails.error) {
    return <DefaultError/>
  }

  const matcher = new RegExp(search, 'ig')

  const emails = !search ? ios_emails : ios_emails.filter(({ translations }) => {
    for (let i = 0; i < translations.length; i++) {
      if (matcher.test(translations[ i ].title) || matcher.test(translations[ i ].body)) {
        return true
      }
    }
    return false
  })

  return (
    <div className={'pt-5 pb-5 container-fluid'}>
      <div className="container mb-5">
        <BackTo to={'/product'}>Retour à Produit</BackTo>

        <Title color={section_color('product')} style={{ color: 'white' }}><GiSmallFire/> Emails</Title>

        <BigBox title={`Liste des emails`}>
          <div className="row">
            <div className="col-12 font-weight-bold">
              Rechercher un texte : entrer un morceau de l'email, dans n'importe quelle langue
            </div>
            <Field type="text" value={search} setter={set_search}/>
            <div className="col-12">
              <hr/>
            </div>
          </div>


          <table className="table">
            <thead>
            <tr>
              <th>identifiant</th>
              <th>Label</th>
              <th>Traductions</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>

            {emails.map(({ email_id, source_lang, label, translations }) => {
              return <tr key={email_id}>
                <td>{email_id}</td>
                <td>{label}</td>
                <td>{translations.map(({ lang }) => <span
                  className={'badge badge-secondary mr-1 mb-1'}>{lang}</span>)}</td>
                <td>
                  <div className="row">
                    <div className="col">
                      <EditEmail email_id={email_id} is_email_id_editable={false} translations={translations}
                                 source_lang={source_lang} label={label}
                                 on_complete={() => ios_emails.refresh()}><MdEdit/> Modifier</EditEmail>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <CopyButton text_to_copy={`await send_email_to_company({ company_id, email_id: '${email_id}' })`}><MdContentCopy/> Utiliser
                        dans le code</CopyButton>
                    </div>
                  </div>
                </td>
              </tr>
            })}
            </tbody>
          </table>

          <div className="row">
            <div className="col-12">
              <EditEmail is_email_id_editable={true} on_complete={() => ios_emails.refresh()}><MdAdd/> Ajouter un nouvel
                email</EditEmail>
            </div>
            <div className="col-12">
              <Button
                onClick={() => force_download_json(ios_emails, `ios_emails_${moment().format()}.json`)}>
                <MdFileDownload/> Télécharger une sauvegarde
              </Button>
            </div>
            <div className="col-12">
              <ImportEmails/>

            </div>
          </div>
        </BigBox>
      </div>
    </div>
  )
}

const ImportEmails = () => {

  const [json, set_json] = React.useState('[]')
  const company_id = useCompanyId()
  const [mutate] = useMutation(ios_edit_email)

  let emails = []
  try {
    emails = JSON.parse(json)
  } catch (e) {

  }


  const do_it = async () => {

    for (let i = 0; i < emails.length; i++) {
      const email = emails[ i ]
      const { translations } = email

      for (let j = 0; j < translations.length; j++) {
        const translation = translations[ j ]

        await await_mutate(mutate, {
          variables: {
            company_id,
            ...email,
            ...translation
          }
        })
      }
    }
  }

  return <IntellifoxModal title={'Importer la sauvegarde'}
                          body={<div className="row">
                            <div className="col-12">
                              <Field type="textarea" value={json} setter={set_json}
                                     label={'Rentrer le contenu du fichier .json ici :'} raw_html={true}/>
                            </div>
                            <div className="col-12">
                              {emails.length} emails seront importés
                            </div>
                          </div>}
                          action_label={'Importer les emails'}
                          on_click={do_it}
  >
    <MdFileUpload/> Importer une sauvegarde
  </IntellifoxModal>
}
