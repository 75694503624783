import React from 'react'
import Graph, { Node } from 'react-json-graph'
import { useWindowSize } from '../../hooks/useWindowSize'
import { section_color } from '../colors'
import { useCachedContext } from '../../hooks/useCachedContext'


const node_height = 80
const node_width = 165
const level_height = 100

class GitNode extends Node {

  constructor(props) {
    super(props)

    this.extra_props = { ...props } || {}
  }


  render() {
    const { background } = this.extra_props

    const {
      x,
      y,
      label,
      isDragging,
    } = this.state

    return (
      <div
        className="shadow"
        style={{
          left: x,
          top: y,
          display: 'inline-block',
          overflow: 'hidden',
          position: 'absolute',
          height: node_height,
          width: node_width,
          background: 'rgba(255,255,255,1)',
          borderRadius: '4px',
          paddingLeft: '10px',
        }}
        ref={(element) => {
          this.element = element
        }}
        onMouseDown={(event) => this._onMouseDown(event)}
      >
        <div className="position-absolute" style={{
          background: background ? background : 'white', opacity: 0.3, width: '100%', height: '100%', left: 0, top: 0,
        }}></div>

        <div className="position-absolute" style={{
          background: background ? background : 'white', opacity: 1, width: 10, height: '100%', left: 0, top: 0,
        }}></div>
        <div className="row align-items-center h-100">
          <div className="col-12">
            {label}
          </div>
        </div>
      </div>
    )
  }
}


const OrgNode = ({ label, role }) => {

  const employees = useCachedContext('ios_employees')
  if (employees.loading) {
    return null
  }

  if (employees.error) {
    return null
  }

  const employee_with_role = employees.filter((e) => e.roles.indexOf(role) !== -1)

  let player = {
    name: 'À pourvoir',
  }


  return <div className="row text-center">
    <div className="col-12 font-weight-bold mb-1">
      {label}
    </div>
    <div className="col-12">
      <span className="p-1 shadow rounded"
            style={{ background: player.background, color: player.color }}>{player.name}</span>
    </div>
  </div>
}

const compute_graph = (tree, width, height) => {

  const nodes = []
  const edges = []

  let id = 0
  const handle_tree = (tree, parent, delta_x = 0, level = 0) => {
    for (let i = 0; i < tree.length; i++) {
      id++
      const { label, children, background, component } = tree[ i ]

      let position = {}
      if (level <= 2) {
        delta_x = ( i + 1 ) * width / ( tree.length + 1 )
        position = {
          x: delta_x - node_width / 2,
          y: ( level * level_height ),
        }
      } else {
        position = {
          x: delta_x + 10 - node_width / 2,
          y: ( ( level + i ) * level_height ),
        }
      }

      nodes.push({
        label: component,
        background,
        id,
        position,
      })

      if (parent) {
        edges.push({ source: parent, target: id })
      }

      if (children) {
        handle_tree(children, id, delta_x, level + 1)
      }
    }
  }

  handle_tree(tree)


  return {
    nodes,
    edges,
    isVertical: true,
    isDirected: true,
  }
}

export const useFlatRoles = () => {
  const hierarchy = useHierarchy()

  const roles = []

  const push_roles = (level) => {
    for (let i = 0; i < level.length; i++) {
      const child = level[ i ]
      roles.push(child)
      if (child.children) {
        push_roles(child.children)
      }
    }
  }

  push_roles(hierarchy)

  return roles
}


export const useRoleDetails = (role) => {
  const roles = useFlatRoles()

  return roles.find((r) => r.role === role) || {}
}

export const useHierarchy = () => {
  return [
    {
      label: 'Président', component: <OrgNode role={'owner'} label={'Président'}/>,
      background: section_color('intellifox'),
      role: 'ceo',
      children: [
        {
          label: 'Agent Support',
          component: <OrgNode role={'success_agent'} label={'Agent Support'}/>,
          background: section_color('success'),
          role: 'support',
        },
      ],
    },
  ]

}

export default function Organigram() {
  const hierarchy = useHierarchy()

  const [width, set_width] = React.useState(-1)
  const [height, set_height] = React.useState(600)
  const [graph, set_graph] = React.useState(compute_graph(hierarchy, width, height))

  const container = React.useRef(null)
  const window_size = useWindowSize()

  React.useEffect(() => {
    if (container.current) {
      const new_width = container.current.getBoundingClientRect().width
      set_width(new_width)
      set_graph(compute_graph(hierarchy, new_width, height))
      setTimeout(() => {
        set_graph(compute_graph(hierarchy, new_width, height))
      }, 100)
    }
  }, [window_size])

  return (
    <div className="row">
      <div className="col-12" ref={container}>
        {width !== -1 ? <Graph parentWidth={width} width={width} height={height} json={graph}
                               shouldNodeFitContent={true}
                               onChange={(newGraphJSON) => {
                                 set_graph(newGraphJSON)
                               }}
                               Node={GitNode}
        /> : null}
      </div>
    </div>
  )
}
