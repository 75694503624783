// eslint-disable-next-line import/no-webpack-loader-syntax
import publish from "!file-loader!../../images/story/publish.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import search from "!file-loader!../../images/story/search.svg";
import React from "react";
import { GiSherlockHolmes, MdEmail } from "react-icons/all";
import { FaPaperPlane } from "react-icons/fa";
import { GiSmallFire } from "react-icons/gi";
import { navigate } from "../../absolute_link";
import Item from "../../components/generic/item";
import BigBox from "../../components/layout/big_box";
import Title from "../../components/layout/title";
import { section_color } from "../colors";

export default function Product() {
  return (
    <div className={"pt-5 pb-5 container-fluid"}>
      <div className="container mb-5">
        <Title color={section_color("product")} style={{ color: "white" }}>
          <GiSmallFire /> Product
        </Title>

        <BigBox title={"Tools"}>
          <div className="row">
            <div className="col-12 col-md-4">
              <Item
                className="gradient_bg font-weight-bold"
                color={section_color("product")}
                image={publish}
                onClick={() => navigate("/product/publish")}
              >
                <div className="row">
                  <div className="col-12 font-weight-bold">
                    <FaPaperPlane /> Publications to Amazon
                  </div>
                </div>
              </Item>
            </div>
            <div className="col-12 col-md-4">
              <Item
                className="gradient_bg font-weight-bold"
                color={section_color("product")}
                image={search}
                onClick={() => navigate("/product/imports")}
              >
                <GiSherlockHolmes /> Imports from Amazon
              </Item>
            </div>
            <div className="col-12 col-md-4">
              <Item
                className="gradient_bg font-weight-bold"
                color={section_color("product")}
                image={search}
                onClick={() => navigate("/product/translations")}
              >
                <GiSherlockHolmes /> Interface translations
              </Item>
            </div>
          </div>
        </BigBox>
      </div>
    </div>
  );
}
