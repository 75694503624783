import React from 'react'
import { useTranslate } from '../../context/lang'

export const validatePassword = (password) => {
  return password.length >= 8 && /[0-9]/.test(password) && /[a-z]/.test(password) && /[A-Z]/.test(password)
}

export default function PasswordHelper({password}) {
  const translate = useTranslate()

  const check_length = password.length >= 8
  const check_number = /[0-9]/.test(password)
  const check_uppercase = /[A-Z]/.test(password)
  const check_lowcase = /[a-z]/.test(password)

  if (check_length && check_number && check_uppercase && check_lowcase) {
    return null
  }
  return (
    <div>{translate({
        fr: `Le mot de passe doit être constitué de :`,
        en: `The password must have:`
    })}
      <ul>
        <li className={`${check_lowcase ? 'green':''}`}>{translate({
            fr: `Minimum 1 minuscule`,
            en: `At least 1 lower case character`
        })}</li>
        <li className={`${check_uppercase ? 'green':''}`}>{translate({
            fr: `Minimum 1 majuscule`,
            en: `At least 1 upper case character`
        })}</li>
        <li className={`${check_number ? 'green':''}`}>{translate({
            fr: `Minimum 1 chiffre`,
            en: `At least 1 number`
        })}</li>
        <li className={`${check_length ? 'green':''}`}>{translate({
            fr: `Minimum 8 caractères`,
            en: `Have a length of at least 8 characters`
        })}</li>
      </ul>
    </div>
  )
}
