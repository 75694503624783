import { useMutation } from "@apollo/client";
import bcrypt from "bcryptjs";
import React, { Fragment, useContext, useState } from "react";
import UserContext from "../../context/user";
import { ADD_USER } from "../../graphql/queries/user";
import CreateButton from "../generic/create-button";
import { DisplayErrors } from "../generic/display-errors";
import { displayFields } from "../generic/field";
import UpdateButton from "../generic/update-button";
import PasswordHelper, { validatePassword } from "./password-rule";

import useHashParam from "use-hash-param";
import { useLang, useTranslate } from "../../context/lang";
import { await_mutate } from "../../graphql/mutate_promise";
import Button from "../generic/button";
import ExternalLink from "../generic/external-link";
import { useLogin } from "./login";

export default function UserFields({
  onSubmit,
  mutation: { data, loading, error },
  children,
  type,
}) {
  const user = useContext(UserContext);
  const translate = useTranslate();

  const [email, set_email] = useState(user && user.user ? user.user.email : "");
  const [first_name, set_first_name] = useState(
    user && user.user ? user.user.first_name : ""
  );
  const [last_name, set_last_name] = useState(
    user && user.user ? user.user.last_name : ""
  );
  const [password, set_password] = useState("");
  const [phone_number, set_phone_number] = useState(
    user && user.user ? user.user.phone_number : ""
  );
  const [accept_intellifox_news, set_accept_intellifox_news] = useState(
    user && user.user ? user.user.accept_intellifox_news || false : false
  );
  const [accept_weekly_summary, set_accept_weekly_summary] = useState(
    user.user.accept_weekly_summary
  );

  const isUpdate = type === "update";
  const fields = [
    {
      type: "email",
      value: email,
      setter: set_email,
      label: translate({
        fr: "Adresse email",
        en: "Email address",
      }),
    },
    {
      type: "text",
      value: first_name,
      setter: set_first_name,
      label: translate({
        fr: "Prénom",
        en: "First name",
      }),
    },
    {
      type: "text",
      value: last_name,
      setter: set_last_name,
      label: translate({
        fr: "Nom",
        en: "Last name",
      }),
    },
    {
      type: "text",
      value: phone_number,
      setter: set_phone_number,
      label: translate({
        fr: "Numéro de téléphone",
        en: "Telephone number",
      }),
    },
    {
      type: "checkbox",
      value: accept_weekly_summary,
      setter: set_accept_weekly_summary,
      label: translate({
        fr: "Recevoir un résumé hebdomadaire",
        en: "Get weekly summary",
      }),
    },
  ];

  if (!isUpdate) {
    fields.push({
      type: "password",
      value: password,
      setter: set_password,
      label: "Entrez un mot de passe *",
      help: <PasswordHelper />,
      validate: validatePassword,
    });
  }

  let displayError = null;
  if (error) {
    displayError = (
      <div>
        {translate({
          fr: `Erreurs :`,
          en: `Errors:`,
        })}
        <DisplayErrors graphQLErrors={error.graphQLErrors} />
      </div>
    );
  }

  const handleSubmit = async () => {
    try {
      await onSubmit({
        email,
        first_name,
        last_name,
        password_hash: password
          ? bcrypt.hashSync(password, bcrypt.genSaltSync(8))
          : null,
        phone_number,
        accept_intellifox_news,
        accept_weekly_summary,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const isDisabled = isUpdate ? false : !validatePassword(password);

  return (
    <Fragment>
      <div className="col-12">{displayFields(fields, handleSubmit)}</div>
      <div className={"col-12 " + (error ? "error" : "")}>
        {displayError}
        {isUpdate ? (
          <UpdateButton
            onClick={handleSubmit}
            loading={loading}
            error={error}
            disabled={isDisabled}
            data={data}
          >
            {children}
          </UpdateButton>
        ) : (
          <CreateButton
            onClick={handleSubmit}
            loading={loading}
            error={error}
            disabled={isDisabled}
            data={data}
          >
            {children}
          </CreateButton>
        )}
      </div>
    </Fragment>
  );
}

export const CreateUserFields = ({ set_user, set_show_create_account }) => {
  const translate = useTranslate();
  const [step, set_step] = React.useState(1);
  const [error, set_error] = React.useState("");
  const lang = useLang();
  const [createAccount, mutation] = useMutation(ADD_USER);
  const [coupon_code, set_coupon_code] = useHashParam("coupon", "");

  const [login] = useLogin(set_user);

  const [email, set_email] = useState("");
  const [accept_cgv, set_accept_cgv] = useState(false);
  const [accept_intellifox_news, set_accept_intellifox_news] = useState(false);

  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [password, set_password] = useState("");

  const first_fields = [
    {
      type: "email",
      value: email,
      setter: set_email,
      label: translate({
        fr: "Entrez votre email : *",
        en: "Enter your email: *",
      }),
      placeholder: translate({
        fr: "Entrez votre adresse email ici",
        en: "Enter your email address here",
      }),
    },

    {
      type: "checkbox",
      value: accept_cgv,
      setter: set_accept_cgv,
      label: translate({
        fr: (
          <>
            J'ai lu et j'accepte les{" "}
            <ExternalLink
              to={"https://intellifox.com/conditions-generales-de-vente/"}
            >
              Conditions Générales de Vente
            </ExternalLink>
            , les{" "}
            <ExternalLink
              to={"https://intellifox.com/conditions-generales-d-utilisation/"}
            >
              Conditions Générales d'Utilisation
            </ExternalLink>
            , la{" "}
            <ExternalLink
              to={"https://intellifox.com/politique-de-confidentialite/"}
            >
              Politique de Confidentialité
            </ExternalLink>{" "}
            et la{" "}
            <ExternalLink
              to={
                "https://intellifox.com/politique-de-remboursement-et-de-resiliation/"
              }
            >
              Politique de Remboursement et de Résiliation
            </ExternalLink>
            .
          </>
        ),
        en: (
          <>
            I read and I accept the{" "}
            <ExternalLink
              to={"https://intellifox.com/conditions-generales-de-vente/"}
            >
              General Terms
            </ExternalLink>
            ,{" "}
            <ExternalLink
              to={"https://intellifox.com/conditions-generales-d-utilisation/"}
            >
              Usage Terms
            </ExternalLink>
            ,{" "}
            <ExternalLink
              to={"https://intellifox.com/politique-de-confidentialite/"}
            >
              Privacy Terms
            </ExternalLink>{" "}
            and the{" "}
            <ExternalLink
              to={
                "https://intellifox.com/politique-de-remboursement-et-de-resiliation/"
              }
            >
              Reimbursement Terms
            </ExternalLink>
            .
          </>
        ),
      }),
    },

    {
      type: "checkbox",
      value: accept_intellifox_news,
      setter: set_accept_intellifox_news,
      label: translate({
        fr: (
          <>
            Intellifox® ne cesse de s'améliorer ! Coche cette case pour
            recevoir les dernières actualités sur les produits, des tutoriels
            vidéo et des démos utiles. Tu peux changer d'avis à tout moment.
          </>
        ),
        en: (
          <>
            I wish to get <b>Intellifox® News</b> by email (1/2 per months, you
            can unsubscribe anytime).
          </>
        ),
      }),
    },
  ];

  const second_fields = [
    {
      type: "email",
      value: email,
      setter: set_email,
      label: translate({
        fr: "Entrez votre email *",
        en: "Enter your email*",
      }),
      placeholder: translate({
        fr: "Entrez votre adresse email ici",
        en: "Enter your email address here",
      }),
    },
    {
      type: "text",
      value: first_name,
      setter: set_first_name,
      label: translate({
        fr: "Entrez votre prénom *",
        en: "Enter your first name*",
      }),
      placeholder: translate({
        fr: "Entrez votre prénom, ex : Brent",
        en: "Enter your first name, e.g.: Brent",
      }),
    },
    {
      type: "text",
      value: last_name,
      setter: set_last_name,
      label: translate({
        fr: "Entrez votre nom *",
        en: "Enter your last name*",
      }),
      placeholder: translate({
        fr: "Entrez votre nom, ex : Spiner",
        en: "Enter your last name, e.g.: Spiner",
      }),
    },
    {
      type: "text",
      value: phone_number,
      setter: set_phone_number,
      label: translate({
        fr: "Entrez votre numéro de téléphone *",
        en: "Enter your phone number*",
      }),
      placeholder: translate({
        fr: "Entrez votre numéro de téléphone, ex : +33 6 12 34 56 78",
        en: "Enter your phone number, e.g.: +33 6 12 34 56 78",
      }),
    },
    {
      type: "password",
      value: password,
      setter: set_password,
      label: translate({
        fr: "Entrez un mot de passe *",
        en: "Enter a password*",
      }),
      help: <PasswordHelper password={password} />,
      validate: validatePassword,
    },
  ];

  let displayError = null;
  if (mutation.error) {
    displayError = (
      <div>
        {translate({
          fr: `Erreurs :`,
          en: `Errors:`,
        })}{" "}
        <DisplayErrors
          graphQLErrors={mutation.error.graphQLErrors}
          email={email}
          set_email={set_email}
        />
      </div>
    );
  }

  const handleSubmit = async () => {
    if (step === 1) {
      if (email && /.+@.+/.test(email) && accept_cgv) {
        set_error("");
        set_step(2);
      } else {
        set_error(
          translate({
            fr: "Vous devez entrer votre email et accepter les conditions générales pour continuer.",
            en: "You need to enter your email address and accept terms and conditions to continue.",
          })
        );
      }
      return;
    }

    try {
      await await_mutate(createAccount, {
        variables: {
          email,
          first_name,
          last_name,
          accept_intellifox_news,
          password_hash: password
            ? bcrypt.hashSync(password, bcrypt.genSaltSync(8))
            : null,
          lang,
          phone_number,
        },
      });

      await login(email, password);

      if (window.$crisp) {
        window.$crisp.push([
          "set",
          "session:event",
          ["has_just_created_account"],
        ]);
      }

      if (window.fbq) {
        window.fbq("track", "CompleteRegistration");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <div className="col-12">
        {step === 1 ? displayFields(first_fields, handleSubmit) : null}
        {step === 2 ? displayFields(second_fields, handleSubmit) : null}
      </div>

      <div className="col-12 error font-weight-bold red mt-3">
        {displayError}
        {error}
      </div>

      <div className={"col-6 " + (mutation.error ? "error" : "")}>
        {step === 2 ? (
          <>
            <Button outline={true} onClick={() => set_step(1)}>
              {translate({
                fr: `Retour`,
                en: `Back`,
              })}
            </Button>
          </>
        ) : (
          <>
            <Button
              outline={true}
              onClick={() => set_show_create_account(false)}
            >
              {translate({
                fr: `J'ai déjà un compte`,
                en: `I already have an account`,
              })}
            </Button>
          </>
        )}
      </div>
      <div className={"col-6 text-right " + (mutation.error ? "error" : "")}>
        {step === 2 ? (
          <CreateButton
            onClick={handleSubmit}
            loading={mutation.loading}
            error={mutation.error}
            data={mutation.data}
          >
            {translate({
              fr: `Rejoindre Intellifox`,
              en: `Join Intellifox`,
            })}
          </CreateButton>
        ) : (
          <Button onClick={handleSubmit}>
            {translate({
              fr: "Démarrer",
              en: `Let's start`,
            })}
          </Button>
        )}
      </div>
    </Fragment>
  );
};
