import * as _ from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  FaCheck,
  FaChevronCircleDown,
  FaChevronCircleRight,
  FaPhone,
  FaTimes,
  FiExternalLink,
  FiX,
  MdEmail,
  MdWarning,
} from "react-icons/all";
import Item from "../../components/generic/item";
import Select from "../../components/generic/select";
import {
  DefaultError,
  DefaultLoading,
} from "../../components/i18n/translations";
import BigBox from "../../components/layout/big_box";
import {
  CachedValues,
  is_ready,
  useCachedContext,
} from "../../hooks/useCachedContext";

import { gql, useMutation } from "@apollo/client";
import random_color from "randomcolor";
import Button from "../../components/generic/button";
import CopyButton, {
  TextCopyButton,
} from "../../components/generic/copy-button";
import DeleteButtonV2 from "../../components/generic/delete-button-v2";
import { DetailTag } from "../../components/generic/detail_tag";
import ExternalLinkButton from "../../components/generic/external-link-button";
import Field from "../../components/generic/field";
import IntellifoxModal from "../../components/generic/modal";
import { Step } from "../../components/generic/step";
import UpdateButtonV2 from "../../components/generic/update_button_v2";
import { useTranslate } from "../../context/lang";
import { await_mutate } from "../../graphql/mutate_promise";
import { create_mutation } from "../../graphql/queries/_utils";
import {
  ios_assist_intellinaut,
  ios_refund_payment_query,
} from "../../graphql/queries/ios_intellinauts";
import { get_client } from "../../graphql/simple-client";
import useCompanyId from "../../hooks/useCompanyId";
import { get_intellifox_url } from "../../hooks/useCurrentIntellifoxUrl";
import useLocalStorage from "../../hooks/useLocalStorage";
import { EmailHistorySearch } from "../product/email_history";
import { ListImport } from "../product/list_imports";
import { ListPublish } from "../product/list_publish";
import AskForCeoHelp from "./ask_for_ceo_help";
import { DisplayNextPayment } from "./cheque_france_num";
import { DeleteAccount } from "./delete_account";
import { DeleteAmazonAccount } from "./delete_amazon_account";
import { MoveToNewPlan } from "./move_to_new_plan";
import { StartFreeTrial } from "./start_free_trial";

export default function Intellinauts() {
  const [show_complete_list, set_show_complete_list] = React.useState(false);

  return (
    <>
      <IntellinautSearch />

      {show_complete_list ? (
        <BigBox
          title={"Complete list"}
          no_padding={true}
          horizontal_scroll={true}
        >
          <AllIntellinauts />
        </BigBox>
      ) : (
        <Button onClick={() => set_show_complete_list(true)}>
          See all users
        </Button>
      )}
    </>
  );
}

export const StatusBadge = ({ status, date }) => {
  let background = "secondary";
  let text = "n/a";

  date = date ? moment(parseInt(date)) : date;

  if (status === "active") {
    background = "green";
    text = "Active";
  } else if (status === "trialing") {
    background = "blue";
    text = `Trial`;
  } else if (status === "past_due") {
    background = "orange";
    text = `Late payment`;
  } else if (status === "deleted") {
    if (date && date.isSameOrAfter(moment())) {
      background = "#a15300";
      text = "Soon cancelled";
    } else {
      background = "black";
      text = "Cancelled";
    }
  }

  return (
    <DetailTag background={background}>
      {text}
      {date ? <small>{date.format()}</small> : ""}
    </DetailTag>
  );
};
export const display_alert_name = (transaction, previous_transaction) => {
  if (
    transaction.sale_gross === 0 &&
    transaction.alert_name === "subscription_payment_succeeded"
  ) {
    return `Trial start 🤞`;
  }

  if (
    transaction.alert_name === "subscription_updated" &&
    transaction.status === "active" &&
    transaction.old_status === "trialing"
  ) {
    return "🥳 Trial validated 🎉";
  }

  if (
    previous_transaction &&
    transaction.alert_name === "subscription_updated" &&
    transaction.status === "active" &&
    previous_transaction.status === "trialing"
  ) {
    return "🥳 Trial validated 🎉";
  }

  if (transaction.alert_name === "subscription_payment_succeeded") {
    return `Paid ${transaction.sale_gross} ${transaction.currency === "EUR" ? "€" : transaction.currency}`;
  }

  if (transaction.alert_name === "subscription_updated") {
    return "Subscription updated";
  }

  if (transaction.alert_name === "subscription_created") {
    return "🔥 New subscription";
  }
  if (transaction.alert_name === "subscription_cancelled") {
    return "😭 Cancelled";
  }
  if (transaction.alert_name === "subscription_payment_failed") {
    return `🧐 Failed payment ${transaction.attempts}`;
  }
  if (transaction.alert_name === "subscription_payment_refunded") {
    return `🧐 Refund (may be partial or full)`;
  }

  return transaction.alert_name;
};

const CancellationReason = ({ company }) => {
  const { cancellation_reason, cancellation_reason_date } = company;

  if (!cancellation_reason) {
    return null;
  }

  return (
    <div className="alert alert-danger mb-3">
      <h5>
        Cancelled on {moment(cancellation_reason_date).format()} because:
        <br />"{cancellation_reason}"
      </h5>
    </div>
  );
};
const Transactions = ({ company }) => {
  const user_with_transactions = useCachedContext(
    "ios_intellinaut_transactions_and_processes",
    company.intellinaut_company_id
  );

  if (user_with_transactions.loading) {
    return <DefaultLoading />;
  }

  if (user_with_transactions.error) {
    return <DefaultError />;
  }

  const filtered_transactions = _.clone(
    user_with_transactions.transactions
  ).sort((e1, e2) => (e1.event_time < e2.event_time ? 1 : -1));

  if (filtered_transactions.length === 0) {
    return (
      <div className="row">
        <div className="col-12 font-italic">No transaction found</div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-12">
        <ExternalLinkButton
          to={`https://vendors.paddle.com/subscriptions/customers/manage/${filtered_transactions[0].subscription_id}`}
        >
          Manage on Paddle
        </ExternalLinkButton>

        <Button onClick={() => user_with_transactions.refresh()}>
          Refresh transactions
        </Button>
      </div>
      <div className="col-12">
        {filtered_transactions.map((t, i) => {
          return (
            <Step
              step={user_with_transactions.transactions.length - i}
              title={`${display_alert_name(t, filtered_transactions[i + 1])}`}
              tags={
                <>
                  {moment(t.event_time).format()}{" "}
                  <DetailTag>{t.plan_name}</DetailTag>{" "}
                  {t.coupon ? (
                    <DetailTag background={"black"}>{t.coupon}</DetailTag>
                  ) : null}
                  <DetailTag>{t.country}</DetailTag>
                </>
              }
              action={
                <>
                  {t.balance_earnings > 0 ? (
                    <div className="row">
                      <div className="col-12">
                        <DetailTag background={"green"}>
                          +{t.balance_earnings}{" "}
                          {t.balance_currency === "EUR"
                            ? "€"
                            : t.balance_currency}
                        </DetailTag>
                      </div>
                    </div>
                  ) : null}
                  {t.balance_earnings_decrease > 0 ? (
                    <div className="row">
                      <div className="col-12">
                        <DetailTag background={"red"}>
                          {t.refund_type}: -{t.balance_earnings_decrease}{" "}
                          {t.balance_currency === "EUR"
                            ? "€"
                            : t.balance_currency}
                        </DetailTag>
                      </div>
                    </div>
                  ) : null}
                </>
              }
            >
              <div className="row">
                <div className="col-12 text-right">
                  {t.receipt_url ? (
                    <>
                      <RefundPayment transaction={t} />
                      <ExternalLinkButton
                        className={"btn-sm mr-1"}
                        to={`https://vendors.paddle.com/orders/detail/${t.order_id}`}
                      >
                        See order on Paddle
                      </ExternalLinkButton>
                      <ExternalLinkButton
                        className={"btn-sm"}
                        to={t.receipt_url}
                      >
                        See invoice
                      </ExternalLinkButton>
                    </>
                  ) : null}
                </div>
                <div className="col-12 small text-right mt-2">
                  <div className="row">
                    <div className="col-12 font-weight-bold">
                      Options after refund
                    </div>
                    <div className="col-12">
                      <IgnoreSubscription
                        subscription_id={
                          filtered_transactions[0].subscription_id
                        }
                      />
                    </div>
                    <div className="col-12">
                      <RebuildSubscription
                        intellinaut_company_id={company.intellinaut_company_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Step>
          );
        })}
      </div>
    </div>
  );
};

const RefundPayment = ({ transaction }) => {
  const company_id = useCompanyId();
  const [refund_payment] = useMutation(ios_refund_payment_query);

  const is_not_possible_to_refund = moment(transaction.event_time).isBefore(
    moment().add(-91, "days")
  );

  const do_refund_payment = async () => {
    await await_mutate(refund_payment, {
      variables: {
        company_id,
        order_id: transaction.order_id,
      },
    });
  };

  if (is_not_possible_to_refund) {
    return (
      <>
        <span className="small font-italic mr-1">
          Not refundable (+90 days)
        </span>
      </>
    );
  }

  return (
    <UpdateButtonV2 trigger={do_refund_payment} className="btn-sm mr-1">
      Refund this payment
    </UpdateButtonV2>
  );
};

const Support = ({ company }) => {
  const [support_link, set_support_link] = React.useState("");
  const assists = useCachedContext("ios_assists");

  const company_id = useCompanyId();

  const [do_ios_assist_intellinaut] = useMutation(ios_assist_intellinaut);

  return (
    <div className="row">
      <div className="col-12">
        <UpdateButtonV2
          trigger={async () => {
            const request = await await_mutate(do_ios_assist_intellinaut, {
              variables: {
                company_id,
                intellinaut_id: company.intellinaut_company_id,
              },
            });
            set_support_link(
              request.ios_assist_intellinaut.replace(
                "https://app.intellifox.com",
                get_intellifox_url()
              )
            );
            await assists.refresh();
          }}
          loadingMessage={"Impersonation link request in progress..."}
        >
          Get an impersonation link to access this account
        </UpdateButtonV2>
      </div>

      {support_link ? (
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <ExternalLinkButton to={support_link}>
                <FiExternalLink /> Access account in a new tab
              </ExternalLinkButton>
              <CopyButton text_to_copy={support_link}>Copy the link</CopyButton>
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-12">
                  <TextCopyButton
                    text_to_copy={support_link.replace(
                      get_intellifox_url(),
                      "https://app.sellersuite.com"
                    )}
                  >
                    Link for SellerSuite
                  </TextCopyButton>
                </div>
                <div className="col-12">
                  <TextCopyButton
                    text_to_copy={support_link.replace(
                      get_intellifox_url(),
                      "http://localhost:5173"
                    )}
                  >
                    Link for local development (SellerSuite)
                  </TextCopyButton>
                </div>
                <div className="col-12">
                  <TextCopyButton
                    text_to_copy={support_link.replace(
                      get_intellifox_url(),
                      "http://localhost:3000"
                    )}
                  >
                    Link for local development (Intellifox)
                  </TextCopyButton>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="alert alert-success">
                This request was recorded in the system.
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const RescheduleNextPayment = ({ company, children }) => {
  const { transactions, intellinaut_company_id } = company;

  const last_transaction = transactions[transactions.length - 1];

  return (
    <>
      {last_transaction ? (
        <div className="col-12">
          <DisplayNextPayment
            intellinaut_id={intellinaut_company_id}
            subscription_id={last_transaction.subscription_id}
          />
          {children}
        </div>
      ) : (
        <>No transaction found</>
      )}
    </>
  );
};

const ModifyNextPayment = ({ company }) => {
  const company_id = useCompanyId();
  const { transactions } = company;

  const last_transaction = transactions[transactions.length - 1];

  const all_payments = transactions.filter(
    (t) => t.alert_name === "subscription_payment_succeeded"
  );
  const last_payment = all_payments[all_payments.length - 1];

  console.log("last_payment", last_payment);

  const vat_rate =
    last_payment.balance_gross /
    (last_payment.balance_gross - last_payment.balance_tax);

  const ios_list_discounts = async (subscription_id) => {
    const client = get_client();
    const response = await client.request(
      `
      query ios_list_discounts($company_id: ID, $subscription_id: String) {
        ios_list_discounts (
          company_id: $company_id,
          subscription_id: $subscription_id,
        ) {
          modifier_id
          subscription_id
          amount
          currency
          is_recurring
          description
        }
      }
    `,
      {
        company_id,
        subscription_id,
      }
    );

    return response.ios_list_discounts;
  };

  if (!last_transaction) {
    return <>Aucune transaction trouvée</>;
  }

  return (
    <>
      <CachedValues
        name={"ios_list_discounts"}
        fetch_value={ios_list_discounts}
      >
        <ManageDiscounts
          subscription_id={last_transaction.subscription_id + ""}
          vat_rate={vat_rate}
        />
      </CachedValues>
    </>
  );
};

const Choice = ({ children, setter, current_value, value }) => {
  return (
    <Button
      onClick={() => setter(value)}
      className={"ml-2 mr-2"}
      button={value === current_value ? "primary" : "secondary"}
    >
      {children}
    </Button>
  );
};

const ManageDiscounts = ({ subscription_id, vat_rate = 1 }) => {
  const company_id = useCompanyId();
  const discounts = useCachedContext("ios_list_discounts", subscription_id);
  const next_payment = useCachedContext(
    "ios_get_next_payment",
    "" + subscription_id
  );

  const [apply_discount] = useMutation(gql`
    mutation ios_apply_discount(
      $company_id: ID
      $subscription_id: String
      $modifier_recurring: Boolean
      $modifier_amount: Float
      $modifier_description: String
    ) {
      ios_apply_discount(
        company_id: $company_id
        subscription_id: $subscription_id
        modifier_recurring: $modifier_recurring
        modifier_amount: $modifier_amount
        modifier_description: $modifier_description
      )
    }
  `);
  const [delete_discount] = useMutation(gql`
    mutation ios_delete_discount($company_id: ID, $modifier_id: String) {
      ios_delete_discount(company_id: $company_id, modifier_id: $modifier_id)
    }
  `);

  const [is_recurring, set_is_recurring] = React.useState(true);
  const [amount, set_amount] = React.useState("");
  const [description, set_description] = React.useState("");

  const [add_or_remove, set_add_or_remove] = React.useState("");
  const [amount_or_percentage, set_amount_or_percentage] = React.useState("");

  const [vat_rate_adjusted, set_vat_rate_adjusted] = React.useState(
    Math.round(100 * vat_rate - 100)
  );

  if (discounts.loading || next_payment.loading) {
    return <DefaultLoading />;
  }

  if (discounts.error || next_payment.error) {
    return <DefaultError />;
  }

  const do_apply_discount = async () => {
    let modifier_amount;

    const vat_rate = 1 + parseFloat(vat_rate_adjusted) / 100;

    if (isNaN(vat_rate) || !vat_rate) {
      throw new Error("Check VAT rate");
    }

    if (amount_or_percentage === "percentage") {
      modifier_amount =
        ((parseFloat(amount) / 100) * next_payment.amount) / vat_rate;
    } else {
      modifier_amount = parseFloat(amount) / vat_rate;
    }

    await await_mutate(apply_discount, {
      variables: {
        company_id,
        subscription_id,
        modifier_recurring: is_recurring,
        modifier_amount:
          add_or_remove === "remove" ? -modifier_amount : modifier_amount,
        modifier_description: description,
      },
    });

    await discounts.refresh();
    await next_payment.refresh();
  };
  const do_delete_discount = async (modifier_id) => {
    await await_mutate(delete_discount, {
      variables: {
        company_id,
        modifier_id: modifier_id + "",
      },
    });

    await discounts.refresh();
    await next_payment.refresh();
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        {discounts.length > 0 ? (
          <Step title={"Existing discount"}>
            <div className="row">
              <div className="col-12">
                {discounts.map((discount) => {
                  // {"modifier_id":782022,"subscription_id":4099677,"amount":"4.000","currency":"EUR","is_recurring":true,"description":"test"}
                  return (
                    <Step
                      title={discount.description}
                      action={
                        <DeleteButtonV2
                          do_delete={() =>
                            do_delete_discount(discount.modifier_id)
                          }
                        >
                          Delete
                        </DeleteButtonV2>
                      }
                    >
                      <div className="row">
                        <div className="col-12 font-weight-bold">
                          {(
                            parseFloat(discount.amount) * (vat_rate || 1)
                          ).toFixed(2)}{" "}
                          {discount.currency}
                        </div>
                        <div className="col-12">
                          Recurring: {discount.is_recurring ? "yes" : "no"}
                        </div>
                      </div>
                    </Step>
                  );
                })}
              </div>
            </div>
          </Step>
        ) : null}
      </div>

      <div className="col-12">
        <Step title={"Apply a discount"}>
          <div className="row">
            <div className="col-12">
              More expensive or less expensive?
              <Choice
                value={"remove"}
                current_value={add_or_remove}
                setter={set_add_or_remove}
              >
                Less expensive
              </Choice>
              <Choice
                value={"add"}
                current_value={add_or_remove}
                setter={set_add_or_remove}
              >
                More expensive
              </Choice>
            </div>
            <div className="col-12">
              Percent of fixed amount?
              <Choice
                value={"percentage"}
                current_value={amount_or_percentage}
                setter={set_amount_or_percentage}
              >
                percentage
              </Choice>
              <Choice
                value={"amount"}
                current_value={amount_or_percentage}
                setter={set_amount_or_percentage}
              >
                Fixed amount
              </Choice>
            </div>
          </div>
          {amount_or_percentage && add_or_remove ? (
            <div className="row">
              <Field
                type={"number"}
                value={amount}
                setter={set_amount}
                label={
                  amount_or_percentage === "amount" ? "Amount" : "Percentage"
                }
              />
              <Field
                type={"checkbox"}
                value={is_recurring}
                setter={set_is_recurring}
                label={"Apply on all future payments"}
              />
              <Field
                type={"text"}
                value={description}
                setter={set_description}
                label={"Short description"}
              />
              <Field
                type={"text"}
                value={vat_rate_adjusted}
                setter={set_vat_rate_adjusted}
                label={"VAT amount in percentage"}
              />
              <div className="col-12">
                <Button
                  outline={true}
                  onClick={() => set_vat_rate_adjusted("0")}
                >
                  0% VAT
                </Button>
                <Button
                  outline={true}
                  onClick={() => set_vat_rate_adjusted(20)}
                >
                  20% VAT
                </Button>
                <span className="small">
                  (detected rate: {Math.round(100 * vat_rate - 100)}%)
                </span>
              </div>
              <div className="col-12 small font-italic">
                Note: The amount cannot be calculated automatically before the
                1st payment.
                <br />
                It is important to enter the correct value so that the reduction
                applied is correct.
              </div>

              <div className="col-12">
                <UpdateButtonV2 trigger={do_apply_discount}>
                  Add the discount
                </UpdateButtonV2>
              </div>
            </div>
          ) : null}
        </Step>
      </div>
    </div>
  );
};

const ShowAmazonAccounts = ({ company }) => {
  const amazon_accounts = useCachedContext(
    "ios_amazon_accounts",
    company.intellinaut_company_id
  );
  if (amazon_accounts.loading) {
    return null;
  }

  if (amazon_accounts.error) {
    console.log("ManageAmazonAccounts got error:", amazon_accounts.error);
    return null;
  }

  return (
    <>
      {amazon_accounts.map(
        ({ label, merchant_id, invalid_grant, market_places }) => {
          const has_problem =
            invalid_grant || !market_places || market_places.length === 0;

          return (
            <div className="row" key={merchant_id}>
              <div className="col-12">
                <DetailTag
                  background={
                    has_problem
                      ? "red"
                      : random_color({
                          seed: merchant_id,
                          luminosity: "dark",
                        })
                  }
                  copy={true}
                  copy_value={merchant_id}
                  value={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>{label}</span>
                      <span>{merchant_id}</span>
                    </div>
                  }
                />
              </div>
              {has_problem ? (
                <div className={"col-12 small font-weight-bold"}>
                  <MdWarning /> Issue with this account
                </div>
              ) : null}
            </div>
          );
        }
      )}
    </>
  );
};

const ManageAmazonAccounts = ({ company }) => {
  const amazon_accounts = useCachedContext(
    "ios_amazon_accounts",
    company.intellinaut_company_id
  );
  if (amazon_accounts.loading) {
    return <DefaultLoading />;
  }

  if (amazon_accounts.error) {
    console.log("ManageAmazonAccounts got error:", amazon_accounts.error);
    return <DefaultError />;
  }

  return (
    <div className="row">
      <div className="col-12">
        {amazon_accounts.map(
          ({ merchant_id, label, market_places, invalid_grant }) => {
            const has_problem =
              invalid_grant || !market_places || market_places.length === 0;
            return (
              <Item>
                {has_problem ? (
                  <div className="alert alert-danger">
                    <div className="row">
                      <div className="col-12">
                        Il y a un problème avec ce compte :
                      </div>
                      <div className="col-12">
                        <ul>
                          {has_problem ? <li>Le token est expiré</li> : null}
                        </ul>
                      </div>
                      <div className="col-12 mt-3 font-weight-bold">
                        Solution à leur suggérer
                      </div>
                      <div className="col-12">
                        <ol>
                          <li>
                            Aller sur https://app.intellifox.com/settings{" "}
                            <CopyButton
                              text_to_copy={
                                "https://app.intellifox.com/settings"
                              }
                            >
                              Copier l'URL
                            </CopyButton>
                          </li>
                          <li>Suivre les instructions présentes à l'écran</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-12 font-weight-bold">{label}</div>
                  <div className="col-12">
                    {market_places ? (
                      market_places.map((market_place) => (
                        <span className={"mr-2"}>{market_place}</span>
                      ))
                    ) : (
                      <span>Aucune marketplace (ceci n'est pas normal)</span>
                    )}
                  </div>
                </div>
              </Item>
            );
          }
        )}
      </div>
    </div>
  );
};

const MoreDetails = ({ company }) => {
  const [selected_section, set_selected_section] = React.useState("");

  const sections = [
    {
      label: `Impersonate account`,
      component: <Support company={company} />,
    },
    {
      label: `Delete Amazon account`,
      component: <DeleteAmazonAccount company={company} />,
    },
    {
      label: `See transactions history`,
      component: (
        <>
          <CancellationReason company={company} />
          <Transactions company={company} />
        </>
      ),
    },
    {
      label: "Manage next payment",
      component: (
        <RescheduleNextPayment company={company}>
          <ModifyNextPayment company={company} />
        </RescheduleNextPayment>
      ),
    },
    {
      label: "Move to new plan",
      component: <MoveToNewPlan company={company} />,
    },
    {
      label: "Start free trial",
      component: <StartFreeTrial company={company} />,
    },
    {
      label: "See sent emails",
      component: (
        <EmailHistorySearch default_search={company.intellinaut_company_id} />
      ),
    },
    {
      label: "See imports",
      component: <ListImport default_search={company.intellinaut_company_id} />,
    },
    {
      label: "See publications",
      component: (
        <ListPublish default_search={company.intellinaut_company_id} />
      ),
    },
    {
      label: `Ask for Conrad's help`,
      component: (
        <AskForCeoHelp
          intellinaut_company_id={company.intellinaut_company_id}
        />
      ),
    },
    {
      label: `Delete account`,
      component: <DeleteAccount company={company} />,
    },
  ];

  return (
    <div className="row">
      <Select
        label={"Available actions"}
        option_label={(i) => sections[i].label}
        options={sections.map((_, i) => i)}
        setter={(i) =>
          selected_section === i
            ? set_selected_section("")
            : set_selected_section(i)
        }
        value={selected_section}
      />

      {sections[selected_section] ? (
        <div className="col-12">
          <Item>{sections[selected_section].component}</Item>
        </div>
      ) : null}
    </div>
  );
};

const languageCodeToText = (lang) => {
  const lang_to_text = {
    fr: "French",
    en: "English",
    de: "German",
    it: "Italian",
    es: "Spanish",
    nl: "Dutch",
    pt: "Portuguese",
    pl: "Polish",
    ru: "Russian",
    tr: "Turkish",
    ja: "Japanese",
    zh: "Chinese",
    ko: "Korean",
    ar: "Arabic",
    hi: "Hindi",
    bn: "Bengali",
    pa: "Punjabi",
    te: "Telugu",
    mr: "Marathi",
    ta: "Tamil",
    ur: "Urdu",
    gu: "Gujarati",
    kn: "Kannada",
    or: "Oriya",
    ml: "Malayalam",
    th: "Thai",
    vi: "Vietnamese",
    id: "Indonesian",
    ms: "Malay",
    tl: "Filipino",
  };

  return lang_to_text[lang] || lang;
};

const IntellinautLine = ({ company, is_only_intellinaut }) => {
  const translate = useTranslate();
  const [_show_more_details, set_show_more_details] = React.useState(false);
  const show_more_details = _show_more_details || is_only_intellinaut;

  const intellinaut_emails = company.intellinauts.map((i) => i.email);
  const user_with_transactions = useCachedContext(
    "ios_intellinaut_transactions_and_processes",
    company.intellinaut_company_id
  );

  if (user_with_transactions.loading) {
    return <DefaultLoading />;
  }

  if (user_with_transactions.error) {
    return <DefaultError />;
  }
  const other_emails = user_with_transactions.transactions.reduce(
    (other_emails, t) => {
      if (
        intellinaut_emails.indexOf(t.email) === -1 &&
        other_emails.indexOf(t.email) === -1
      ) {
        other_emails.push(t.email);
      }
      return other_emails;
    },
    []
  );

  if (
    company.intellinauts?.filter(
      (u) => u.first_name?.includes("{") || u.last_name?.includes("{")
    ).length > 0
  ) {
    console.log({ company });
  }

  // https://vendors.paddle.com/subscriptions/customers/manage/6986434
  return (
    <>
      <tr className={"small"}>
        <td
          className="clickable"
          onClick={() => set_show_more_details(!show_more_details)}
        >
          <h2>
            {show_more_details ? (
              <FaChevronCircleDown />
            ) : (
              <FaChevronCircleRight />
            )}
          </h2>
        </td>
        <td>
          {company.intellinauts.map((u) => {
            if (u.first_name.includes("{") || u.last_name.includes("{")) {
              return (
                <div className="row">
                  <div className="col-12">
                    {translate({
                      en: "Agency Customer",
                      fr: "Client Agence",
                    })}
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    ({languageCodeToText(company.lang)})
                  </div>
                </div>
              );
            }
            return (
              <div className="row">
                <div className="col-12">
                  <TextCopyButton text_to_copy={u.first_name}>
                    {u.first_name}
                  </TextCopyButton>
                </div>
                <div className="col-12">
                  <TextCopyButton text_to_copy={u.last_name}>
                    {u.last_name}
                  </TextCopyButton>
                </div>
                <div
                  className="col-12"
                  style={{
                    fontSize: 14,
                  }}
                >
                  ({languageCodeToText(company.lang)})
                </div>
              </div>
            );
          })}
        </td>
        <td>
          {company.intellinauts.map((u) => {
            if (u.email.includes("{")) {
              return (
                <div className="row">
                  <div className="col-12">
                    {translate({
                      en: "Agency Customer",
                      fr: "Client Agence",
                    })}
                  </div>
                </div>
              );
            }
            return (
              <div className="row">
                <div className="col-12">
                  Login email:{" "}
                  <TextCopyButton text_to_copy={u.email}>
                    {u.email}
                  </TextCopyButton>
                </div>
                <div className="col-12">
                  <TextCopyButton text_to_copy={u.phone_number}>
                    {u.phone_number}
                  </TextCopyButton>
                </div>
                {u.amazon_user_id ? (
                  <div className="col-12">
                    <FaCheck /> Login with Amazon{" "}
                    <TextCopyButton text_to_copy={u.amazon_email}>
                      {u.amazon_email}
                    </TextCopyButton>
                  </div>
                ) : (
                  <div className="col-12 small">
                    <FaTimes /> No login with Amazon
                  </div>
                )}
              </div>
            );
          })}
          {other_emails.map((email) => {
            return (
              <div className="row">
                <div className="col-12">
                  <TextCopyButton text_to_copy={email}>{email}</TextCopyButton>{" "}
                  (utilisé sur Paddle)
                </div>
              </div>
            );
          })}
        </td>
        <td>
          <div>{moment(company.creation_date).fromNow()}</div>

          <TextCopyButton
            text_to_copy={moment(company.creation_date).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
            style={{
              textWrap: "nowrap",
              fontSize: 12,
            }}
          >
            {moment(company.creation_date).format("MMMM Do YYYY, h:mm:ss a")}
          </TextCopyButton>
        </td>
        <td>
          <ShowAmazonAccounts company={company} />
        </td>
        <td>
          <DetailTag
            background={random_color({
              seed: company.intellinaut_company_id,
              luminosity: "dark",
            })}
            copy={true}
            value={company.intellinaut_company_id}
            max_length={10}
          />
        </td>
        <td>
          <div className="mb-1">
            <DetailTag background={"green"}>
              {company.subscription_tier} - {company.subscription_frequency}
            </DetailTag>
          </div>
          {company.subscription_status.map((s) => {
            return (
              <div className="row">
                <div className="col-12">
                  <StatusBadge
                    status={s.status}
                    date={s.cancellation_effective_date}
                  />
                </div>
              </div>
            );
          })}
        </td>
        <td>{company.referral_source ?? "-"}</td>
      </tr>

      {show_more_details ? (
        <tr>
          <td colSpan={12}>
            <MoreDetails
              company={{
                ...company,
                ...user_with_transactions,
              }}
            />
          </td>
        </tr>
      ) : null}
    </>
  );
};

const IntellinautList = ({
  intellinaut_companies,
  sort_by,
  order_by_desc,
  set_sort_by,
  set_order_by_desc,
}) => {
  const [limit, set_limit] = React.useState(50);

  intellinaut_companies = intellinaut_companies.sort((s1, s2) => {
    if (order_by_desc) {
      return s1[sort_by] < s2[sort_by] ? 1 : -1;
    } else {
      return s1[sort_by] < s2[sort_by] ? -1 : 1;
    }
  });

  return (
    <div className="row">
      <div className="col-12">
        <table className="table">
          <thead>
            <tr className="font-weight-bold">
              <td></td>
              <td>Name</td>
              <td>
                <MdEmail /> <FaPhone />
              </td>
              <td
                className="clickable"
                onClick={() => {
                  set_sort_by("creation_date");
                  set_order_by_desc(!order_by_desc);
                }}
              >
                Account creation
              </td>
              <td
                className="clickable"
                onClick={() => {
                  set_sort_by("merchant_id");
                  set_order_by_desc(!order_by_desc);
                }}
              >
                merchant_id (amazon)
              </td>
              <td>company_id</td>
              <td>subscription</td>
              <td>referral source</td>
            </tr>
          </thead>
          <tbody>
            {intellinaut_companies.map((company, i) => {
              if (i > limit) {
                return null;
              }

              return (
                <IntellinautLine
                  key={company.intellinaut_company_id}
                  company={company}
                  is_only_intellinaut={intellinaut_companies.length === 1}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="col-12 text-center">
        {intellinaut_companies.length - limit > 0 ? (
          <Button onClick={() => set_limit(limit + 50)} outline={true}>
            See more users ({intellinaut_companies.length - limit})
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const AllIntellinauts = () => {
  const translate = useTranslate();
  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  const [selected_filters, _set_selected_filters] = React.useState(0);
  const [sort_by, set_sort_by] = React.useState("creation_date");
  const [order_by_desc, set_order_by_desc] = React.useState(true);

  const filters = useMemo(() => {
    if (intellinaut_companies.loading) {
      return [];
    }

    if (intellinaut_companies.error) {
      return [];
    }

    const companies_grouped_by_merchant_id = _.groupBy(
      intellinaut_companies,
      "merchant_id"
    );

    const merchant_ids_with_more_than_one_company = Object.keys(
      companies_grouped_by_merchant_id
    ).filter(
      (merchant_id) =>
        merchant_id && companies_grouped_by_merchant_id[merchant_id].length > 1
    );

    const status_filter = (status) => {
      return {
        label: <StatusBadge status={status} />,
        companies: intellinaut_companies.filter((ic) =>
          ic.subscription_status.reduce(
            (has_status, s) => has_status || s.status === status,
            false
          )
        ),
        order_by_desc: true,
        sort_by: "creation_date",
      };
    };

    return [
      {
        label: "Tous",
        companies: intellinaut_companies,
        order_by_desc: true,
        sort_by: "creation_date",
      },
      status_filter("active"),
      {
        label: (
          <>
            <StatusBadge status="active" /> + English
          </>
        ),
        companies: intellinaut_companies.filter(
          (ic) =>
            ic.lang === "en" &&
            ic.subscription_status.reduce(
              (has_status, s) => has_status || s.status === "active",
              false
            )
        ),
        order_by_desc: true,
        sort_by: "creation_date",
      },
      status_filter("trialing"),
      status_filter("past_due"),
      status_filter("deleted"),
      {
        label: "Aucun abonnement",
        companies: intellinaut_companies.filter(
          (ic) => ic.subscription_status.length === 0
        ),
        order_by_desc: true,
        sort_by: "creation_date",
      },
      {
        label: "Potential fraud",
        companies: intellinaut_companies.filter(
          (ic) =>
            merchant_ids_with_more_than_one_company.indexOf(ic.merchant_id) !==
            -1
        ),
        order_by_desc: true,
        sort_by: "merchant_id",
      },
      {
        label: "Started a subscription",
        companies: intellinaut_companies.filter(
          (ic) => ic.subscription_status.length > 0
        ),
        order_by_desc: true,
        sort_by: "creation_date",
      },
    ];
  }, [intellinaut_companies]);

  if (intellinaut_companies.loading) {
    return <DefaultLoading />;
  }

  if (intellinaut_companies.error) {
    return <DefaultError what={intellinaut_companies} />;
  }

  const set_selected_filters = (i) => {
    _set_selected_filters(i);
    set_order_by_desc(filters[i].order_by_desc);
    set_sort_by(filters[i].sort_by);
  };

  const filtered_companies = filters[selected_filters].companies;

  return (
    <div className="row">
      <div
        className="col-12"
        style={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 9,
          borderBottom: "1px solid black",
          margin: 0,
        }}
      >
        <Select
          label={translate({ en: "Filters", fr: "Filtres" })}
          value={selected_filters}
          setter={set_selected_filters}
          options={filters.map((_, i) => i)}
          option_label={(i) => (
            <span>
              {filters[i].label} ({filters[i].companies.length})
            </span>
          )}
        />
      </div>
      <div className="col-12">
        <IntellinautList
          intellinaut_companies={filtered_companies}
          sort_by={sort_by}
          order_by_desc={order_by_desc}
          set_order_by_desc={set_order_by_desc}
          set_sort_by={set_sort_by}
        />
      </div>
    </div>
  );
};

const IntellinautSearch = () => {
  const translate = useTranslate();
  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  const [previous_search, set_previous_search] = useLocalStorage(
    "ios_success_search",
    ""
  );
  const [search, set_search] = useState(previous_search);
  React.useEffect(() => {
    set_previous_search(search);
  }, [search]);
  const [sort_by, set_sort_by] = React.useState("creation_date");
  const [order_by_desc, set_order_by_desc] = React.useState(true);

  if (intellinaut_companies.error) {
    return <DefaultError what={intellinaut_companies} />;
  }

  let regex = /invalid/;
  try {
    regex = new RegExp(search, "ig");
  } catch (e) {}
  const filtered_companies =
    is_ready(intellinaut_companies) && search.length >= 3
      ? intellinaut_companies.filter((c) => {
          if (!search) {
            return false;
          }

          let match = false;

          if (regex.test(c.intellinaut_company_id)) {
            match = true;
          }

          for (let i = 0; i < c.intellinauts.length; i++) {
            const { first_name, last_name, email, phone_number } =
              c.intellinauts[i];
            if (
              regex.test(first_name) ||
              regex.test(last_name) ||
              regex.test(email) ||
              regex.test(phone_number)
            ) {
              match = true;
            }
          }

          return match;
        })
      : [];

  return (
    <BigBox title={"Find a user"}>
      <div className="row">
        <div className="col-12">
          <div className="p-3">
            <Field
              label={
                "Search with first name, last name, email or company_id (3 characters minimum)"
              }
              value={search}
              setter={set_search}
            />
          </div>
        </div>
        {!is_ready(intellinaut_companies) ? (
          <DefaultLoading />
        ) : (
          <>
            {search && filtered_companies.length === 0 && search.length >= 3 ? (
              <div className="col-12">
                <i>
                  {translate({
                    en: "Couldn't find a user matching this search",
                  })}
                </i>
              </div>
            ) : null}
            {filtered_companies.length > 0 ? (
              <div className="col-12">
                <IntellinautList
                  intellinaut_companies={filtered_companies}
                  sort_by={sort_by}
                  order_by_desc={order_by_desc}
                  set_order_by_desc={set_order_by_desc}
                  set_sort_by={set_sort_by}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </BigBox>
  );
};

const IgnoreSubscription = ({ subscription_id }) => {
  const company_id = useCompanyId();
  const translate = useTranslate();

  const ignored_subscription_ids = useCachedContext("ignored_subscription_ids");

  const [cancel_ignored_subscription_id] = useMutation(
    create_mutation({
      mutation: "cancel_ignored_subscription_id",
      params: {
        company_id: "ID!",
        subscription_id: "Float",
      },
    })
  );

  if (ignored_subscription_ids.loading) {
    return <DefaultLoading />;
  }

  if (ignored_subscription_ids.error) {
    return <DefaultError />;
  }

  const is_ignored = ignored_subscription_ids.find(
    (s) => s.subscription_id === subscription_id
  );

  if (is_ignored) {
    return (
      <>
        <span className="font-italic">
          <FaCheck /> {translate({ fr: "Abonnement ignoré depuis le" })}{" "}
          {moment(is_ignored.date).format("YYYY-MM-DD")} (raison : "
          {is_ignored.reason}" ){" "}
        </span>
        <UpdateButtonV2
          button={"link"}
          className={"btn-sm"}
          trigger={async () => {
            await await_mutate(cancel_ignored_subscription_id, {
              variables: {
                company_id,
                subscription_id,
              },
            });

            await ignored_subscription_ids.refresh();
          }}
        >
          <FiX /> Annuler
        </UpdateButtonV2>
      </>
    );
  }

  return (
    <>
      <IgnoreSubscriptionModal subscription_id={subscription_id} />{" "}
    </>
  );
};

const default_reasons = [
  `Subscription cancelled and refunded`,
  `Double subscription`,
];

const IgnoreSubscriptionModal = ({ subscription_id }) => {
  const translate = useTranslate();
  const company_id = useCompanyId();

  const ignored_subscription_ids = useCachedContext("ignored_subscription_ids");

  const [set_ignored_subscription_id] = useMutation(
    create_mutation({
      mutation: "set_ignored_subscription_id",
      params: {
        company_id: "ID!",
        subscription_id: "Float",
        reason: "String",
      },
    })
  );

  const [reason, set_reason] = React.useState("");
  return (
    <IntellifoxModal
      title={"Entrer une raison"}
      button_props={{
        className: "btn-sm",
        button: "danger",
      }}
      action_label={`Deactivate the subscription now`}
      on_click={async () => {
        await await_mutate(set_ignored_subscription_id, {
          variables: {
            company_id,
            subscription_id,
            reason,
          },
        });

        await ignored_subscription_ids.refresh();
      }}
      body={
        <div className="row">
          <Field
            type={"text"}
            value={reason}
            setter={set_reason}
            placeholder={"Enter an explanation..."}
          />
          <div className="col-12">
            Usual explanations:
            <ul>
              {default_reasons.map((r) => (
                <li>
                  <Button
                    className={"btn-sm"}
                    button={"link"}
                    onClick={() => set_reason(r)}
                  >
                    {r}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      }
    >
      {translate({ en: "Deactivate the subscription now" })}
    </IntellifoxModal>
  );
};

const RebuildSubscription = ({ intellinaut_company_id }) => {
  const company_id = useCompanyId();
  const translate = useTranslate();
  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  const [rebuild_subscription_from_scratch] = useMutation(
    create_mutation({
      mutation: "rebuild_subscription_from_scratch",
      params: {
        company_id: "ID!",
        intellinaut_company_id: "ID!",
      },
    })
  );

  return (
    <>
      <UpdateButtonV2
        className={"btn-sm"}
        trigger={async () => {
          await await_mutate(rebuild_subscription_from_scratch, {
            variables: {
              company_id,
              intellinaut_company_id,
            },
          });

          await intellinaut_companies.refresh();
        }}
      >
        {translate({ en: "Update subscription status" })}
      </UpdateButtonV2>{" "}
    </>
  );
};
