import React from 'react'
import Login from '../user/login'

import LeftMenu from '../navigation/left-menu'
import { useTheme } from '../../context/theme'
import Redirect from '../generic/redirect'
import { Router } from '@reach/router'
import { routes } from '../../routes/routes'
import { useHasActiveSubscription, useSubscriptionStatus } from '../../hooks/useCompanyId'
import SelectPlan, { ShowOptions, ShowPausedOptions } from '../user/select-plan'
import useLocalStorage from '../../hooks/useLocalStorage'
import ResetPassword from '../user/reset_password'
import { DefaultLoading } from '../i18n/translations'
import { useWindowSize } from '../../hooks/useWindowSize'
import Item from '../generic/item'
import Button from '../generic/button'
import IntellifoxIcon from '../../images/intellifox-icon'

const SettingsSpe = ({ token, user_id, company_id }) => {

  const [_, set_user] = useLocalStorage('user', '')

  React.useEffect(() => {
    set_user({
      token,
      user: {
        user_id,
      },
      company: {
        company_id,
      },
    })
    window.location.reload()
  }, [])

  return <Redirect to={'/'}/>
}


export const ShowProperStuff = () => {
  const subscription_status = useSubscriptionStatus()

  if (subscription_status === 'paused') {
    return <ShowOptions subscription_status={subscription_status}/>
  }

  return <SelectPlan is_from_cancelled_plan={subscription_status === 'cancelled' || subscription_status === 'deleted'}/>
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError(error) {

    return {
      error,
      url: window.location.toString(),
    }
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error && this.state.url === window.location.toString()) {
      let details = ''
      try {

        details = `**URL**:
"${this.state.url}"`
        details += `
        
**Erreur**:
"${this.state.error.toString()}"`
        details += `
        
**Détails techniques**:
${this.state.error.stack.split('\n').filter((k, i) => i < 4).join('\n')}`
      } catch (e) {

      }


      return <div className="container pt-5 mt-5">
        <div className="alert alert-danger">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 pt-4">
                      <h1><IntellifoxIcon/> An error happened 😭</h1>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3">
                  <Item>
                    <div className="col-12">
                      <Button onClick={() => window.location.reload()}>Reload page</Button>
                    </div>
                  </Item>
                </div>


                <div className="col-12  mt-3">
                  Error details

                  <Item>
                    <div className="row small">
                      <div className="col-12">
                        <code>{details.split('\n').map((detail) => <>{detail}<br/></>)}</code>
                      </div>
                    </div>
                  </Item>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    }
    return this.props.children
  }
}


export default function MainContent({ isLoggedIn, disconnect, set_user }) {
  const { theme } = useTheme()
  const has_active_subscription = useHasActiveSubscription()
  const [discreet_mode] = useLocalStorage('intellifox_discreet_mode', false)
  const [overflow_hidden, set_overflow_hidden] = React.useState(false)


  return (
    <>
      <div
        className={`container-fluid variable-root global-colors ${theme} min-vh-100 ${discreet_mode ? 'discreet_mode' : ''}`}
        style={{
          overflow: overflow_hidden ? 'hidden' : '',
          maxHeight: overflow_hidden ? '100vh' : '',
        }}>
        <div className="row">
          <Router basepath={'/'} primary={false}>
            <Redirect to={'/'} path={'/'}/>
            <LeftMenu isLoggedIn={isLoggedIn} disconnect={disconnect} path={'*'}/>
          </Router>
          {
            isLoggedIn && has_active_subscription ?
              <Router basepath={'/'}>
                {routes.map(({ Menu, path }, i) => Menu ? <Menu key={i} path={path} disconnect={disconnect}
                                                                set_overflow_hidden={set_overflow_hidden}/> : null)}
              </Router> : null
          }
          <div className="col" style={{ height: '100vh', overflow: 'hidden' }}>
            <div className="row" style={{ height: '100%' }}>
              {isLoggedIn && has_active_subscription ?
                <>
                  {/*<div className="col-12" style={{ height: 36 }}>*/}
                  {/*  <Router basepath={'/'} primary={false}>*/}
                  {/*    <Tabs path={'*'}/>*/}
                  {/*  </Router>*/}
                  {/*</div>*/}
                  <div className="col-12 pt-5 pt-md-0" style={{ height: '100%', overflow: 'auto' }}>
                    <React.Suspense fallback={<DefaultLoading/>}>
                      <ErrorBoundary>
                        <Router basepath={'/'}>
                          {routes.map(({ Component, path }, i) => <Component key={i} path={path} disconnect={disconnect}
                                                                             set_overflow_hidden={set_overflow_hidden}/>)}
                        </Router>
                      </ErrorBoundary>
                    </React.Suspense>
                  </div>
                </> :
                ( isLoggedIn ?
                  <div className="col-12 pt-5 pt-3" style={{ height: '100%', overflow: 'auto' }}>
                    <Router basepath={'/'} primary={false}>
                      <ShowProperStuff path={'*'}/>
                    </Router>
                  </div> : <div className="col-12 pt-5 mt-3">
                    <Router basepath={'/'} primary={false}>
                      <Login set_user={set_user} path={'*'}/>
                    </Router>
                  </div> )
              }
            </div>
          </div>
        </div>


      </div>

    </>
  )
}
