import React from 'react'
import { get_client } from '../../graphql/simple-client'
import * as _ from 'lodash'
import { create_mutation, create_query } from '../../graphql/queries/_utils'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'
import Button from '../../components/generic/button'
import { AffiliateSummary, format_percentage, get_commission_rate_structure } from '../intellifox'
import BigBox from '../../components/layout/big_box'
import en from 'moment/locale/en-ca'
import moment from 'moment'

import './dashboard.scss'
import CopyButton from '../../components/generic/copy-button'
import {  FaGreaterThanEqual, IoMdCopy, MdEdit } from 'react-icons/all'
import Title from '../../components/layout/title'
import IntellifoxIcon from '../../images/intellifox-icon'
import IntellifoxModal from '../../components/generic/modal'
import Field from '../../components/generic/field'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'

export default function AffiliateDashboard({ secret }) {
  React.useEffect(() => {
    moment.updateLocale('en', en)
    moment.locale('en')

    document.querySelector('html').setAttribute('lang', 'en')
  }, [])

  const fetch_ios_mrr = async () => {
    const client = get_client()
    const response = await client.request(create_query({
      query: 'ios_mrr_affiliate',
      params: {
        secret: 'String',

      },
      output: `
      {
        start
        end
        mrr
        intellinauts
        
        details {
          date
          intellinaut_id
          referral_id
          mrr
          frequency  
        }
        
        affiliates {
          name
          referral_id
          start
          end
          mrr
          intellinauts
        }
    }
      `,
    }), {
      secret,
    })
    return _.reverse(response.ios_mrr_affiliate)
  }
  const fetch_ios_get_affiliate_info = async () => {
    const client = get_client()
    const response = await client.request(create_query({
      query: 'ios_get_affiliate_info',
      params: {
        secret: 'String',

      },
      output: `
      {
         name
         referral_id
         extra_data
    }
      `,
    }), {
      secret,
    })
    return {
      ...response.ios_get_affiliate_info,
      extra_data: JSON.parse(response.ios_get_affiliate_info.extra_data),
    }
  }


  return (
    <CachedValues name={'ios_mrr_affiliate'} fetch_value={fetch_ios_mrr}>
      <CachedValues name={'ios_get_affiliate_info'} fetch_value={fetch_ios_get_affiliate_info}>
        <div className="variable-root light"
             style={{ overflow: 'auto', height: '100vh', background: 'black', color: 'white' }}>
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-12">
                <DisplayDashboard secret={secret}/>

              </div>
            </div>
          </div>
        </div>

      </CachedValues>
    </CachedValues>

  )
}

const DisplayDashboard = ({ secret }) => {

  const ios_mrr_affiliate = useCachedContext('ios_mrr_affiliate', secret)
  const ios_get_affiliate_info = useCachedContext('ios_get_affiliate_info', secret)

  if (ios_mrr_affiliate.loading || ios_get_affiliate_info.loading) {
    return <div>Loading...</div>
  }

  if (ios_mrr_affiliate.error || ios_get_affiliate_info.error) {
    console.log('what?', ios_mrr_affiliate.error || ios_get_affiliate_info.error)
    return <div>
      <Button onClick={() => ios_mrr_affiliate.refresh()}>Refresh</Button>
    </div>
  }

  const { referral_id, name, extra_data } = ios_get_affiliate_info
  const link = `https://intellifox.com/#r_id=${referral_id}`

  return <>
    <Title color={'white'} style={{ color: 'black' }}>
      <div className="row">
        <div className="col-12">
          <IntellifoxIcon/> Intellifox affiliate dashboard / {name}
        </div>
      </div>
    </Title>
    <BigBox title={'Affiliate link'} actions={<AcceptTermsAndConditions secret={secret} ios_get_affiliate_info={ios_get_affiliate_info}/>}>
      <h2>{link} <CopyButton text_to_copy={link}><IoMdCopy/> Copy link</CopyButton></h2>
    </BigBox>

    <BigBox title={'Results and payouts'} no_padding={true}
            actions={<ConfigureAffiliate ios_get_affiliate_info={ios_get_affiliate_info} secret={secret}/>}>
      <AffiliateSummary months={ios_mrr_affiliate} referral_id={referral_id} extra_data={extra_data} secret={secret}/>
    </BigBox>
  </>
}

const AcceptTermsAndConditions = ({ ios_get_affiliate_info, secret }) => {
  const extra_data = ios_get_affiliate_info.extra_data || {}
  const { referral_id } = ios_get_affiliate_info

  const accepted_terms_and_conditions = extra_data.accepted_terms_and_conditions_2022_01_25 || false

  const [ios_set_affiliate_info] = useMutation(create_mutation({
    mutation: 'ios_set_affiliate_info',
    params: {
      secret: 'String',
      extra_data: 'String',
    },
  }))

  const accept_them = async () => {
    await await_mutate(ios_set_affiliate_info, {
      variables: {
        secret,
        extra_data: JSON.stringify({
          ...extra_data,
          accepted_terms_and_conditions_2022_01_25: true,
        }),
      },
    })
    ios_get_affiliate_info.refresh()
  }

  return <IntellifoxModal show_modal_default={!accepted_terms_and_conditions}
                          title={'Review & accept affiliate program terms and conditions'}
                          body={<AffiliateTermsAndConditions referral_id={referral_id}/>}
                          action_label={'Accept terms and conditions'}
                          on_click={accept_them}
  >
    See terms and conditions
  </IntellifoxModal>

}

const ConfigureAffiliate = ({ ios_get_affiliate_info, secret }) => {

  const extra_data = ios_get_affiliate_info.extra_data || {}

  // company_name: 'Allain Consulting',
  const [company_name, set_company_name] = React.useState(extra_data.company_name || '')
  // siren: '879686046',
  const [siren, set_siren] = React.useState(extra_data.siren || '')
  // company_address_line_1: '75 chemin des fusains',
  const [company_address_line_1, set_company_address_line_1] = React.useState(extra_data.company_address_line_1 || '')
  // company_address_line_2: 'Puyricard',
  const [company_address_line_2, set_company_address_line_2] = React.useState(extra_data.company_address_line_2 || '')
  // company_postal_code: '13540',
  const [company_postal_code, set_company_postal_code] = React.useState(extra_data.company_postal_code || '')
  // company_city: 'Aix-en-Provence FR',
  const [company_city, set_company_city] = React.useState(extra_data.company_city || '')
  // iban: `FR76 1027 8374 7300 0122 5700 233`,
  const [iban, set_iban] = React.useState(extra_data.iban || '')
  // bic: `CMCIFR2A`,
  const [bic, set_bic] = React.useState(extra_data.bic || '')
  // vat_number: 'FR05879686046',
  const [vat_number, set_vat_number] = React.useState(extra_data.vat_number || '')
  const [vat_rate, set_vat_rate] = React.useState(extra_data.vat_rate || 0)
  const [extra_info, set_extra_info] = React.useState(extra_data.extra_info || 0)

  const [ios_set_affiliate_info] = useMutation(create_mutation({
    mutation: 'ios_set_affiliate_info',
    params: {
      secret: 'String',
      extra_data: 'String',
    },
  }))

  const update_it = async () => {
    await await_mutate(ios_set_affiliate_info, {
      variables: {
        secret,
        extra_data: JSON.stringify({
          company_name,
          siren,
          company_address_line_1,
          company_address_line_2,
          company_postal_code,
          company_city,
          iban,
          bic,
          vat_number,
          vat_rate,
          extra_info,
        }),
      },
    })
    ios_get_affiliate_info.refresh()
  }

  return <IntellifoxModal title={'Edit company info'}
                          body={<div className="row">
                            <Field type={'text'} value={company_name} setter={set_company_name} label={'company name'}/>
                            <Field type={'text'} value={siren} setter={set_siren} label={'company id number'}/>
                            <Field type={'text'} value={company_address_line_1} setter={set_company_address_line_1}
                                   label={'company_address_line_1'}/>
                            <Field type={'text'} value={company_address_line_2} setter={set_company_address_line_2}
                                   label={'company_address_line_2'}/>
                            <Field type={'text'} value={company_postal_code} setter={set_company_postal_code}
                                   label={'company_postal_code'}/>
                            <Field type={'text'} value={company_city} setter={set_company_city}
                                   label={'company_city and country'}/>
                            <Field type={'text'} value={iban} setter={set_iban} label={'iban'}/>
                            <Field type={'text'} value={bic} setter={set_bic} label={'bic'}/>
                            <Field type={'text'} value={vat_number} setter={set_vat_number} label={'vat_number'}/>
                            <Field type={'number'} value={vat_rate} setter={set_vat_rate}
                                   label={'applicable vat (20% if based in France, 0% otherwise)'}/>
                            <Field type={'text'} value={extra_info} setter={set_extra_info}
                                   label={'Anything else to add on the invoice'}/>

                          </div>}
                          action_label={'Update info'}
                          on_click={() => update_it()}
  >
    <MdEdit/> Edit company info
  </IntellifoxModal>
}


const AffiliateTermsAndConditions = ({ referral_id }) => {

  const commission_rate_structure = get_commission_rate_structure(referral_id)

  return <div className="row">
    <div className="col-12">
      <h1>Intelifox affiliate program terms and conditions</h1>
    </div>
    <div className="col-12 small">
      Last update: 2022-01-25
    </div>
    <div className="col-12 font-weight-bold">
      Preambule
    </div>
    <div className="col-12">
      <p>
        These terms and conditions define the conditions for participating in the Intellifox affiliate program
        (hereafter designated as "the program").
      </p>

      <p>Intellifox SASU (designated as "the company" hereafter) offers a software solution called Intellifox, which
        helps Amazon sellers build and optimize their listings.</p>

      <p>The affiliate partner (designated as “the partner” in the next articles) is an independent agent participating
        in the program. To participate in the program, the partner must accept these terms and conditions by clicking on 'Accept terms and conditions'.</p>
    </div>

    <div className="col-12 font-weight-bold">
      Article 1 - Goal of the program
    </div>
    <div className="col-12">
      <p>
        The goal of the program is to leverage the partner’s audience in order to promote Intellifox, in exchange for
        commissions as defined in the following articles.
      </p>
    </div>
    <div className="col-12 font-weight-bold">
      Article 2 - Commissions
    </div>
    <div className="col-12">
      <p>
        Commissions are calculated monthly. Each period starts on the 5th day of a month and ends on the 4th day of the
        next calendar month.
      </p>
      <p>
        The commission (C) is calculated using the following formula
        <ul>
          <li>R = (monthly revenue brought by the partner during the period) + (yearly revenue brought by the partner
            during the last 12 months, divided by 12)
          </li>
          <li>Cx = commission rate as explained in the next section</li>
          <li>C = R * Cx</li>
        </ul>
      </p>

      <p>
        The commission rate (Cx) is calculated using the following formula :
        <ul>
          <li>Q = (number of monthly users that have paid during the period) + (number of yearly users that have paid
            during the last twelve months)
          </li>
          <li>Cx can be read on the following table on the first line where the value of Q is greater
            or equal to the value on the left.
          </li>
        </ul>
      </p>

      <p>
        <table className={'table'}>
          <thead>
          <tr>
            <th>Value of Q</th>
            <th>Cx</th>
          </tr>
          </thead>
          <tbody>
          {commission_rate_structure.map(({ over, rate }) => {
            return <tr>
              <td><FaGreaterThanEqual/> {over}</td>
              <td>{format_percentage(rate)}</td>
            </tr>
          })}
          </tbody>
        </table>
      </p>
    </div>
    <div className="col-12 font-weight-bold">
      Article 3 - Payouts
    </div>
    <div className="col-12">
      <p>
        Payouts for a given period N which starts on YYYY-(N)-05 and YYYY-(N+1)-04 are paid on YYYY-(N+3)-15, two months
        after the end of the period.
      </p>
      <p>
        Example
        <ul>
          <li>The commission for the 2021-11-05 to 2021-12-04 period will be paid on 2022-02-15
          </li>
        </ul>
      </p>
      <p>Commissions and payouts are calculated in euros and sent as such to the bank account of the partner.</p>
    </div>
    <div className="col-12 font-weight-bold">
      Article 4 - Forbidden behaviour
    </div>
    <div className="col-12">
      <p>
        It is forbidden for the partner to:
        <ul>
          <li>Create paid ads using the following keywords: Intellifox</li>
          <li>Speak negatively publicly about the company or the software</li>
          <li>Spamming the link</li>
          <li>Misleading customers to believe the partner is the same as the company.</li>
          <li>Using celebrity endorsements without the company approval.</li>
        </ul>
      </p>
    </div>
    <div className="col-12 font-weight-bold">
      Article 5 - Approval or removal of affiliate
    </div>
    <div className="col-12">
      <p>The company reserves the right to approve, disapprove or remove ANY Affiliate at any time in its sole and
        absolute discretion.</p>
    </div>
    <div className="col-12 font-weight-bold">
      Article 6 - Modification
    </div>
    <div className="col-12">
      <p>The company reserves the right to modify these terms and conditions. The partner will receive a notice on the
        dashboard. Referring any client equals acceptance of the updated terms and condition.</p>
    </div>
  </div>
}
