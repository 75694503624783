import React, { useState } from 'react'

import './button.css'
import Redirect from './redirect'
import { useTranslate } from '../../context/lang'
import Loader from './loader'
import { DefaultLoading } from '../i18n/translations'

export default function DeleteButtonV2(
  {
    children, className = '', classNameGroup = '',
    do_delete,
  }) {
  const translate = useTranslate()

  const [delete_confirmation, set_delete_confirmation] = useState(false)
  const [loading, set_loading] = React.useState(false)
  const [success, set_success] = React.useState(false)
  const [error, set_error] = React.useState(false)

  if (!delete_confirmation) {
    return (
      <button className={`mb-1 btn btn-outline-danger ${className}`}
              onClick={() => set_delete_confirmation(true)}>{children}</button>
    )
  }

  if (loading) {
    return <DefaultLoading/>
  }

  return (
    <>
      <div className={` mb-1 btn-group ${classNameGroup}`}>
        <button className={`btn btn-danger ${className}`}
                disabled={loading}
                onClick={async () => {
                  try {
                    set_loading(true)
                    await do_delete()
                    set_loading(false)
                    set_success(true)
                    set_delete_confirmation(false)
                  } catch (e) {
                    set_loading(false)
                    set_error(true)
                  }
                }}>{translate({
          fr: `Confirmer`,
          en: `Confirm`,
        })}
        </button>
        <button className={`btn btn-outline-danger ${className}`}
                disabled={loading}
                onClick={() => set_delete_confirmation(false)}>{translate({
          fr: `Annuler`,
          en: `Cancel`,
        })}
        </button>
      </div>
      <div>{error ? `Cannot perform the action` : null}
      </div>
    </>
  )

}
