import React, { useEffect, useRef, useState } from 'react'

import './text-area.css'
import RichTextEditor from 'react-rte'
import { useTranslate } from '../../context/lang'

let global_textarea_id = 0

export const badge = (length, count_min, count_max) => {
  if (length > count_max) return 'danger'
  if (length < count_min) return 'warning'
  return 'success'
}


const TextArea = (props) => {
  const {
    value, setter, help, placeholder, label, validate = () => true,
    count, count_min, count_max, count_html,
    show_count_objective = true,
    show_html,
    variables = [],
    keywords,
    edit = true,
    margin_bottom = 'mb-3',
    col = '12',
    need_to_be_filled,
    focus_on_launch = false,
    raw_html = false,
  } = props

  const [focus, set_focus] = React.useState(false)
  const textarea = useRef(null)
  const value_set_from_within = useRef(false)

  const editor_state_tmp = RichTextEditor.createEmptyValue()
  const [editor_state, set_editor_state] = useState(editor_state_tmp.setContentFromString(value, 'html'))
  const [textarea_id] = useState(global_textarea_id)
  global_textarea_id = global_textarea_id + 1


  const correctString = (e) => {
    return e.toString('html')
  }

  useEffect(() => {
    if (!value_set_from_within.current) {
      set_editor_state(editor_state.setContentFromString(value, 'html'))
    }
    value_set_from_within.current = false
  }, [value])
  const editor_ref = useRef(null)

  const was_focused_called_ref = React.useRef(false)

  React.useEffect(() => {
    if (focus_on_launch && !was_focused_called_ref.current) {
      editor_ref.current._focus()
      was_focused_called_ref.current = true
    }

  }, [editor_ref])


  return (
    <>
      <div className={`col-12 col-md-${col} field ${margin_bottom}`}>
        {label ? <label htmlFor={`textarea_${textarea_id}`}>{label}</label> : null}
        {edit ?
          <div className="row">
            <div className="col">
              {!raw_html ?
                <div className={`texteditor-container ${show_html ? '' : 'no-control'} w-100 position-relative`}>
                  <RichTextEditor
                    className={`force-global-colors-transparent-bg input-global-colors p-0 w-100 ${focus ? 'focus' : ''}`}
                    value={editor_state}
                    placeholder={placeholder}
                    ref={editor_ref}
                    onChange={(e) => {
                      set_editor_state(e)
                      if (value !== e.toString('html')) {
                        value_set_from_within.current = true
                        setter(e.toString('html'))
                      }
                    }}
                    onBlur={() => set_focus(false)}
                    onFocus={() => set_focus(true)}
                  />
                </div> :
                <div className={`texteditor-container ${show_html ? '' : 'no-control'} w-100 position-relative`}>
                <textarea
                  className={`force-global-colors-transparent-bg input-global-colors w-100 ${focus ? 'focus' : ''}`} style={{height: 500}}
                  value={value} onChange={(e) => setter(e.target.value)} placeholder={placeholder}>

                </textarea>
                </div>}
            </div>

          </div> : <div className="row">
            <div className="col-12">
              <div style={{ padding: 10, paddingLeft: 0 }} className='font-weight-bold'>
                {value}
              </div>
            </div>
          </div>}
        <div className="row">

          <div className="col-12 mt-1">
            {help}
          </div>
        </div>

      </div>


    </>
  )
}


export default TextArea
