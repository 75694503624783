import Item from './item'
import React from 'react'
import { useRoleDetails } from '../../routes/intellifox/organigram'

export const Step = ({ step, role, title, tags, details, action, children, done, mb="mb-3" }) => {

  const { background } = useRoleDetails(role)

  return <Item className={`${mb} overflow-hidden pl-5 pr-5`} style={{opacity:done?0.3:1}}>
    {role ? <div className="position-absolute"
                 style={{ left: 0, top: 0, height: '100%', width: '100%', opacity: 0.3, background }}></div> : null}
    {role ? <div className="position-absolute"
                 style={{ left: 0, top: 0, height: '100%', width: 20, background }}></div> : null}
    {role ? <div className="position-absolute"
                 style={{ right: 0, top: 0, height: '100%', width: 20, background }}></div> : null}
    <div className="row align-items-center">
      {step ? <div className="col-auto">
        <h1>{step}</h1>
      </div> : null}
      <div className="col">
        <div className="row">
          <div className="col-12 font-weight-bold">
            <h3>{title}</h3>
            {tags}
          </div>
          <div className="col-12 font-italic">
            {details}
          </div>
        </div>
      </div>
      <div className="col-auto">
        {action}
      </div>
    </div>

    {children ? <div className="row mt-4">
      <div className="col-12">
        {children}
      </div>
    </div> : null}
  </Item>
}
