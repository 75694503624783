import copy_to_clipboard from "copy-to-clipboard";
import React from "react";
import { TextCopyButton } from "./copy-button";

export const DetailTag = ({
  background = "black",
  color = "white",
  value,
  children,
  copy,
  max_length,
  copy_value = undefined,
}) => {
  let displayed_value = value;
  if (max_length && value && value.substr && value.length > max_length) {
    displayed_value = value.substr(0, max_length) + "...";
  }

  if (!value && !children) {
    return null;
  }

  if (copy) {
    return (
      <span
        className="rounded mr-1 mb-1 p-2 small font-weight-bold clickable text-nowrap"
        onClick={() => copy_to_clipboard(copy_value || value)}
        style={{ background, color }}
      >
        <TextCopyButton
          text_to_copy={value}
          style={{
            color: `${color}`,
          }}
        >
          {displayed_value}
          {children}
        </TextCopyButton>
      </span>
    );
  }

  return (
    <span
      className="rounded mr-1 mb-1 p-1 small font-weight-bold text-nowrap"
      style={{ background, color }}
    >
      {displayed_value}
      {children}
    </span>
  );
};
