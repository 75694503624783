import React, { useEffect, useState } from "react";
import { TextCopyButton } from "../../components/generic/copy-button";
import { useTranslate } from "../../context/lang";
import { GET_TRIAL_ANALYTICS } from "../../graphql/queries/analytics";
import { get_client } from "../../graphql/simple-client";
import { useCachedContext } from "../../hooks/useCachedContext";

const fields = [
  "User",
  "Email",
  "score",
  "started trial",
  "Connected Amazon Account",
  "Launched Product Import",
  "Launched Keyword Search",
  "Selected Competitors",
  "Selected Keywords",
  "Used the background removal tool",
  "Publish a product",
  "Activated Alerts",
];

const quantifiable_fields = [
  "number_of_amazon_accounts",
  "number_of_selected_keywords",
  "number_of_published_products",
  "number_of_background_removed_image",
  "number_of_keywords_searched",
  "number_of_competitors_selected",
  "number_of_imported_products",
];

export default function TrialBoard() {
  const me = useCachedContext("me");
  const translate = useTranslate();
  const [trial_analytics, set_trial_analytics] = useState([]);
  const [loading, set_loading] = useState(true);

  console.log({
    trial_analytics,
  });

  const company_id = me && me.company ? me.company.company_id : undefined;

  const fetch_analytics_for_trial_users = async () => {
    try {
      const client = get_client();

      const response = await client.request(GET_TRIAL_ANALYTICS, {
        company_id,
      });

      set_trial_analytics(response.fetch_analytics_for_trial_users);
    } finally {
      set_loading(false);
    }
  };

  useEffect(() => {
    fetch_analytics_for_trial_users();
  }, []);

  if (loading) return <div>Loading...</div>;

  // Add a score percentage to each trial analytics row and sort by score percentage
  const trial_analytics_rows = trial_analytics
    .map((ta) => {
      return {
        ...ta,
        scorePercentage: (
          (Object.values(ta).reduce((acc, x) => {
            if (typeof x == "boolean") {
              return x === true ? acc + 1 : acc + 0;
            } else if (typeof x == "number") {
              return x > 0 ? acc + 1 : acc + 0;
            } else {
              return acc;
            }
          }, 0) /
            9) *
          100
        ).toFixed(0),
      };
    })
    .sort((a, b) => b.scorePercentage - a.scorePercentage);

  return (
    <>
      <h2>
        {translate({
          en: "Trial Users Dashboard",
          fr: "Tableau de bord des utilisateurs d'essai",
        })}
      </h2>
      <table style={{ width: "100%", marginBottom: "50px" }}>
        <thead>
          <tr>
            {fields.map((f) => (
              <th
                style={{
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  width: f.toLowerCase() === "email" ? 500 : 250,
                  paddingRight: "16px",
                  fontSize: 12,
                  border: "1px solid black",
                  paddingLeft: "10px",
                  textTransform: "capitalize",
                }}
              >
                {f}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {trial_analytics_rows.map((ta) => (
            <tr
              style={{
                height: "70px",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <td
                style={{
                  paddingLeft: "10px",
                  border: "1px solid darkgrey",
                }}
              >
                <TextCopyButton
                  text_to_copy={ta.name}
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                  }}
                >
                  {ta.name}
                </TextCopyButton>
              </td>
              <td
                style={{
                  paddingLeft: "10px",
                  border: "1px solid darkgrey",
                  maxWidth: 100,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <TextCopyButton
                  text_to_copy={ta.email}
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {ta.email}
                </TextCopyButton>
              </td>
              <td
                style={{
                  paddingLeft: "10px",
                  border: "1px solid darkgrey",
                }}
              >
                {ta.scorePercentage}%
              </td>
              <td
                style={{
                  paddingLeft: "10px",
                  border: "1px solid darkgrey",
                }}
              >
                {ta.started_free_trial ? "✅" : "❌"}
              </td>
              {quantifiable_fields.map((q) => (
                <td
                  style={{
                    paddingLeft: "10px",
                    border: "1px solid darkgrey",
                    width: 250,
                  }}
                >
                  {ta[q] > 0 ? "✅" : "❌"}
                </td>
              ))}
              <td
                style={{
                  paddingLeft: "10px",
                  border: "1px solid darkgrey",
                }}
              >
                {ta.activated_alerts ? "✅" : "❌"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
