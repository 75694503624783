import { useContext } from 'react'
import UserContext from '../context/user'

export const useRole = (...roles) => {
  const user = useContext(UserContext)

  roles = [...roles, 'ceo']

  if (!user.user) {
    return ''
  }
  for (let i = 0; i < roles.length; i++) {

    if (user.company.ios_roles.indexOf(roles[ i ]) !== -1) {
      return true
    }
  }
  return false
}

export const useMyRoles = () => {
  const user = useContext(UserContext)

  return user.company.ios_roles
}
