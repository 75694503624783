import { gql } from '@apollo/client'

export const GET_IOS_SELLERS = `
    query GetIosSellers($company_id: ID!, $offset: Int, $limit: Int, $state: String, $only_with_phone: Boolean) {
        ios_sellers (
          company_id: $company_id,
          offset: $offset,
          limit: $limit,
          state: $state,
          only_with_phone: $only_with_phone,
        ) {
          seller_id
          name
          store_link
          phone
          rating
          ratings_total
          ratings_total_percentage
          detailed_information
          number_of_products
          state,
          events,
        }
    }
`
export const GET_IOS_SELLER = `
    query GetIosSeller($company_id: ID!, $seller_id: String) {
        ios_seller (
          company_id: $company_id,
          seller_id: $seller_id,
        ) {
          seller_id
          name
          store_link
          phone
          rating
          ratings_total
          ratings_total_percentage
          detailed_information
          number_of_products
          state,
          events,
          details,
          products {
            product_id
            title
            image_url
            rating
            reviews_total
            is_fba
            rank
            category
          }
        }
    }
`
export const UPDATE_IOS_SELLER = gql`
    mutation UpdateIosSeller(
      $company_id: ID!, 
      $seller_id: String,
      $state: String,
      $events: String,
      $details: String,
    ) {
        ios_update_seller (
          company_id: $company_id,
          seller_id: $seller_id,
          state: $state,
          events: $events,
          details: $details,
        )
    }
`
