import React from 'react'

import './button.css'

export default function Button({ children, className = '', onClick, onMouseOver, disabled, button = 'primary', outline = false, style = {} }) {
  return (
    <button className={`mb-1 btn btn-${outline ? 'outline-' : ''}${button} ${className}`} disabled={disabled} style={style}
            onMouseOver={() => onMouseOver && onMouseOver()}
            onClick={(e) => {
              e.preventDefault()
              if (!disabled && onClick) {
                onClick(e)
              }
            }}>{children}</button>
  )
}
