// eslint-disable-next-line import/no-webpack-loader-syntax
import build_product from "!file-loader!../../images/story/build_product.svg";
import * as _ from "lodash";
import moment from "moment";
import React from "react";
import {
  FaArrowRight,
  FaGreaterThanEqual,
  FaQuestion,
  FaSeedling,
} from "react-icons/all";
import { navigate } from "../../absolute_link";
import BackTo from "../../components/generic/back_to";
import Button from "../../components/generic/button";
import CopyButton from "../../components/generic/copy-button";
import ExternalLink from "../../components/generic/external-link";
import InlineToggle, {
  InlineToggleStandalone,
} from "../../components/generic/inline-toggle";
import Item from "../../components/generic/item";
import IntellifoxModal, {
  IntellifoxModalComponent,
} from "../../components/generic/modal";
import {
  DefaultError,
  DefaultLoading,
} from "../../components/i18n/translations";
import BigBox from "../../components/layout/big_box";
import Title from "../../components/layout/title";
import { useCachedContext } from "../../hooks/useCachedContext";
import IntellifoxIcon from "../../images/intellifox-icon";
import { section_color } from "../colors";
import { format_big_number } from "../success/cheque_france_num";
import { DownloadBillForAffiliate } from "../success/download_bill";
import MrrGraph from "./mrr_graph";

export const format_percentage = (percentage, precision = 0) =>
  `${Math.round(100 * Math.pow(10, precision) * percentage) / Math.pow(10, precision)}%`;

export const format_euros = (euro, precision = 0) =>
  typeof euro === "number" ? (
    <>€{format_big_number(euro, precision)}</>
  ) : null;

export const format_currency = (amount, currency, precision = 2) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency, maximumFractionDigits: precision }).format(amount)
}

export const IntellifoxPage = ({ title, component }) => {
  return (
    <div className={"pt-5 pb-5 container-fluid"}>
      <div className="container mb-5">
        <BackTo to={"/intellifox"}>Retour à Intellifox</BackTo>
        <Title color={section_color("intellifox")} style={{ color: "white" }}>
          <IntellifoxIcon /> {title}
        </Title>

        <div className="row">
          <div className="col-12">
            <BigBox>{component}</BigBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MRR = () => {
  const months = useCachedContext("ios_mrr");

  const [selected_affiliate, set_selected_affiliate] = React.useState("");

  if (months.loading) {
    return <DefaultLoading />;
  }

  if (months.error) {
    return <DefaultError what={months} />;
  }

  const filtered_months = months.map((m) => {
    return {
      ...m,
      ...compute_months_stats(m),
    };
  });
  const last_finished_month = filtered_months[1];

  const affiliates = filtered_months.reduce((affiliates, month) => {
    for (let i = 0; i < month.affiliates.length; i++) {
      const affiliate = month.affiliates[i];

      if (!affiliates.find((a) => a.referral_id === affiliate.referral_id)) {
        affiliates.push({
          name: affiliate.name,
          referral_id: affiliate.referral_id,
          secret: affiliate.secret,
        });
      }
    }
    return affiliates;
  }, []);

  return (
    <>
      <div className="container">
        <BigBox
          no_padding={true}
          title={
            <>
              €{format_big_number(last_finished_month.mrr_after_commissions, 0)} - {last_finished_month.intellinauts}{" "}
            </>
          }
          actions={
            <div className="row">
              <div className="col-auto small">
                ({moment(last_finished_month.start).format("MMMM YYYY")})
              </div>
            </div>
          }
        >
          <MrrGraph months={filtered_months} />
        </BigBox>
      </div>
      <BigBox title={"Monthly results"} force_close={false}>
          <MonthsSummary months={months} affiliates={affiliates} />
      </BigBox>
      <BigBox title={"Affiliate partnerships"}>
        <div className="row">

        </div>
        {affiliates.map((a) => {
          return (
            <div className="col mb-2">
              <Item className={"d-block"}>
                <div className="row">
                  <div className="col-12 font-weight-bold">
                    {a.name}
                  </div>
                  <div className="col-12 small">
                    <ExternalLink to={`https://app.intellifox.com/affiliate-dashboard/${a.secret}`}>
                      Open dashboard in a new window
                    </ExternalLink>
                    <CopyButton className={"btn-sm mr-3"}
                                text_to_copy={`https://app.intellifox.com/affiliate-dashboard/${a.secret}`}
                    >
                      Copy link
                    </CopyButton>
                  </div>
                </div>
              </Item>
            </div>
          );
        })}
      </BigBox>
    </>
  );
};

export const AffiliatePage = ({ referral_id }) => {
  const months = useCachedContext("ios_mrr");

  if (months.loading) {
    return <DefaultLoading />;
  }

  if (months.error) {
    return <DefaultError />;
  }

  return (
    <BigBox title={"Mois par mois"}>
      <AffiliateSummary months={months} referral_id={referral_id} />
    </BigBox>
  );
};

const zero_commissions_affiliate = [
  {
    name: "Codigy SASU",
    referral_id: "LjMb7jKXIjw6-Cf-1G8G",
    secret: "yYQKACCDHHyrXqcBk5deqYzg",
    extra_data: '{"accepted_terms_and_conditions_2022_01_25":true}',
    email: "codigy.eu@gmail.com",
    total: 1190,
  },
  {
    name: "Intellifox-YouTube",
    referral_id: "JyP8Lz-4yzkO3Dj_1dld",
    secret: "quEz8Nzhp6lfXxLTmwAbS_uV",
    extra_data: '{"accepted_terms_and_conditions_2022_01_25":true}',
    email: "conrad@intellifox.com",
    total: 8,
  },
  {
    name: "Intellifox-YouTube-EN",
    referral_id: "2AXudIxatOGev7Y8GE1x",
    secret: "L18UM7Hlg8lwqHIcpWc0tMZ1",
    extra_data: '{"accepted_terms_and_conditions_2022_01_25":true}',
    email: "conrad@intellifox.com",
    total: 0,
  },
  {
    name: "Intellifox-SellerCentral",
    referral_id: "UIujgpU4ou9e8HlkmjCC",
    secret: "GV73IRmzFSEAyk3NDkn3hi4R",
    extra_data: '{"accepted_terms_and_conditions_2022_01_25":true}',
    email: "conrad@intellifox.com",
    total: 13,
  },
  {
    name: "Intellifox-GoogleAds",
    referral_id: "5UVM5gF-l6kbQPymwsMg",
    secret: "wPlwlzyuxeQOZArkFMJFWk_r",
    extra_data: '{"accepted_terms_and_conditions_2022_01_25":true}',
    email: "conrad@intellifox.com",
    total: 49,
  },
  {
    name: "Intellifox-Reddit",
    referral_id: "QlT0EF5zkp73PxHNzNrG",
    secret: "a0jzLRFDTKJS4td1ANSC4Hnh",
    extra_data: '{"accepted_terms_and_conditions_2022_01_25":true}',
    email: "conrad@intellifox.com",
    total: 0,
  },
];

export const get_commission_rate_structure = (referral_id) => {
  if (referral_id === "Rx6PPB2QPcTCzd3EngYX") {
    // olivier allain
    return [
      {
        over: 500,
        rate: 0.5,
      },
      {
        over: 250,
        rate: 0.4,
      },
      {
        over: 100,
        rate: 0.35,
      },
      {
        over: 50,
        rate: 0.25,
      },
      {
        over: 0,
        rate: 0.2,
      },
    ];
  }

  if (zero_commissions_affiliate.find((i) => i.referral_id === referral_id)) {
    return [
      {
        over: 0,
        rate: 0,
      },
    ];
  }

  return [
    {
      over: 0,
      rate: 0.3,
    },
  ];
};

const compute_commission_rate = (referral_id, intellinauts) => {
  const commission_rates = get_commission_rate_structure(referral_id);

  for (let i = 0; i < commission_rates.length; i++) {
    const commission_rate = commission_rates[i];
    const { over, rate } = commission_rate;
    if (intellinauts >= over) {
      return rate;
    }
  }

  throw new Error("could not compute commission rate");
};

export const AffiliateSummary = ({
  months,
  referral_id,
  secret,
  extra_data = {},
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Timespan</th>
              <th scope="col">Subscriptions</th>
              <th scope="col">Comission rate</th>
              <th scope="col">Commissions</th>
              <th scope="col">Payment date and invoice</th>
            </tr>
          </thead>
          <tbody>
            {months.map((mrr) => {
              const affiliate_data = mrr.affiliates.find(
                (a) => a.referral_id === referral_id
              );
              if (!affiliate_data) {
                return null;
              }

              const details = mrr.details.filter(
                (d) => d.referral_id === referral_id
              );

              const monthly = details.filter((d) => d.frequency === "monthly");
              const yearly = details.filter((d) => d.frequency === "yearly");
              const yearly_cheque_france_num = details.filter(
                (d) => d.frequency === "yearly_cheque_france_num"
              );

              const is_current_month = moment().isBetween(
                moment(mrr.start),
                moment(mrr.end)
              );
              // if (is_current_month) {
              //   return null
              // }
              const current_month_warning = is_current_month ? (
                <div className="row">
                  <div className="col-12 small">In progress...</div>
                </div>
              ) : null;

              const commission_rate = compute_commission_rate(
                referral_id,
                affiliate_data.intellinauts
              );
              const commission_rate_structure =
                get_commission_rate_structure(referral_id);
              const commission = affiliate_data.mrr * commission_rate;

              const payment_date = moment(affiliate_data.start)
                .startOf("month")
                .add(3, "month")
                .add(14, "days");

              const encode = function (obj) {
                var str = [];
                for (var p in obj)
                  if (obj.hasOwnProperty(p)) {
                    str.push(
                      encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                    );
                  }
                return str.join("&");
              };

              const vat = 0; //extra_data ? extra_data.vat_rate : 20

              const params = {
                invoice_number: `${payment_date.format("YYYYMM15")}-${referral_id.substring(0, 3)}`,
                invoice_date: payment_date.valueOf(),
                ...extra_data,

                amount_ttc:
                  Math.round(100 * commission * (1 + vat / 100)) / 100,
                vat,
                ref: `INTELLIFOX INVOICE ${payment_date.format("YYYYMM15")}-${referral_id.substring(0, 3)}`,
                label: `${moment(affiliate_data.start).format("ll")} - ${moment(affiliate_data.end).format("ll")}`,

                intellinauts: affiliate_data.intellinauts,
                commission_rate,
                start: affiliate_data.start,
                end: affiliate_data.end,
              };

              const what = btoa(encode(params));
              const total =
                monthly.length +
                yearly.length +
                yearly_cheque_france_num.length;

              const monthly_total = monthly.reduce(
                (total, details) => total + details.mrr,
                0
              );
              const yearly_total =
                yearly.reduce((total, details) => total + details.mrr, 0) +
                yearly_cheque_france_num.reduce(
                  (total, details) => total + details.mrr,
                  0
                );

              const bill_url = `${window.location.origin}/reverse_affiliate#?data=${what}`;

              return (
                <tr style={{ opacity: is_current_month ? 0.5 : 1 }}>
                  <td>
                    <div className="row">
                      <div className="col-12 font-weight-bold">
                        {moment(mrr.start).format("MMMM YYYY")}
                      </div>
                    </div>
                    <div className="row small">
                      <div className="col-12">
                        From {moment(mrr.start).format("ll")}
                      </div>
                      <div className="col-12">
                        to {moment(mrr.end).format("ll")}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-sm small">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Qty</th>
                              <th className={"text-right"}>Avg</th>
                              <th className={"text-right"}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {monthly_total > 0 ? (
                              <tr>
                                <td>Monthly</td>
                                <td>{monthly.length}</td>
                                <td>
                                  {format_big_number(
                                    monthly_total / monthly.length
                                  )}
                                  €
                                </td>
                                <td>{format_big_number(monthly_total)}€</td>
                              </tr>
                            ) : null}
                            {yearly_total > 0 ? (
                              <tr>
                                <td>Annual</td>
                                <td>
                                  {yearly.length +
                                    yearly_cheque_france_num.length}
                                </td>
                                <td>
                                  {format_big_number(
                                    yearly_total /
                                      (yearly.length +
                                        yearly_cheque_france_num.length)
                                  )}
                                  €
                                </td>
                                <td>{format_big_number(yearly_total)}€</td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>Total</td>
                              <td
                                className="font-weight-bold"
                                style={{ border: "3px solid green" }}
                              >
                                {total}
                              </td>
                              <td>
                                {format_big_number(
                                  (monthly_total + yearly_total) / total
                                )}
                                €
                              </td>
                              <td>
                                {format_big_number(
                                  monthly_total + yearly_total
                                )}
                                €
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="col-12 small">
                      <table className={"table table-sm"}>
                        <tbody>
                          {commission_rate_structure.map(({ over, rate }) => {
                            const is_current = commission_rate === rate;
                            return (
                              <tr
                                className={`${is_current ? "font-weight-bold" : ""}`}
                                style={{
                                  border: is_current ? "3px solid green" : "",
                                }}
                              >
                                <td className={"small"}>
                                  <FaGreaterThanEqual />
                                  {over}
                                </td>
                                <td className={"small"}>
                                  {format_percentage(rate)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td>
                    {current_month_warning}
                    <div className="row">
                      <div
                        className="col-12 font-weight-bold"
                        style={{ border: "3px solid green" }}
                      >
                        {format_big_number(commission)}&nbsp;€
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="row">
                      <div
                        className={`col-12 ${payment_date.isSame(moment(), "month") ? "font-weight-bold" : ""}`}
                      >
                        {payment_date.isSame(moment(), "month") ? (
                          <FaArrowRight />
                        ) : null}{" "}
                        {payment_date.format("ll")}
                      </div>
                      <div className="col-12">
                        <DownloadBillForAffiliate
                          secret={secret}
                          bill_url={bill_url}
                          output_file_name={`${affiliate_data.name.toUpperCase()}_${params.invoice_number}_${commission.toFixed(2)}_EUR.pdf`
                            .replace(/ /g, "_")
                            .replace(/ /g, "_")
                            .replace(",", ".")}
                        >
                          Download invoice
                        </DownloadBillForAffiliate>
                        <ExternalLink to={bill_url}>See invoice</ExternalLink>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const compute_months_stats = (mrr, before) => {
  const monthly = mrr.details.filter((d) => d.frequency === "monthly");
  const yearly = mrr.details.filter((d) => d.frequency === "yearly");
  const yearly_cheque_france_num = mrr.details.filter(
    (d) => d.frequency === "yearly_cheque_france_num"
  );

  const total_commissions = mrr.affiliates.reduce(
    (total_commissions, affiliate) => {
      total_commissions +=
        affiliate.mrr *
        compute_commission_rate(affiliate.referral_id, affiliate.intellinauts);

      return total_commissions;
    },
    0
  );

  const total_percentage =
    monthly.length + yearly.length + yearly_cheque_france_num.length;

  const mrr_after_commissions = mrr.mrr - total_commissions;

  const new_customers = before
    ? _.uniqBy(
        mrr.details.filter((sub) => {
          return !before.details.find(
            (old_sub) => old_sub.intellinaut_id === sub.intellinaut_id
          );
        }),
        "intellinaut_id"
      )
    : [];

  const canceled_customers = before
    ? before.details.filter((sub) => {
        return !mrr.details.find(
          (old_sub) => old_sub.intellinaut_id === sub.intellinaut_id
        );
      })
    : [];

  const affiliates_stats = mrr.affiliates.map((a) => {
    const intellinauts = mrr.details.filter(
      (i) => i.referral_id === a.referral_id
    );
    const intellinauts_before = before
      ? before.details.filter((i) => i.referral_id === a.referral_id)
      : [];

    const new_customers = before
      ? intellinauts.filter((sub) => {
          return !intellinauts_before.find(
            (old_sub) => old_sub.intellinaut_id === sub.intellinaut_id
          );
        })
      : [];
    const canceled_customers = before
      ? intellinauts_before.filter((sub) => {
          return !intellinauts.find(
            (old_sub) => old_sub.intellinaut_id === sub.intellinaut_id
          );
        })
      : [];

    return {
      ...a,
      new_customers,
      canceled_customers,
    };
  });

  return {
    monthly,
    yearly,
    yearly_cheque_france_num,
    total_commissions,
    total_percentage,
    mrr_after_commissions,
    new_customers,
    canceled_customers,
    affiliates_stats,
  };
};

const Source = ({ utm }) => {
  const [show, set_show] = React.useState(false);

  return (
    <InlineToggle is_on={show} on_toggle={() => set_show(!show)}>
      {utm}
    </InlineToggle>
  );
};

const IntellinautsAcquisition = ({ month, new_customers }) => {
  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  if (intellinaut_companies.loading) {
    return <DefaultLoading />;
  }

  if (intellinaut_companies.error) {
    return <DefaultError />;
  }

  const details = new_customers
    .map((new_customer) => {
      return intellinaut_companies.find(
        (i) => new_customer.intellinaut_id === i.intellinaut_company_id
      );
    })
    .map((details) => {
      if (!details) {
        return null;
      }
      const {
        intellinauts,
        utm,
        creation_date,
        transactions,
        subscription_frequency,
        subscription_tier,
      } = details;
      const { email, first_name, last_name, referral_id } = intellinauts[0];
      const transaction_before_period = transactions.filter((t) => {
        const transaction_date = moment(t.created_at);
        return transaction_date.isBefore(moment(month.start));
      });

      const transaction_during_period = transactions.filter((t) => {
        const transaction_date = moment(t.created_at);
        return transaction_date.isBetween(
          moment(month.start),
          moment(month.end)
        );
      });

      const sum_transaction_earnings = (transactions) => {
        return transactions.reduce((total, transaction) => {
          return total + transaction.balance_earnings;
        }, 0);
      };

      const payment_transaction = transaction_during_period.find(
        (t) => t.alert_name === "subscription_payment_succeeded"
      );

      return {
        ...details,
        email,
        first_name,
        last_name,
        referral_id,
        total_before_period: sum_transaction_earnings(
          transaction_before_period
        ),
        total_for_period: sum_transaction_earnings(transaction_during_period),
        transaction_during_period,
        transaction_before_period,
        plan_name: payment_transaction ? payment_transaction.plan_name : "n/a",
      };
    })
    .filter((d) => d !== null);

  console.log("IntellinautAcquisition", details);

  const reactivations = details.filter((d) => d && d.total_before_period > 0);
  const new_intellinauts = details.filter(
    (d) => d && d.total_before_period === 0
  );

  return (
    <div className="row">
      <div className="col-12">
        <h1>{details.length} acquisitions</h1>
        <h2>dont {reactivations.length} réactivations</h2>
        <h2>et {new_intellinauts.length} nouveaux clients</h2>
      </div>
      <div className="col-12">
        <table className="table table-condensed">
          <thead>
            <tr>
              <th>Email</th>
              <th>Création du compte</th>
              <th>Source</th>
              <th>€ avant acquisition</th>
              <th>€ pour le mois</th>
              <th>Tier actuel / plan choisi</th>
            </tr>
          </thead>
          <tbody>
            {details.map((detail) => {
              if (!detail) {
                return null;
              }

              const {
                email,
                first_name,
                last_name,
                referral_id,
                utm,
                creation_date,
                total_before_period,
                total_for_period,
                transaction_during_period,
                transaction_before_period,
                subscription_frequency,
                subscription_tier,
                plan_name,
              } = detail;

              const referral = month.affiliates.find(
                (r) => r.referral_id === referral_id
              );

              return (
                <tr>
                  <td>
                    {email} {first_name} {last_name}
                  </td>
                  <td>{moment(creation_date).format("LLL")}</td>
                  <td>
                    <div className="row">
                      <div className="col-12">
                        {referral ? referral.name : "n/a"}
                      </div>
                      <div className="col-12">
                        <Source utm={utm} />
                      </div>
                    </div>
                  </td>
                  <td>
                    {transaction_before_period.length > 0 ? (
                      <>
                        <InlineToggleStandalone
                          label={`${transaction_before_period.length} transactions précédentes`}
                        >
                          {transaction_before_period.map((t) => {
                            if (!t.balance_earnings) {
                              return null;
                            }
                            return (
                              <div className="row">
                                <div className="col-12 small">
                                  {format_euros(t.balance_earnings, 2)} -{" "}
                                  {moment(t.created_at).format("DD/MM/YYYY")}
                                </div>
                              </div>
                            );
                          })}
                        </InlineToggleStandalone>
                      </>
                    ) : null}
                  </td>
                  <td>
                    {transaction_during_period.map((t) => {
                      if (!t.balance_earnings) {
                        return null;
                      }
                      return (
                        <div className="row">
                          <div className="col-12 small">
                            {format_euros(t.balance_earnings, 2)} -{" "}
                            {moment(t.created_at).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      );
                    })}
                  </td>
                  <td>
                    <div className="row">
                      <div className="col-12">
                        {subscription_tier} - {subscription_frequency}
                      </div>
                      <div className="col-12">Plan : "{plan_name}"</div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const IntellinautsChurn = ({ month, canceled_customers }) => {
  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  if (intellinaut_companies.loading) {
    return <DefaultLoading />;
  }

  if (intellinaut_companies.error) {
    return <DefaultError />;
  }

  const details = canceled_customers.map((new_customer) => {
    return intellinaut_companies.find(
      (i) => new_customer.intellinaut_id === i.intellinaut_company_id
    );
  });

  const grouped_by_reasons = _.groupBy(details, "cancellation_reason");

  const top_reasons = Object.keys(grouped_by_reasons).sort((r1, r2) =>
    grouped_by_reasons[r1].length > grouped_by_reasons[r2].length ? -1 : 1
  );

  return (
    <div className="row">
      <div className="col-12">
        <h1>
          {details.length} résiliations en{" "}
          {moment(month.start).format("MMMM YYYY")}
        </h1>
      </div>
      <div className="col-12">
        <h2>Top des raisons de résiliation</h2>
      </div>
      <div className="col-12">
        {top_reasons.map((r) => {
          if (r === "n/a" || r === "null") {
            return null;
          }
          return (
            <h4>
              {grouped_by_reasons[r].length} <FaArrowRight /> "{r}"
            </h4>
          );
        })}
        <span className="small">
          {grouped_by_reasons["n/a"].length + grouped_by_reasons["null"].length}{" "}
          non précisé
        </span>
      </div>
      <div className="col-12">
        <hr />
        <h2>Détails</h2>
      </div>
      <div className="col-12">
        <table className="table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Création du compte</th>
              <th>Date d'annulation de l'abonnement</th>
              <th>Raison</th>
            </tr>
          </thead>
          <tbody>
            {details.map(
              ({
                intellinauts,
                cancellation_reason,
                cancellation_reason_date,
                creation_date,
              }) => {
                const { email, first_name, last_name } = intellinauts[0];
                return (
                  <tr>
                    <td>
                      {email} {first_name} {last_name}
                    </td>
                    <td>{moment(creation_date).format("LLL")}</td>
                    <td>
                      {cancellation_reason_date
                        ? moment(cancellation_reason_date).format("LLL")
                        : "n/a"}
                    </td>
                    <td>{cancellation_reason}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ShowDetails = ({ details, after }) => {
  const [show, set_show] = React.useState(false);

  return (
    <>
      <Button onClick={() => set_show(true)} className="btn-sm" outline={true}>
        <FaQuestion />
      </Button>
      {show ? (
        <IntellifoxModalComponent
          show_modal={show}
          close={() => set_show(false)}
          body={details(after)}
        />
      ) : null}
    </>
  );
};

const MonthsSummary = ({ months, affiliates }) => {
  const [comparison_mode, set_comparison_mode] = React.useState(true);

  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  const comparisons = [
    {
      label: "MRR",
      compute_value: (after) => after.mrr,
      formatter: format_euros,
      show_diff: true,
      diff_goal: 1000,
      show_percentage: true,
    },
    {
      label: "MRR after commissions",
      compute_value: (after) => after.mrr_after_commissions,
      formatter: format_euros,
      show_diff: true,
    },
    {
      label: "Users",
      compute_value: (after) => after.intellinauts,
      show_diff: true,
    },
    {
      label: "€/user",
      compute_value: (after) =>
        after.mrr_after_commissions / after.intellinauts,
      formatter: (v) => format_euros(v, 2),
    },
    {
      label: "Account creations",
      compute_value: (after) => {
        if (intellinaut_companies.loading || intellinaut_companies.error) {
          return <>...</>;
        }

        console.log(intellinaut_companies);

        const accounts = intellinaut_companies.filter(
          (c) =>
            moment(c.creation_date).isBetween(
              moment(after.start),
              moment(after.end)
            ) && !c.intellinauts.find((i) => i.amazon_user_id === "transferred")
        );

        return (
          <div className="row">
            <div className="col-12">{accounts.length}</div>
          </div>
        );
      },
    },
    {
      label: "Free trial starts",
      compute_value: (after) => {
        if (intellinaut_companies.loading || intellinaut_companies.error) {
          return <>...</>;
        }

        const accounts = intellinaut_companies.filter(
          (c) => !c.intellinauts.find((i) => i.amazon_user_id === "transferred")
        );

        const free_trial_started = accounts.filter((c) =>
          moment(c.free_trial_start).isBetween(
            moment(after.start),
            moment(after.end)
          )
        );

        return free_trial_started.length;
      },
    },
    {
      label: "Products import during free trials",
      compute_value: (after) => {
        if (intellinaut_companies.loading || intellinaut_companies.error) {
          return <>...</>;
        }

        const accounts = intellinaut_companies.filter(
          (c) => !c.intellinauts.find((i) => i.amazon_user_id === "transferred")
        );

        const free_trial_started = accounts.filter((c) =>
          moment(c.free_trial_start).isBetween(
            moment(after.start),
            moment(after.end)
          )
        );

        const first_products_import_date_started = free_trial_started.filter(
          (c) =>
            moment(c.first_products_import_date).isBetween(
              moment(after.start),
              moment(after.end).add(21, "days")
            )
        );

        return first_products_import_date_started.length;
      },
    },
    {
      label: `Paying customers that weren't there the month before`,
      compute_value: (after) => after.new_customers.length,
      show_diff: true,
      details: (month) => (
        <IntellinautsAcquisition
          month={month}
          new_customers={month.new_customers}
        />
      ),
    },
    {
      label: "Churned customers",
      compute_value: (after) =>
        moment().isBefore(after.end) ? "..." : after.canceled_customers.length,
      details: (after) => (
        <IntellinautsChurn
          month={after}
          canceled_customers={after.canceled_customers}
        />
      ),
    },
    {
      label: "% churn",
      compute_value: (after, before) =>
        after.canceled_customers.length / before.intellinauts,
      formatter: (v) => format_percentage(v, 1),
    },
  ];

  for (let i = 0; i < affiliates.length; i++) {
    const affiliate = affiliates[i];
    const { name, referral_id } = affiliate;

    comparisons.push(
      { header: true },
      {
        label: `${name}: MRR`,
        compute_value: (after) => {
          const values_for_affiliate = after.affiliates.find(
            (r) => r.referral_id === referral_id
          );

          return values_for_affiliate ? values_for_affiliate.mrr : 0;
        },
        show_diff: true,
        show_percentage: true,
        formatter: format_euros,
      },
      {
        label: `${name}: Users`,
        compute_value: (after) => {
          const values_for_affiliate = after.affiliates_stats.find(
            (r) => r.referral_id === referral_id
          );

          return values_for_affiliate ? values_for_affiliate.intellinauts : 0;
        },
        show_diff: true,
      },
      {
        label: `${name}: Account creations`,
        compute_value: (after) => {
          if (intellinaut_companies.loading || intellinaut_companies.error) {
            return <>...</>;
          }

          const accounts = intellinaut_companies.filter(
            (c) =>
              moment(c.creation_date).isBetween(
                moment(after.start),
                moment(after.end)
              ) && c.intellinauts.find((i) => i.referral_id === referral_id)
          );

          return (
            <div className="row">
              <div className="col-12">{accounts.length}</div>
            </div>
          );
        },
      },
      {
        label: `${name}: Acquisition`,
        compute_value: (after) => {
          const values_for_affiliate = after.affiliates_stats.find(
            (r) => r.referral_id === referral_id
          );

          return values_for_affiliate
            ? values_for_affiliate.new_customers.length
            : 0;
        },
      },
      {
        label: `${name}: Churn`,
        compute_value: (after) => {
          if (moment().isBefore(after.end)) {
            return "...";
          }

          const values_for_affiliate = after.affiliates_stats.find(
            (r) => r.referral_id === referral_id
          );

          return values_for_affiliate
            ? values_for_affiliate.canceled_customers.length
            : 0;
        },
      }
    );
  }

  const limit = 6 + 1;
  // const finished_months = months.filter((m) => moment().isAfter(m.end)).reverse()
  const finished_months = months.filter((m) => true).reverse(); // just to copy array before reverse()

  const months_to_show = finished_months.filter(
    (m, i) => i >= finished_months.length - limit
  );

  const table_header = (
    <tr>
      <th scope="col"></th>
      {months_to_show.map((m, i) => {
        if (i === 0) {
          return null;
        }
        return <th scope="col">{moment(m.start).format("MMMM YYYY")}</th>;
      })}
    </tr>
  );

  return (
    <div className="row">
      <div className="col-12">
        <Button
          onClick={() => set_comparison_mode(!comparison_mode)}
          button={comparison_mode ? "primary" : "secondary"}
        >
          Last 6 months
        </Button>
        <Button
          onClick={() => set_comparison_mode(!comparison_mode)}
          button={!comparison_mode ? "primary" : "secondary"}
        >
          Full history
        </Button>
      </div>
      {comparison_mode ? (
        <div className="col-12">
          <table className="table">
            <thead>{table_header}</thead>
            <tbody>
              {comparisons.map(
                ({
                  header,
                  label,
                  compute_value,
                  formatter = (v) => v,
                  show_diff,
                  diff_goal,
                  show_percentage,
                  details,
                }) => {
                  if (header) {
                    return table_header;
                  }
                  const month_stats = months_to_show.map((after, i) => {
                    const before =
                      finished_months[finished_months.length - 1 - limit + i];
                    const before_before =
                      finished_months[
                        finished_months.length - 1 - limit + i + 1
                      ];

                    const after_stats = compute_months_stats(after, before);
                    const before_stats = compute_months_stats(
                      before,
                      before_before
                    );

                    return {
                      after: { ...after_stats, ...after },
                      before: { ...before_stats, ...before },
                    };
                  });

                  const values = month_stats.map(({ before, after }) => {
                    return compute_value(after, before);
                  });

                  const diffs = values
                    .map((value, i) => {
                      if (i === 0) {
                        // Skip the first to avoid empty value_before
                        return null;
                      }
                      const value_before = values[i - 1];
                      return value - value_before;
                    })
                    .filter((i) => i !== 0);

                  const diff_mean = _.round(_.meanBy(diffs), 2);

                  return (
                    <tr>
                      <td className={"small"}>
                        <div className="row">
                          <div className="col-12">{label}</div>
                          {show_diff ? (
                            <div className="col-12 small">
                              {diff_goal ? (
                                <>
                                  <span className="small">Objectif</span>{" "}
                                  <span className="small badge badge-success ">
                                    +{formatter(diff_goal)}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="small">avg</span>{" "}
                                  <span className="small badge badge-success ">
                                    {formatter(diff_mean)}
                                  </span>
                                </>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </td>
                      {values.map((value, i) => {
                        if (i === 0) {
                          // Skip the first to avoid empty value_before
                          return null;
                        }
                        const value_before = values[i - 1];
                        const diff = value - value_before;

                        const percentage_diff = value / value_before - 1;

                        const badge = `small badge badge-${(diff_goal ? diff >= diff_goal : diff > diff_mean) ? "success" : diff > 0 ? "warning" : "danger"}`;

                        return (
                          <td className="text-right">
                            <div className="row">
                              <div className="col-12">{formatter(value)}</div>
                              <div className="col-12">
                                {details ? (
                                  <ShowDetails
                                    details={details}
                                    after={month_stats[i].after}
                                  />
                                ) : null}
                              </div>
                            </div>
                            <div className="row">
                              {show_diff ? (
                                <div className="col-12 small">
                                  <span className={badge}>
                                    {diff > 0 ? "+" : null}
                                    {formatter(diff)}
                                  </span>
                                </div>
                              ) : null}

                              {show_percentage ? (
                                <div className="col-12 small">
                                  <span className={badge}>
                                    {percentage_diff > 0 ? "+" : null}
                                    {format_percentage(percentage_diff, 1)}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Période</th>
                <th scope="col">Intellinautes</th>
                <th scope="col">MRR</th>
                <th scope="col">Sources des Intellinautes</th>
              </tr>
            </thead>
            <tbody>
              {months.map((mrr) => {
                const is_current_month = moment().isBetween(
                  moment(mrr.start),
                  moment(mrr.end)
                );
                if (is_current_month) {
                  return null;
                }

                const {
                  monthly,
                  yearly,
                  yearly_cheque_france_num,
                  total_commissions,
                  total_percentage,
                  mrr_after_commissions,
                } = compute_months_stats(mrr);

                return (
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-12 font-weight-bold">
                          {moment(mrr.start).format("MMMM YYYY")}
                        </div>
                      </div>
                      <div className="row small">
                        <div className="col-12">
                          Du {moment(mrr.start).format("DD/MM/YYYY")}
                        </div>
                        <div className="col-12">
                          au {moment(mrr.end).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="row">
                        <div className="col-12 font-weight-bold">
                          {mrr.intellinauts}
                        </div>
                        <div className="col-12 small">
                          {format_percentage(monthly.length / total_percentage)}{" "}
                          mensuels
                        </div>
                        <div className="col-12 small">
                          {format_percentage(yearly.length / total_percentage)}{" "}
                          annuels
                        </div>
                        <div className="col-12 small">
                          {format_percentage(
                            yearly_cheque_france_num.length / total_percentage
                          )}{" "}
                          annuels Chèque France Num
                        </div>
                        <div className="col-12 small">
                          {format_percentage(
                            monthly.length / total_percentage +
                              yearly.length / total_percentage +
                              yearly_cheque_france_num.length / total_percentage
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="row">
                        <div className="col-12 font-weight-bold">
                          {format_big_number(mrr.mrr)}&nbsp;€
                        </div>
                      </div>
                    </td>
                    <td>
                      <Item no_padding={true}>
                        <table className="table table-sm  table-borderless small">
                          <thead>
                            <tr>
                              <th scope="col">Pourcentage</th>
                              <th scope="col">Revenus</th>
                              <th scope="col">Intellinautes</th>
                              <th scope="col">Nom</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mrr.affiliates.map((affiliate) => {
                              return (
                                <tr>
                                  <td>
                                    {Math.round(
                                      (100 * affiliate.mrr) / mrr.mrr
                                    )}
                                    %
                                  </td>
                                  <td>
                                    {format_big_number(affiliate.mrr)}&nbsp;€
                                  </td>
                                  <td>{affiliate.intellinauts}</td>
                                  <td>{affiliate.name}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Item>
                      <div className="row small text-right">
                        <div className="col-12 font-weight-bold">
                          Total commissions
                        </div>
                        <div className="col-12">
                          {format_big_number(total_commissions, 0)}&nbsp;€ (
                          {format_percentage(total_commissions / mrr.mrr)})
                        </div>
                        <div className="col-12 font-weight-bold">
                          MRR post commissions
                        </div>
                        <div className="col-12 font-weight-bold">
                          {format_euros(mrr_after_commissions)}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const AnalyzeChurn = ({ before, after }) => {
  const new_subs = after.details.filter((sub) => {
    return !before.details.find(
      (old_sub) => old_sub.intellinaut_id === sub.intellinaut_id
    );
  });
  const churned_subs = before.details.filter((sub) => {
    return !after.details.find(
      (old_sub) => old_sub.intellinaut_id === sub.intellinaut_id
    );
  });

  return (
    <IntellifoxModal
      button_props={{ className: "btn-sm", outline: true }}
      title={"Analyse"}
      body={
        <div className="row">
          <div className="col-12 font-weight-bold">Gains / pertes</div>
          <div className="col-5">{new_subs.length} new subs</div>
          <div className="col-5">{churned_subs.length} churned subs</div>
        </div>
      }
    >
      Analyser
    </IntellifoxModal>
  );
};

const Comparison = ({ label, before, after, formatter = (v) => v }) => {
  return (
    <tr>
      <td className={"small"}>{label}</td>
      <td>{formatter(before)}</td>
      <td>{formatter(after)}</td>
      <td>{formatter(after - before)}</td>
    </tr>
  );
};
export default function Intellifox() {
  return (
    <div className={"pt-5 pb-5 container-fluid"}>
      <div className="container mb-5">
        <Title color={section_color("intellifox")} style={{ color: "white" }}>
          <IntellifoxIcon /> Seller Suite
        </Title>
      </div>

      <div className="row">
        <div className="col-12">
          <MRR />
        </div>
      </div>
    </div>
  );
}
