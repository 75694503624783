import { Tab, TabContainer, TabPane, Tabs } from "react-bootstrap";
import Title from "../../components/layout/title";
import { LinkAccount } from "./link_account";

const Operations = () => {
  return (
    <div>
      <Title color={"orange"} style={{ color: "white" }}>
        Operations
      </Title>
      <Tabs defaultActiveKey="link">
        <Tab eventKey="link" title="Link Accounts">
          <LinkAccount />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Operations;
