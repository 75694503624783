import React from 'react'

export default function ProgressBar({ progress }) {
  if (!progress) {
    return null
  }
  return (
    <div className="col-12">
      <div
        className={`progress-bar rounded ${progress < 100 ? 'progress-bar-striped progress-bar-animated' : 'bg-success'}`}
        role="progressbar"
        aria-valuenow={progress} aria-valuemin="0" aria-valuemax={100}
        style={{
          width: progress + '%',
          fontSize: 16,
          minHeight: 30,
          minWidth: 50,
          fontWeight: 600,
        }}><b>{progress.toFixed(0)} %</b></div>
    </div>
  )
}
