import { gql } from "@apollo/client";

export const GET_TRIAL_ANALYTICS = gql`
  query GetTrialAnalytics($company_id: ID!) {
    fetch_analytics_for_trial_users(company_id: $company_id) {
      id
      name
      email
      started_free_trial
      activated_alerts
      number_of_selected_keywords
      number_of_published_products
      number_of_imported_products
      number_of_background_removed_image
      number_of_amazon_accounts
      number_of_keywords_searched
      number_of_competitors_selected
    }
  }
`;
