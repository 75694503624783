import { useTranslate } from '../../context/lang'
import React from 'react'
import Button from '../generic/button'

const translations = {}

export const default_loading_translations = {
  fr: 'Chargement en cours...',
  en: 'Loading...',
}

export const default_error_translations = {
  fr: 'Erreur lors du chargement',
  en: 'Error while loading data',
}

export const DefaultLoading = ({className}) => {
  const translate = useTranslate()
  return <div className={className}>{translate(default_loading_translations)}</div>
}
export const DefaultError = ({className, what}) => {
  const translate = useTranslate()
  return <div className={className}>{translate(default_error_translations)} {what ? <Button onClick={() => what.refresh()} disabled={what.loading}>{what.loading ? <DefaultLoading/> : 'Retry'}</Button> : null}</div>
}

export const set_translation = (key, lang, translation) => {
  if (translations[ `${key}-${lang}` ]) {
    throw new Error(`Set translation for "${key}-${lang}" : error : key already exists ! `)
  }

  translations[ `${key}-${lang}` ] = translation
}

export const get_translation = (translations, lang) => {
  if (translations[ lang ]) {
    return translations[ lang ]
  }
  return translations[ 'en' ]
}


