import React from "react";
import Redirect from "../components/generic/redirect";
import Product from "./product";
import Success from "./success";
import Intellifox, { AffiliatePage, IntellifoxPage } from "./intellifox";
import Intellinauts from "./success/intellinauts";
import Assists from "./success/assists";
import Publish from "./product/list_publish";
import Imports from "./product/list_imports";
import Translations from "./product/translations";
import Emails from "./product/emails";
import EmailHistory from "./product/email_history";
import Analytics from "./analytics";
import Operations from "./operations";

export const routes = [
  {
    path: "/",
    Component: () => {
      return <Redirect to={`/success`} />;
    },
  },
  { path: "/numbers", Component: Intellifox },
  {
    path: "/numbers/affiliate/:referral_id",
    Component: (props) => (
      <IntellifoxPage
        title={"Affiliation"}
        component={<AffiliatePage {...props} />}
      />
    ),
  },
  { path: "/product", Component: Product },
  { path: "/product/publish", Component: Publish },
  { path: "/product/imports", Component: Imports },
  { path: "/product/translations", Component: Translations },
  { path: "/product/emails", Component: Emails },
  { path: "/product/emails_history", Component: EmailHistory },
  { path: "/success", Component: Success },
  { path: "/success/assists", Component: Assists },
  { path: "/analytics", Component: Analytics },
  { path: "/operations", Component: Operations },
];
