import React from 'react'
import { FaToggleOff, FaToggleOn } from 'react-icons/all'

export default function InlineToggle({ is_on, on_toggle, children, label }) {
  return (
    <div className="row">
      <div className="col-12 font-weight-bold clickable" onClick={() => on_toggle()}>
        <span className="h3 mr-2">{is_on ? <FaToggleOn/> : <FaToggleOff/>}</span> {label}
      </div>
      {is_on ? <div className="col-12">
        {children}
      </div> : null}
    </div>
  )
}

export function InlineToggleStandalone({ children, label }) {

  const [opened, set_opened] = React.useState(false)
  return (
    <div className="row">
      <div className="col-12 font-weight-bold clickable small" onClick={() => set_opened(!opened)}>
        <span className="mr-2">{opened ? <FaToggleOn/> : <FaToggleOff/>}</span> {label}
      </div>
      {opened ? <div className="col-12">
        {children}
      </div> : null}
    </div>
  )
}
