import { useState } from "react";
import Button from "../../components/generic/button";
import { LINK_ACCOUNT } from "../../graphql/queries/link_accounts";
import { get_client } from "../../graphql/simple-client";
import { useCachedContext } from "../../hooks/useCachedContext";

export const LinkAccount = () => {
  const me = useCachedContext("me");
  const company_id = me && me.company ? me.company.company_id : undefined;
  const [accounts, setAccounts] = useState({
    mainAccount: "",
    secondaryAccount: "",
  });
  const [success, setSuccess] = useState(false);

  const linkAccount = async () => {
    setSuccess(null);
    const client = get_client();
    const response = await client.request(LINK_ACCOUNT, {
      mainAccount: accounts.mainAccount,
      secondaryAccount: accounts.secondaryAccount,
      companyId: company_id,
    });
    if (response.link_accounts) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
    if (success !== false)
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
  };
  return (
    <div
      style={{
        marginTop: 48,
      }}
    >
      <h1>Link Account</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 48,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "center",
          }}
        >
          <span
            style={{
              width: 300,
            }}
          >
            Main Account Email:
          </span>
          <input
            onChange={(e) => {
              e.persist();
              setAccounts((prev) => ({
                ...prev,
                mainAccount: e.target?.value,
              }));
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "center",
          }}
        >
          <span
            style={{
              width: 300,
            }}
          >
            Secondary Account Email:
          </span>
          <input
            onChange={(e) => {
              e.persist();
              setAccounts((prev) => ({
                ...prev,
                secondaryAccount: e.target?.value,
              }));
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "center",
          }}
        >
          <Button onClick={linkAccount}>Link</Button>
          {success === null && <span>Linking...</span>}
          {success === true && (
            <span
              style={{
                color: "green",
                fontWeight: "bold",
              }}
            >
              Success!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
