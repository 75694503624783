import React, { useMemo, useCallback } from 'react'
import { AreaClosed, Line, Bar } from '@visx/shape'
import appleStock, { AppleStock } from '@visx/mock-data/lib/mocks/appleStock'
import { curveMonotoneX } from '@visx/curve'
import { GridRows, GridColumns } from '@visx/grid'
import { scaleTime, scaleLinear, scaleBand } from '@visx/scale'
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip'
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { localPoint } from '@visx/event'
import { LinearGradient } from '@visx/gradient'
import { max, extent, bisector } from 'd3-array'
import { timeFormat } from 'd3-time-format'
import { useWindowSize } from '../../hooks/useWindowSize'
import * as _ from 'lodash'
import moment from 'moment'
import Item from '../../components/generic/item'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { format_big_number } from '../success/cheque_france_num'
import { compute_months_stats } from './index'


export const background = '#3b6978'
export const background2 = '#204051'
export const accentColor = '#edffea'
export const amazonColor = '#e88a39'
export const accentColorDark = '#75daad'
const tooltipStyles = {
  position: 'absolute',
  zIndex: 42,
}

// util

// accessors
const getDate = (d) => d.start
const getEarnings = (d) => d.mrr_after_commissions

export const OrdersGraphAxis = ({ height, width, moneyScale, dateScale }) => {


  const money_ticks = moneyScale.ticks()
  const filtered_money_ticks = money_ticks.filter((t, i) => i !== 0 && i !== money_ticks.length - 1)


  return <>
    <svg width={100} height={height + 20} className="position-absolute" style={{ left: -99, top: -10 }}>
      <AxisLeft scale={moneyScale} tickValues={filtered_money_ticks} left={100} top={10} stroke={'transparent'}
                tickStroke={'var(--color-text-global)'}
                tickFormat={(v) => {
                  return `${format_big_number(v, 0)}`
                }}
                tickComponent={({ x, y, formattedValue }) => {
                  return <text transform="" x={x - 4} y={y + 4} fill="var(--color-text-global)" font-size="10"
                               text-anchor="end">{formattedValue}</text>
                }}
      />
    </svg>
    <svg width={width} height={30} className="position-relative"
         style={{ borderRadius: '30px', overflow: 'hidden', top: -1 }}>
      <AxisBottom scale={dateScale} stroke={'transparent'} tickStroke={'var(--color-text-global)'}
                  tickLabelProps={() => ( {
                    fill: 'var(--color-text-global)',
                    fontSize: 10,
                    textAnchor: 'middle',
                  } )}/>
    </svg>
  </>
}

export default function MrrGraph({ months }) {
  const window_size = useWindowSize()
  const row_ref = React.useRef(null)
  const [computed_width, set_computed_width] = React.useState(500)

  React.useEffect(() => {
    set_computed_width(row_ref.current.getBoundingClientRect().width - 30)
  }, [window_size])


  return <div className="row" ref={row_ref}>
    <div className="col-12">
      <DopeGraph width={computed_width} months={months}/>
    </div>
  </div>
}

const DopeGraph = withTooltip(
  ({
     months,
     width = 500,
     height = 500,
     margin = { top: 0, right: 0, bottom: 0, left: 0 },
     showTooltip,
     hideTooltip,
     tooltipData,
     tooltipTop = 0,
     tooltipLeft = 0,
     from,
     to,
   }) => {
    // bounds
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom
    const finished_months = months.filter((m, i) => m.end < Date.now())
    months = [
      {
        mrr_after_commissions: finished_months[ 0 ].mrr_after_commissions * 1.05,
        start: finished_months[ 0 ].end + 1,
      },
      ...finished_months,
      {
        mrr_after_commissions: 0,
        start: moment('2020-03-05').valueOf(),
      },
    ]


    const data = months
    // scales
    const [dateScale, normalized_date_scale] = useMemo(
      () => {
        const dateScale = scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(data, getDate),
        })

        const normalized_date_scale = scaleBand({
          domain: data.filter((d, i) => !!d.day && i < data.length - 1).map((d) => d.day),
        })

        normalized_date_scale.rangeRound([1, width])

        return [dateScale, normalized_date_scale]
      },
      [margin.left, innerWidth, data, width],
    )
    const stockValueScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, ( max(data, getEarnings) || 0 ) + innerHeight / 3],
          nice: true,
        }),
      [innerHeight, margin.top, data],
    )

    // tooltip handler
    const handleTooltip = useCallback(
      (event, d) => {
        showTooltip({
          tooltipData: d,
          tooltipLeft: dateScale(getDate(d)),
          tooltipTop: stockValueScale(getEarnings(d)),
        })
      },
      [dateScale, showTooltip, stockValueScale],
    )

    return (
      <div>
        <svg width={width} height={height} className="list-item-shadow" onMouseLeave={() => hideTooltip()}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={14}
          />
          <LinearGradient id="area-background-gradient" from={background} to={background2}/>
          <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1}/>
          <LinearGradient id="area-gradient2" from={amazonColor} to={amazonColor} toOpacity={0.1}/>
          <GridRows
            left={margin.left}
            scale={stockValueScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          <AreaClosed
            data={data}
            x={d => dateScale(getDate(d)) ?? 0}
            y={d => stockValueScale(getEarnings(d)) ?? 0}
            yScale={stockValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
            pointerEvents="none"
          />
          {data.map((d, i) => {
            if (i === 0 || i === data.length - 1) {
              return null
            }
            return <Bar
              key={`event_${i}`}
              x={dateScale(getDate(d)) - ( width / data.length ) / 2}
              y={margin.top}
              width={width / data.length}
              height={height}
              fill="transparent"
              opacity={0.2}
              onTouchStart={(e) => handleTooltip(e, d)}
              onTouchMove={(e) => handleTooltip(e, d)}
              onMouseMove={(e) => handleTooltip(e, d)}
            />
          })}

          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        <OrdersGraphAxis width={width} height={height} dateScale={normalized_date_scale} moneyScale={stockValueScale}/>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              top={-10}
              left={-10}
              style={tooltipStyles}
            >
              <Item>
                <div className="row">
                  <div className="col-12 small">
                    {moment(tooltipData.start).format()} -> {moment(tooltipData.end).format()}
                  </div>
                  <div className="col-12 font-weight-bold">
                    MRR post-comm : {format_big_number(tooltipData.mrr_after_commissions, 0)} €
                  </div>
                  <div className="col-12 font-weight-bold">
                    Intellinautes : {format_big_number(tooltipData.intellinauts, 0)}
                  </div>
                </div>
              </Item>
            </TooltipWithBounds>
            <Tooltip
              top={innerHeight + margin.top - 14}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)',
              }}
            >
              {moment(getDate(tooltipData)).format('MM/YYYY')}
            </Tooltip>
          </div>
        )}
      </div>
    )
  },
)
