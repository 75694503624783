import React from 'react'
import useCompanyId from '../../hooks/useCompanyId'
import { useTranslate } from '../../context/lang'
import { get_client } from '../../graphql/simple-client'
import Button from '../../components/generic/button'
import { FaDownload } from 'react-icons/all'
import axios from 'axios'
import { create_query } from '../../graphql/queries/_query_and_mutations_templates'


export const force_download_json = (object, file_name) => {

  const blob_href = window.URL.createObjectURL(new Blob([JSON.stringify(object)]))
  console.log('force_download success, create link...')
  const link = document.createElement('a')
  link.href = blob_href
  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  console.log('force_download success, click link...')
  link.click()
  console.log('force_download success, success?')
  setTimeout(() => {
    console.log('force_download success, clean link')
    document.body.removeChild(link)
  }, 20000)
}

export async function force_download(url, file_name) {
  try {
    console.log('force_download', url)
    const response = await axios({
      url: url.replace('http:', 'https:'),
      method: 'GET',
      responseType: 'blob',
    })

    console.log('force_download success, create blob_href...')

    const blob_href = window.URL.createObjectURL(new Blob([response.data]))
    console.log('force_download success, create link...')
    const link = document.createElement('a')
    link.href = blob_href
    link.setAttribute('download', file_name)
    document.body.appendChild(link)
    console.log('force_download success, click link...')
    link.click()
    console.log('force_download success, success?')
    setTimeout(() => {
      console.log('force_download success, clean link')
      document.body.removeChild(link)
    }, 20000)
    console.log('force_download no error found')
  } catch (e) {
    console.log('failed to force_download', e)
    window.open(url)
  }

}
const GET_BILL_AS_PDF = `
    query GetBillAsPdf(
        $company_id: ID!,
        $bill_url: String!
    ) {
        get_bill_as_pdf(
            company_id: $company_id,
            bill_url: $bill_url,
        )
    }
`

const download_bill = async ({ company_id, bill_url, output_file_name }) => {
  try {
    const client = get_client()
    const response = await client.request(GET_BILL_AS_PDF, {
      company_id,
      bill_url,
    })
    const url = response.get_bill_as_pdf

    await force_download(url, output_file_name)
  } catch (e) {
    console.log(e)
  }
}
export const DownloadBill = ({ bill_url, output_file_name, children }) => {

  const company_id = useCompanyId()
  const translate = useTranslate()

  const [download_in_progress, set_download_in_progress] = React.useState(false)

  if (download_in_progress) {
    return <>
      Téléchargement...
    </>
  }

  return <><Button onClick={async () => {

    set_download_in_progress(true)
    await download_bill({
      company_id,
      bill_url,
      output_file_name,
    }).catch(e => console.log(e))
    set_download_in_progress(false)

  }} className={'mb-2 mr-1'}><FaDownload/> {children ? children : translate({
    fr: `Télécharger`,
    en: `Download`,
  })}</Button>
  </>
}



const get_bill_as_pdf_for_affiliate = create_query({
  query: 'get_bill_as_pdf_for_affiliate',
  params: {
    secret: 'String',
    bill_url: 'String',
  },
})

const download_bill_for_affiliate = async ({ secret, bill_url, output_file_name }) => {
  try {
    const client = get_client()
    const response = await client.request(get_bill_as_pdf_for_affiliate, {
      secret,
      bill_url,
    })
    const url = response.get_bill_as_pdf_for_affiliate

    await force_download(url, output_file_name)
  } catch (e) {
    console.log(e)
  }
}
export const DownloadBillForAffiliate = ({ secret, bill_url, output_file_name, children }) => {

  const translate = useTranslate()

  const [download_in_progress, set_download_in_progress] = React.useState(false)

  if (download_in_progress) {
    return <>
      Téléchargement...
    </>
  }

  return <><Button onClick={async () => {

    set_download_in_progress(true)
    await download_bill_for_affiliate({
      secret,
      bill_url,
      output_file_name,
    }).catch(e => console.log(e))
    set_download_in_progress(false)

  }} className={'mb-2 mr-1'}><FaDownload/> {children ? children : translate({
    fr: `Télécharger`,
    en: `Download`,
  })}</Button>
  </>
}

