import { gql } from "@apollo/client";
import { create_query } from "./_utils";

export const ios_intellinaut_companies = `
    query ios_intellinaut_companies($company_id: ID!, $where: String, $subscription_status: String) {
        ios_intellinaut_companies (
          company_id: $company_id,
          where: $where,
          subscription_status: $subscription_status,
        ) {
            lang
            intellinaut_company_id
            merchant_id
            creation_date
            subscription_tier
            subscription_frequency

            cancellation_reason
            cancellation_reason_date

            free_trial_start
            free_trial_end
            first_products_import_date

            referral_id
            referral_source
            company_name

            utm

            subscription_status {
              status
              cancellation_effective_date
            }
            intellinauts {
                intellinaut_id
                first_name
                last_name
                phone_number
                email
                referral_id
                amazon_user_id
                amazon_email
            }
            transactions {
              id
              alert_name
              transaction_status
              created_at
              updated_at
              attempts
              passthrough
              checkout_id
              subscription_id
              country
              currency
              customer_name
              earnings
              order_id
              fee
              sale_gross
              email
              user_id
              subscription_plan_id
              next_bill_date
              plan_name
              payment_tax
              payment_method
              status
              old_status
              balance_currency
              balance_earnings
              balance_tax
              balance_fee
              balance_gross
              coupon
              receipt_url
              initial_payment
              instalments
              quantity
              unit_price
              subscription_payment_id
              next_payment_amount
              event_time


              balance_earnings_decrease
              refund_type
            }
        }
    }
`;

export const ios_intellinaut_transactions_and_processes = create_query({
  query: "ios_intellinaut_transactions_and_processes",
  params: {
    company_id: "ID!",
    intellinaut_company_id: "ID!",
  },
  output: `
    {
            intellinaut_company_id
            first_products_import_date
            subscription_status {
              status
              cancellation_effective_date
            }
            intellinauts {
                intellinaut_id
                first_name
                last_name
                phone_number
                email
                referral_id
                amazon_user_id
                amazon_email
            }
            transactions {
              id
              alert_name
              transaction_status
              created_at
              updated_at
              attempts
              passthrough
              checkout_id
              subscription_id
              country
              currency
              customer_name
              earnings
              order_id
              fee
              sale_gross
              email
              user_id
              subscription_plan_id
              next_bill_date
              plan_name
              payment_tax
              payment_method
              status
              old_status
              balance_currency
              balance_earnings
              balance_tax
              balance_fee
              balance_gross
              coupon
              receipt_url
              initial_payment
              instalments
              quantity
              unit_price
              subscription_payment_id
              next_payment_amount
              event_time


              balance_earnings_decrease
              refund_type
            }
        }
    `,
});

export const ios_intellinaut_companies_light = `
    query ios_intellinaut_companies($company_id: ID!) {
        ios_intellinaut_companies (
          company_id: $company_id,
        ) {
            intellinaut_company_id
            merchant_id
            creation_date
            subscription_tier
            subscription_frequency

            cancellation_reason
            cancellation_reason_date

            referral_id
            utm

            intellinauts {
                intellinaut_id
                first_name
                last_name
                phone_number
                email
                referral_id
                amazon_user_id
                amazon_email
            }
        }
    }
`;

export const ios_assists = `
    query ios_assists($company_id: ID!) {
        ios_assists (
          company_id: $company_id,
        ) {
             employee_id,
             intellinaut_id,
             date,
        }
    }
`;

export const ios_assist_intellinaut = gql`
  mutation ios_assist_intellinaut($company_id: ID, $intellinaut_id: ID) {
    ios_assist_intellinaut(
      company_id: $company_id
      intellinaut_id: $intellinaut_id
    )
  }
`;

export const ios_get_next_payment = `
    query ios_get_next_payment($company_id: ID, $subscription_id: String) {
        ios_get_next_payment (
          company_id: $company_id,
          subscription_id: $subscription_id,
        )
    }
`;

export const ios_reschedule_payment = gql`
  mutation ios_reschedule_payment(
    $company_id: ID
    $intellinaut_id: String
    $original_date: Float
    $payment_id: String
    $date: Float
  ) {
    ios_reschedule_payment(
      company_id: $company_id

      intellinaut_id: $intellinaut_id
      original_date: $original_date

      payment_id: $payment_id
      date: $date
    )
  }
`;

export const ios_refund_payment_query = gql`
  mutation ios_refund_payment($company_id: ID, $order_id: String) {
    ios_refund_payment(company_id: $company_id, order_id: $order_id)
  }
`;

export const ios_france_cheque_num_payment_received = gql`
  mutation ios_france_cheque_num_payment_received(
    $company_id: ID
    $intellinaut_id: ID
    $intellinaut_email: String
    $end_date: Float
    $payment_received_date: Float
  ) {
    ios_france_cheque_num_payment_received(
      company_id: $company_id
      intellinaut_id: $intellinaut_id
      intellinaut_email: $intellinaut_email
      end_date: $end_date
      payment_received_date: $payment_received_date
    )
  }
`;

export const ios_fetch_amazon_api_output = `
    query ios_fetch_amazon_api_output(
      $company_id: ID,
      $intellinaut_id: ID,
      $method: String,
      $params: String
    ) {
        ios_fetch_amazon_api_output (
            company_id:$company_id,
            intellinaut_id:$intellinaut_id,
            method:$method,
            params:$params,
        )
    }
`;

export const ios_get_call_intellinaut_query = create_query({
  query: "ios_get_call_intellinaut",
  params: {
    company_id: "ID",
    intellinaut_id: "ID",
    call_id: "String",
  },
  output: `{
    created_at
    updated_at
    data
  }`,
});

export const ios_amazon_accounts = {
  query: "ios_amazon_accounts",
  params: {
    company_id: "ID!",
    intellinaut_id: "ID",
  },
  output: `
    {
        merchant_id
        label
        invalid_grant
        market_places
    }`,
};
