import { create_mutation, create_query } from './_query_and_mutations_templates'

export const get_processes = create_query({
  query: 'ios_processes',
  params: {
    company_id: 'ID!',
    limit: 'Int',
    offset: 'Int',
    optional_intellinaut_id: 'ID',
    only_unfinished: 'Boolean',
  },
  output: `{
    intellinaut_id
    process_id
    type
    creation_date
    steps {
      name
      status
      index
      start_date
      end_date
    }
  
  }`,
})
export const get_ios_process_data = create_query({
  query: 'ios_process_data',
  params: {
    company_id: 'ID!',
    intellinaut_id: 'ID',
    process_id: 'ID',
  },
  output: `{
    data
  }`,
})

export const restart_ios_process = create_mutation({
  mutation: 'restart_ios_process',
  params: {
    company_id: 'ID!',
    intellinaut_id: 'ID',
    process_id: 'ID',
    index: 'Int',
    relaunch_job: 'Boolean',
  },
})

export const delete_ios_process = create_mutation({
  mutation: 'delete_ios_process',
  params: {
    company_id: 'ID!',
    intellinaut_id: 'ID',
    process_id: 'ID',
  },
})
