import React from 'react'

import './button.css'
import { Link } from '@reach/router'
import { absolute } from '../../absolute_link'

export default function InternalLink({ children, className = '', to }) {
  return (
      <Link to={absolute(to)} className={`internal_link word-break ${className}`}>{children}</Link>
  )
}
