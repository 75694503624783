import { useMutation } from "@apollo/client";
import React from "react";
import DeleteButtonV2 from "../../components/generic/delete-button-v2";
import { useTranslate } from "../../context/lang";
import { await_mutate } from "../../graphql/mutate_promise";
import { create_mutation } from "../../graphql/queries/_utils";
import { useCachedContext } from "../../hooks/useCachedContext";
import useCompanyId from "../../hooks/useCompanyId";

export const DeleteAccount = ({ company }) => {
  const company_id = useCompanyId();
  const { intellinaut_company_id } = company;
  const translate = useTranslate();

  const intellinaut_companies = useCachedContext("ios_intellinaut_companies");

  const [delete_account_for_real] = useMutation(
    create_mutation({
      mutation: "delete_account_for_real",
      params: {
        company_id: "ID!",
        intellinaut_company_id: "ID!",
      },
    })
  );

  const handle_delete = async () => {
    await await_mutate(delete_account_for_real, {
      variables: {
        company_id,
        intellinaut_company_id,
      },
    });
    console.log("Deleting account...");

    await intellinaut_companies.refresh();
  };

  return (
    <div className="row">
      <div className="col-12">
        <DeleteButtonV2 do_delete={handle_delete}>
          {translate({ en: "Delete this account" })}
        </DeleteButtonV2>
      </div>
    </div>
  );
};
