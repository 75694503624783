import React from 'react'
import Button from './button'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { navigate } from '../../absolute_link'
import TextButton from './text_button'

export default function BackTo({onClick, to, children}) {
  return (
      <TextButton onClick={(e) => {
        if (to) {
          navigate(to)
        }
        if (onClick) {
          onClick(e)
        }
      }}><FaLongArrowAltLeft/> {children}</TextButton>
  )
}
