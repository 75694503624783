import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslate } from "../../context/lang";

const GET_USER_ANALYTICS = gql`
  query get_user_analytics {
    ios_user_analytics {
      user_id
      email
      first_name
      last_name
      lang
      company_id
      subscription_tier
      subscription_frequency

      number_of_products
      number_of_orders
    }
  }
`;

const TableHeadCell = ({ children, onClick }) => (
  <th
    onClick={onClick}
    style={{
      fontSize: 14,
      maxWidth: 150,
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {onClick ? (
      <a href="#" onClick={onClick}>
        {children}
      </a>
    ) : (
      children
    )}
  </th>
);

const TableCell = ({ children }) => (
  <td
    style={{
      fontSize: 14,
      maxWidth: 150,
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {children}
  </td>
);

export default function UserAnalytics() {
  const translate = useTranslate();
  const { data, loading, error } = useQuery(GET_USER_ANALYTICS, {
    fetchPolicy: "cache-first",
  });
  const [order_by, set_order_by] = React.useState("number_of_products");

  const active_users = useMemo(() => {
    if (!data || error) return [];
    const filtered = data.ios_user_analytics.filter(
      (user) =>
        ![null, "canceled", "new_customer"].includes(user.subscription_tier)
    );
    const sorted = filtered.sort((a, b) => b[order_by] - a[order_by]);
    return sorted;
  }, [data, order_by]);

  if (loading) return <span>Loading analytics...</span>;

  if (error) return <span>Error fetching analytics</span>;

  return (
    <>
      <h2>
        {translate({
          en: "Users Analytics",
        })}
      </h2>

      <table>
        <thead>
          <tr>
            <TableHeadCell>#</TableHeadCell>
            <TableHeadCell>Email</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Language</TableHeadCell>{" "}
            <TableHeadCell>Subscription Tier</TableHeadCell>
            <TableHeadCell>Subscription frequency</TableHeadCell>
            <TableHeadCell>Products </TableHeadCell>
            <TableHeadCell>Publishes</TableHeadCell>
            <TableHeadCell>Last Published At</TableHeadCell>
            <TableHeadCell>Managed Campaigns</TableHeadCell>
            <TableHeadCell>Last Month Revenues</TableHeadCell>
          </tr>
        </thead>{" "}
        <tbody>
          {active_users.map((user, index) => (
            <tr key={user.user_id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.fist_name} {user.last_name}
              </TableCell>
              <TableCell>{user.lang}</TableCell>
              <TableCell>{user.subscription_tier}</TableCell>
              <TableCell>{user.subscription_frequency}</TableCell>
              <TableCell>{user.number_of_products}</TableCell>
              <PublicationCells intellinaut_id={user.company_id} />
              <RevenueCells intellinaut_id={user.company_id} />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const GET_USER_ANALYTICS_DETAILS = gql`
  query ios_user_analytics_details($company_id: ID!) {
    ios_user_analytics_details(company_id: $company_id) {
      user_id
      email
      first_name
      last_name
      lang
      number_of_publications
      latest_publication_date
      number_of_campaigns
    }
  }
`;

const GET_USER_REVENIUE_FOR_MONTH = gql`
  query ios_user_analytics_revenues(
    $company_id: ID!
    $from: Float
    $to: Float
  ) {
    ios_user_analytics_revenues(company_id: $company_id, from: $from, to: $to)
  }
`;

const PublicationCells = ({ intellinaut_id }) => {
  const { data, loading, error } = useQuery(GET_USER_ANALYTICS_DETAILS, {
    variables: {
      company_id: intellinaut_id,
    },
  });

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error</span>;

  const details = data.ios_user_analytics_details;

  return (
    <>
      <TableCell>{details.number_of_publications}</TableCell>
      <TableCell>
        {/* Last published at in days ago */}
        <span
          style={{
            textWrap: "nowrap",
          }}
        >
          {moment(details.latest_publication_date).fromNow()}
        </span>
      </TableCell>
      <TableCell>{details.number_of_campaigns}</TableCell>
    </>
  );
};

// Round to the nearest 15 minutes
const last_30_days_end = moment().startOf("hour");
const last_30_days_start = moment(last_30_days_end)
  .add(-1, "month")
  .startOf("hour");

const RevenueCells = ({ intellinaut_id }) => {
  const {
    data: revenue_data,
    loading: revenue_loading,
    error: revenue_error,
  } = useQuery(GET_USER_REVENIUE_FOR_MONTH, {
    variables: {
      company_id: intellinaut_id,
      from: last_30_days_start,
      to: last_30_days_end,
    },
  });

  if (revenue_loading) return <span>Loading...</span>;
  if (revenue_error) return <span>Error</span>;

  return (
    <>
      {/* Average or total revenue */}
      <TableCell>{revenue_data?.ios_user_analytics_revenues}</TableCell>

      {/* Number of Ad campaigns managed */}
    </>
  );
};
