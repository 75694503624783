import React, { useContext } from 'react'
import UserContext from '../context/user'

export default () => {
  const user = useContext(UserContext)
  if (user && user.company) {
    const { company_id } = user.company
    return company_id
  }

  return null
}

export const useMerchantId = () => {
  const user = useContext(UserContext)
  const { merchant_id, } = user.company
  return { merchant_id, }
}

export const useHasActiveSubscription = () => {
  return true
}

export const useUserFirstName = () => {
  const user = useContext(UserContext)

  if (!user.user) {
    return ''
  }
  return user.user.first_name
}
export const useUserLastName = () => {
  const user = useContext(UserContext)

  if (!user.user) {
    return ''
  }
  return user.user.last_name
}

export const useSubscriptionStatus = () => {
  const user = useContext(UserContext)
  if (!user.company) {
    return 'n/a'
  }

  return user.company.subscription ? user.company.subscription.status : 'n/a'
}
