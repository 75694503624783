import React from 'react'
import './item.scss'
import { FaCheckCircle } from 'react-icons/all'

export default function Item({ children, onClick, active, className = '', style = {}, image, color, no_padding =false, text_color, horizontal_scroll }) {
  return (
    <div className={`list-item position-relative ${onClick ? 'clickable' : ''} ${active ? 'active' : ''} ${no_padding ? 'p-0':''} ${className}`} onClick={onClick}
         style={{
           ...style,
         }}>
      <div style={{

        background: color,
        color: text_color,
        borderRadius: '10px',
        padding: no_padding ? 0 : 10,

        overflowX: horizontal_scroll ? "scroll":null,
        overflowY: horizontal_scroll ? "scroll":null,
        width: horizontal_scroll ? '100%':null,
        height: horizontal_scroll ? '80vh':null,
      }}>{children} </div>

      {image?<div className="row mb-2">
        <div className="col-12">
          <img src={image} alt="" className="w-100"/>
        </div>
      </div>:null}
      {active ?
        <div className="position-absolute" style={{ right: 10, bottom: 10, fontSize: '200%', color: 'green' }}>
          <FaCheckCircle/></div> : null}
    </div>
  )
}
