import React from 'react'

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox,

} from 'react-icons/md'

export const Checkbox = (
  {
    value, setter, children, className = '', col = 12, style = {},
  }) => (
  <>
    <div className={`col-${col} mb-2 clickable ${className}`} style={style} onClick={(e) => {
      e.stopPropagation()
      setter(value === false || value === null ? true : false, e)
    }}>
      <div className="row no-gutters">
        <div className="col-auto" tabIndex="0" onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setter(value === false || value === null ? true : false)
          }
        }}>
          {value === true ? <MdCheckBox style={{ fontSize: '140%' }}/> : (
            value === 'partial' ? <MdIndeterminateCheckBox style={{ fontSize: '140%' }}/> :
              <MdCheckBoxOutlineBlank style={{ fontSize: '140%' }}/>
          )}
        </div>
        <div className="col pl-2">
          {children}
        </div>
      </div>
    </div>
  </>
)
