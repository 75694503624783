import React from 'react'
import BackTo from '../../components/generic/back_to'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { FaPhone, FaSeedling, MdEmail } from 'react-icons/all'
import BigBox from '../../components/layout/big_box'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Item from '../../components/generic/item'
import moment from 'moment'
import UserContext from '../../context/user'
import useCompanyId from '../../hooks/useCompanyId'
import { get_client } from '../../graphql/simple-client'
import {
  ios_assists,
  ios_france_cheque_num_payment_received,
  ios_get_next_payment,
  ios_reschedule_payment,
} from '../../graphql/queries/ios_intellinauts'
import Button from '../../components/generic/button'
import { StatusBadge } from './intellinauts'
import ExternalLink from '../../components/generic/external-link'
import Field from '../../components/generic/field'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import DeleteButtonV2 from '../../components/generic/delete-button-v2'
import Intellifox from '../intellifox'
import IntellifoxModal from '../../components/generic/modal'
import { Step } from '../../components/generic/step'
import { DownloadBill } from './download_bill'


export const format_big_number = (sum, digits = 2) => {
  if (isNaN(sum)) {
    return 0
  }
  return sum.toLocaleString(
    'en-US',
    {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    },
  )
}

export const DisplayNextPayment = ({ intellinaut_id, subscription_id, show_reschedule=true }) => {
  const company_id = useCompanyId()
  const next_payment = useCachedContext('ios_get_next_payment', '' + subscription_id)

  const [date, set_date] = React.useState(Date.now())

  React.useEffect(() => {
    if (next_payment.payout_date) {
      set_date(moment(next_payment.payout_date, 'YYYY-MM-DD').valueOf())
    }
  }, [next_payment])

  const [ios_reschedule_payment_mutation] = useMutation(ios_reschedule_payment)
  if (next_payment.loading) {
    return <DefaultLoading/>
  }

  if (next_payment.error) {
    return <DefaultError/>
  }

  console.log('?', next_payment)

  if (!next_payment.payout_date) {
    return <>Couldn't find a next payment</>
  }

  const do_it = async () => {
    await await_mutate(ios_reschedule_payment_mutation, {
      variables: {
        company_id,


        intellinaut_id,
        original_date: moment(next_payment.payout_date, 'YYYY-MM-DD').valueOf(),

        payment_id: '' + next_payment.id,
        date,
      },
    })

    await next_payment.refresh()
  }

  return <>

    <div className="row">
      <div className="col-12">
        <h1>
          Next payment: {next_payment.amount} {next_payment.currency},
          {moment(next_payment.payout_date, 'YYYY-MM-DD').format()}
        </h1>
      </div>
    </div>
    {show_reschedule ? <Step title={'Push the next payment'}>
      <div className="row">
        <Field type='date' setter={(v) => set_date(moment(v).valueOf())} value={date}/>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-12 small">
                  Add time
                </div>
                <div className="col-12">
                  <Button outline={true} className="btn-sm mr-2"
                          onClick={() => set_date(moment(date).add(1, 'day').valueOf())}>
                    Add one day
                  </Button>
                  <Button outline={true} className="btn-sm mr-2"
                          onClick={() => set_date(moment(date).add(1, 'month').valueOf())}>
                    Add one month
                  </Button>
                  <Button outline={true} className="btn-sm mr-2"
                          onClick={() => set_date(moment(date).add(1, 'year').valueOf())}>
                    Add one year
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12 small">
                  Remove time
                </div>
                <div className="col-12">
                  <Button outline={true} className="btn-sm mr-2"
                          onClick={() => set_date(moment(date).add(-1, 'day').valueOf())}>
                    Remove a day
                  </Button>
                  <Button outline={true} className="btn-sm mr-2"
                          onClick={() => set_date(moment(date).add(-1, 'month').valueOf())}>
                    Remove a month
                  </Button>
                  <Button outline={true} className="btn-sm mr-2"
                          onClick={() => set_date(moment(date).add(-1, 'year').valueOf())}>
                    Remove a year
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-2">
          <Item>
            👉 <UpdateButtonV2 trigger={do_it}>Save and warn the user</UpdateButtonV2><br/>
            <small>Note: this action will send an email to the user</small>
          </Item>
        </div>
      </div>
    </Step> : null  }
  </>
}


const ChequeFranceNumPaymentReceived = ({ companies, intellinaut_email, company }) => {

  const company_id = useCompanyId()

  const {
    intellinaut_company_id,
    intellinauts,
    referral_id,
    company_name,
    ask_for_check_france_num,
    ask_for_check_france_num_date,
    france_cheque_num_invoice_number,
    france_cheque_num_invoice_date,
    france_cheque_num_bill_url,
    france_cheque_num_payment_received,
    france_cheque_num_payment_received_date,
    transactions,
  } = company


  const last_transaction = transactions[ 0 ]


  const [payment_received_date, set_payment_received_date] = React.useState(Date.now())
  const [end_date, set_end_date] = React.useState(moment('2022-05-31', 'YYYY-MM-DD').valueOf())

  const [ios_france_cheque_num_payment_received_mutation] = useMutation(ios_france_cheque_num_payment_received)


  const do_it = async () => {
    await await_mutate(ios_france_cheque_num_payment_received_mutation, {
      variables: {
        company_id,
        intellinaut_id: intellinaut_company_id,
        intellinaut_email,
        end_date,
        payment_received_date,
      },
    })

    await companies.refresh()
  }

  return <div className="row">
    <div className="col-12 font-weight-bold">
      Référence : 👉 {france_cheque_num_invoice_number} 👈
    </div>
    <div className="col-12">
      <IntellifoxModal
        title={'Confirmer la réception du virement'}
        body={<div className="row">
          <div className="col-12 font-weight-bold text-center mt-3 mb-3">
            <h2>Référence attendue : 👉 {france_cheque_num_invoice_number} 👈</h2>
            <h3>{company_name} {intellinauts[ 0 ].first_name} {intellinauts[ 0 ].last_name}</h3>
          </div>
          <div className="col-12">
            <Step step={1} title={'Télécharger la facture'} action={<DownloadBill bill_url={france_cheque_num_bill_url}
                                                                                  output_file_name={`Intellifox_${france_cheque_num_invoice_number}_500_EUR.pdf`}/>}/>
          </div>
          <div className="col-12">
            <Step step={2} title={'Saisir le facture sur Qonto'} action={<ExternalLink
              to={'https://app.qonto.com/organizations/intellifox-8325/transactions?bank-accounts=90571af2-6de9-4b1e-a35f-e214734c23c6&query=500'}>Ouvrir
              Qonto</ExternalLink>}/>
          </div>
          <div className="col-12">
            <Step step={3} title={'Repousser le prochain paiement d\'un an ?'}>
              {last_transaction ? <div className="col-12">
                <DisplayNextPayment intellinaut_email={intellinaut_email}
                                    subscription_id={last_transaction.subscription_id}/>
              </div> : <>Aucune transction trouvée</>}
            </Step>
          </div>
          <div className="col-12">
            <Step step={4} title={'Saisir les détails'}>
              <div className="row">
                <Field type="date" value={payment_received_date}
                       setter={(v) => set_payment_received_date(moment(v).valueOf())}
                       label={'Date de réception du virement'}/>
                <div className="col-12 mb-2">
                  <Button outline={true} className={'btn-sm mr-1'}
                          onClick={() => set_payment_received_date(moment().add(-2, 'day').valueOf())}>
                    {moment().add(-2, 'day').format()}
                  </Button>
                  <Button outline={true} className={'btn-sm mr-1'}
                          onClick={() => set_payment_received_date(moment().add(-1, 'day').valueOf())}>
                    {moment().add(-1, 'day').format()}
                  </Button>
                  <Button outline={true} className={'btn-sm mr-1'}
                          onClick={() => set_payment_received_date(moment().add(0, 'day').valueOf())}>
                    {moment().add(0, 'day').format()}
                  </Button>
                </div>
                <Field type="date" value={end_date} setter={(v) => set_end_date(moment(v).valueOf())}
                       label={`Date de fin de l'abonnement`}/>
              </div>
            </Step>
          </div>

        </div>}
        action_label={`Confirmer et envoyer un mail à ${intellinaut_email}`}
        on_click={do_it}
      >Marquer le virement reçu</IntellifoxModal>
    </div>
  </div>
}

const ChequeFranceNumCompany = ({ companies, company }) => {
  const {

    intellinaut_company_id,
    intellinauts,
    referral_id,
    company_name,
    ask_for_check_france_num,
    ask_for_check_france_num_date,
    france_cheque_num_invoice_number,
    france_cheque_num_invoice_date,
    france_cheque_num_bill_url,
    france_cheque_num_payment_received,
    france_cheque_num_payment_received_date,
    transactions,
  } = company

  const last_transaction = transactions[ 0 ]

  const intellinaut_email = intellinauts[ 0 ].email


  return <Item className={'mb-2'} active={france_cheque_num_payment_received}>
    <div className="row">
      <div className="col-12 font-weight-bold">
        <div className="row">
          <div className="col-6">
            {company_name} {intellinauts[ 0 ].first_name} {intellinauts[ 0 ].last_name} {intellinauts[ 0 ].email}
          </div>
          <div className="col-6 text-right">
            {moment(ask_for_check_france_num_date).format()} <ExternalLink
            to={france_cheque_num_bill_url}>Voir la facture</ExternalLink>

          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {company.subscription_status.map((s) => {
              return <StatusBadge status={s.status} date={s.cancellation_effective_date}/>
            })}
          </div>
        </div>


      </div>
      <div className="col-12 text-center pt-4 pb-5">
        <ChequeFranceNumPaymentReceived companies={companies} company={company} intellinaut_email={intellinaut_email}/>
      </div>

      <div className="col-12 text-center">
        Abonnement en cours ? {transactions.length} transactions
      </div>
    </div>

  </Item>
}

const ChequeFranceNumCompanies = () => {

  const cheque_france_num_companies = useCachedContext('ios_intellinaut_companies', 'WHERE c.ask_for_check_france_num = true ')

  const [filter, set_filter] = React.useState('')

  if (cheque_france_num_companies.loading) {
    return <DefaultLoading/>
  }

  if (cheque_france_num_companies.error) {
    return <DefaultError/>
  }

  const sorted_companies = cheque_france_num_companies.sort((c1, c2) => c1.ask_for_check_france_num_date < c2.ask_for_check_france_num_date ? 1 : -1)


  const filtered_companies = filter ? sorted_companies.filter((c) => {
    const regex = new RegExp(filter, 'ig')
    return regex.test(c.france_cheque_num_invoice_number)
  }) : sorted_companies

  return <div className="row">

    <Field type="text" value={filter} setter={set_filter} label={'Chercher une référence'}/>

    <div className="col-12">
      {filtered_companies.map((c) => {
        const { intellinaut_company_id } = c

        return <ChequeFranceNumCompany companies={cheque_france_num_companies} company={c}
                                       key={intellinaut_company_id}/>
      })}
    </div>
  </div>
}


const ChequeFranceNumStats = () => {
  const cheque_france_num_companies = useCachedContext('ios_intellinaut_companies', 'WHERE c.ask_for_check_france_num = true ')

  if (cheque_france_num_companies.loading) {
    return <DefaultLoading/>
  }

  if (cheque_france_num_companies.error) {
    return <DefaultError/>
  }

  const number_of_payment_received = cheque_france_num_companies.reduce((total, c) => {
    if (c.france_cheque_num_payment_received) {
      return total + 1
    }
    return total
  }, 0)


  const payment_not_received_new_users = cheque_france_num_companies
    .filter((c) => !c.france_cheque_num_payment_received)
    .reduce((new_users, c) => c.transactions.length === 0 ? new_users + 1 : new_users, 0)


  const payment_received_new_users = cheque_france_num_companies
    .filter((c) => c.france_cheque_num_payment_received)
    .reduce((new_users, c) => c.transactions.length === 0 ? new_users + 1 : new_users, 0)


  const number_of_payment_not_received = cheque_france_num_companies.length - number_of_payment_received
  const number_of_reimbursement_confirmed = 0

  const stats = [
    {
      label: 'Non confirmés',
      quantity: number_of_payment_not_received,
      new_users: payment_not_received_new_users,
    },
    {
      label: 'Virements reçus',
      quantity: number_of_payment_received,
      new_users: payment_received_new_users,
    },
  ]

  return <div className="row">
    <div className="col-12">
      <table className="table">
        <thead>
        <tr>
          <th></th>
          {stats.map(({ label }) => <th>{label}</th>)}
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Quantité</td>
          {stats.map(({ quantity, new_users }) => <td>
            <h4>{quantity}</h4>
            <small>+{new_users} nouveaux Intellinautes</small><br/>
            <small>{quantity - new_users} Intellinautes déjà existants</small>
          </td>)}
        </tr>
        <tr>
          <td>CA</td>
          {stats.map(({ quantity }) => <td><h4>{format_big_number(quantity * 500 / 1.2, 0)} €</h4></td>)}
        </tr>
        <tr>
          <td>MRR</td>
          {stats.map(({ quantity, new_users }) => <td>
            <h4>{format_big_number(( quantity * 500 / 1.2 ) / 12, 0)} €</h4>

            <small>+{format_big_number(( new_users * 500 / 1.2 ) / 12, 0)} € nouveaux MRR</small><br/>
          </td>)}
        </tr>
        <tr>
          <td>Tréso</td>
          {stats.map(({ quantity }) => <td>{format_big_number(quantity * 500, 0)} €</td>)}
        </tr>
        </tbody>
      </table>
    </div>
  </div>
}

export default function ChequeFranceNum() {
  const company_id = useCompanyId()

  const fetch_ios_get_next_payment = async (subscription_id) => {
    const client = get_client()
    const response = await client.request(ios_get_next_payment, {
      company_id, subscription_id,
    })

    let next_payment = {}
    try {
      next_payment = JSON.parse(response.ios_get_next_payment)
    } catch (e) {
      console.log('could not parse ios_get_next_payement',e, response)
    }
    return next_payment
  }

  return (
    <div className={'pt-5 pb-5 container-fluid'}>
      <CachedValues name={'ios_get_next_payment'} fetch_value={fetch_ios_get_next_payment}>
        <div className="container mb-5">
          <BackTo to={'/success'}>Retour à Réussite</BackTo>

          <Title color={section_color('success')} style={{ color: 'white' }}><FaSeedling/> Suivi Chèque France
            Num</Title>
          <BigBox title={'Statistiques'} no_padding={true}>
            <ChequeFranceNumStats/>
          </BigBox>
          <BigBox title={'Trouver une référence'}>
            <ChequeFranceNumCompanies/>
          </BigBox>
        </div>
      </CachedValues>
    </div>
  )
}
